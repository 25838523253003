@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

@item-wide-size: 68px;
@item-narrow-size: 34px;

@item-wide-size_mobile: 34px;
@item-narrow-size_mobile: 17px;

.ib-preview-modal-carousel {
  display: flex;
  gap: @ib-padding-xx-small;
  overflow-x: auto;
  padding-bottom: @ib-padding-xxx-small;
  margin-bottom: -@ib-padding-xxx-small;

  &__item {
    flex-shrink: 0;
    width: @item-narrow-size;
    height: @item-wide-size;
    border-radius: @ib-border-radius-xx-small;
    cursor: pointer;

    @media (max-width: @ib-breakpoint-small) {
      border-radius: @ib-border-radius-xxxx-small;
      width: @item-narrow-size_mobile;
      height: @item-wide-size_mobile;
    }

    &_selected {
      width: @item-wide-size;

      @media (max-width: @ib-breakpoint-small) {
        width: @item-wide-size_mobile;
      }
    }

    &__fading {
      position: relative;
      width: 100%;
      height: 100%;
      top: -100%;
      background-color: @ib-black-color;
      opacity: 0.5;
      border-radius: @ib-border-radius-xx-small;

      @media (max-width: @ib-breakpoint-small) {
        border-radius: @ib-border-radius-xxxx-small;
      }
    }

    &_selected &__fading {
      background-color: transparent;
    }

    &__spinner-wrapper {
      display: none;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;

      .ib-spin {
        transform: scale(0.6);
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: @ib-border-radius-xx-small;

      @media (max-width: @ib-breakpoint-small) {
        border-radius: @ib-border-radius-xxxx-small;
      }
    }

    &_loading img {
      display: none;
    }

    &_loading &__fading {
      background-color: @ib-black-color;
      opacity: 0.1;
    }

    &_loading&_selected &__fading {
      opacity: 0.03;
    }

    &_loading &__spinner-wrapper {
      display: flex;
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;