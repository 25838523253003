@import "src/inbox/styles/variables";

.ib-main-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  @media (max-width: @ib-breakpoint-small) {
    display: none;
  }

  &_mobile {
    display: none;
    flex-direction: row;
    height: 64px;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    margin: 0 16px;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
    }

    @media (max-width: @ib-breakpoint-x-small) {
      gap: unset;
      justify-content: space-evenly;
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;