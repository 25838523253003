@import "src/simple-bot/styles/variables";
@import "src/simple-bot/styles/mixins";

.ib-analytics-block {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__extra {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__trend {
      margin-left: auto;
      padding: 4px 14px;
      border-radius: @sb-border-radius-xxx-large;
      font-size: @sb-font-size-base;
      line-height: @sb-line-height-x-small;
      color: @sb-success-color;
      background-color: @sb-success-bg-color;

      &_negative {
        color: @sb-warning-color;
        background-color: @sb-warning-bg-color;
      }
    }
  }

  &__content {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    align-items: flex-end;
    padding-bottom: 10px;

    &__count {
      display: flex;
      flex-direction: column;
      width: 50%;
      color: @sb-font-color-base;

      div {
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
      }

      span {
        font-size: @sb-font-size-xxxx-large;
        line-height: @sb-line-height-xxx-large;
        margin-top: 6px;
      }
    }

    &__chart {
      display: flex;
      width: 50%;
      height: 60%;

      .recharts-wrapper {
        cursor: pointer !important;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;