@import "src/simple-bot/styles/variables";

@sb-bot-answer-editor-border-hover-color: #D9D9D9;
@sb-bot-answer-editor-variable-container-color: #E6E6E6;
@sb-bot-answer-editor-toolbar-border-radius: 9px;

.bot-answer-editor {
  position: relative;
  z-index: 2;
  line-height: @sb-line-height-base;

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    color: @sb-font-color-secondary-2;
  }

  &_read-only {
    z-index: 1;
    cursor: pointer;

    .bot-answer-editor__toolbar {
      border: none;
      background: transparent;
      height: calc(100% + 4px + 4px);

      &__actions {
        display: none;
      }
    }

    &:hover .bot-answer-editor__toolbar {
      border: 1px solid @sb-bot-answer-editor-border-hover-color;
    }

    .public-DraftEditor-content {
      user-select: none !important;
    }
  }

  &__tooltip {
    padding: 0;
    min-width: 202px;

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      background: @sb-control-color-background;
      border-radius: 8px;
      max-height: 202px;
      overflow: auto;
    }
  }

  [contenteditable] {
    position: relative;
    z-index: 2;
    outline: none;

    p {
      margin: 0;
      word-break: break-word;
    }
  }

  .variable-container {
    font-size: @sb-font-size-small;
    background: @sb-bot-answer-editor-variable-container-color;
    padding: 2px 0;
    margin: 2px;
    border-radius: 6px;
    width: fit-content;
    white-space: nowrap;
    z-index: -1;
  }

  &__toolbar {
    z-index: 1;
    position: absolute;
    top: -4px;
    left: -8px;
    height: calc(100% + 4px + 4px);
    width: calc(100% + 8px + 8px);
    border-radius: @sb-border-radius-small;
    border: 2px solid @sb-editor-elements-selected-color;
    background: @sb-control-color-background;

    &__actions {
      position: absolute;
      top: calc(100% + 4px);
      left: -2px;
      display: inline-flex;
      flex-wrap: nowrap;
      align-items: center;
      background: @sb-control-color-background;
      border-radius: @sb-bot-answer-editor-toolbar-border-radius;
      box-shadow: @sb-box-shadow-default;
      padding: 4px 8px;
      width: calc(100% + 4px);

      &__button {
        padding: 0;
        margin-right: 4px;
        color: @sb-font-color-secondary-2;
        fill: @sb-font-color-secondary-2;
        font-size: @sb-font-size-x-small;
        height: 100%;
        display: inline-flex;
        align-items: center;

        &_delete {
          margin-left: auto;
          margin-right: 0;
        }

        &:hover {
          color: @sb-editor-elements-selected-color;
          fill: @sb-editor-elements-selected-color;
        }

        .sb-upload {
          height: unset !important;

          &.ant-upload.ant-upload-drag {
            border: none;
            background: transparent;

            .ant-upload-drag-container {
              display: flex;
            }

            .ant-upload-btn {
              padding: 0;
              color: @sb-font-color-secondary-2;
              fill: @sb-font-color-secondary-2;

              &:hover {
                color: @sb-editor-elements-selected-color;
                fill: @sb-editor-elements-selected-color;
              }
            }
          }
        }
      }
    }
  }

  &__inline-toolbar {
    z-index: 2;
    position: absolute;
    transform: translate(-50%, calc(-100% - 5px));
    background: @sb-control-color-background;
    border-radius: @sb-bot-answer-editor-toolbar-border-radius;
    box-shadow: @sb-box-shadow-default;
    display: inline-flex;
    align-items: center;
    padding: 0 4px;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2px;
      padding: 0;
      height: 20px;
      min-width: 20px;
      color: @sb-font-color-base;
      fill: @sb-font-color-base;
      font-size: @sb-font-size-small;
      line-height: @sb-font-size-small;

      &:hover, &_selected {
        color: @sb-editor-elements-selected-color;
        fill: @sb-editor-elements-selected-color;
      }
    }

    .ant-divider {
      height: 20px;
      margin: 4px;
    }

    &__link-input.ant-input-group {
      margin: 0 4px;
      display: flex;
      font-size: @sb-font-size-x-small;
      line-height: @sb-font-size-x-small;

      input {
        box-shadow: none;
        border-color: @sb-input-color-border;
        border-right-width: 0 !important;
        margin-right: 0 !important;
        height: 100%;
        width: 100px;
      }

      button {
        background-color: @sb-primary-color;
        border-color: @sb-primary-color;
        height: 100%;
        font-size: @sb-font-size-x-small;
        line-height: @sb-font-size-x-small;
        padding: 6px;
      }
    }
  }

  &__attachments {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    padding: 2px 0;

    &:empty {
      padding: 0;
    }

    &__content {
      display: flex;
      align-items: center;

      .sb-icon {
        margin-right: 6px;
        margin-left: 0;
        flex: 1 0 auto;
      }

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .sb-tag {
      margin: 2px;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;