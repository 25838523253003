@import "src/simple-bot/styles/variables";

.editor-select {
  position: relative;

  &__select {
    margin-top: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 48px;
    line-height: @sb-line-height-base;
    background: @sb-control-color-background;
    color: @sb-font-color-base;
    border: 1px solid @sb-editor-select-border-color;
    border-radius: @sb-border-radius-small;
    cursor: pointer;
    padding: 0 40px 0 12px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .sb-icon {
    position: absolute;
    right: 15px;
    color: @sb-icon-color-base;
  }

  &_warning &__select {
    border-color: @sb-editor-elements-warning-color;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;