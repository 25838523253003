@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-alert {
  width: fit-content;
  max-width: calc(100% - @ib-padding-base * 2);
  display: flex;
  padding: @ib-padding-x-small @ib-padding-base;
  background: @gray-gray-900;
  border-radius: @ib-border-radius-x-small;

  &__icon-container {
    .block-size(20px);
    margin-right: 8px;
    flex-shrink: 0;

    &__success {
      color: @green-green-300 !important;
    }

    &__error {
      color: @red-red-600 !important;
    }
  }

  &__content {
    color: @gray-gray-50;
  }

  .ib-button_icon {
    .block-size(20px);
    color: @gray-gray-400;
    margin-left: 24px;
    flex-shrink: 0;

    &:hover {
      color: @gray-gray-200;
    }
  }

  .ib-icon {
    .block-size(20px);
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;