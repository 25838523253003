@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-color-picker {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: @ib-padding-xxxx-small;

  @media (max-width: @ib-breakpoint-small) {
    gap: @ib-padding-xx-small;
  }

  &__color-block {
    .block-size(40px);
    cursor: pointer;
    border-radius: @ib-border-radius-x-small;
    display: flex;
    justify-content: center;
    align-items: center;

    .ib-icon {
      opacity: 0;
      color: @white-white-50 !important;
    }
  }

  &__selected &__color-block .ib-icon {
    opacity: 1;
  }

  &__is-light &__color-block {
    border: 1px solid @gray-gray-200;

    .ib-icon {
      color: @blue-blue-700 !important;
    }
  }

  &__custom {
    &:not(:first-child) {
      margin-left: @ib-padding-xxx-small;
    }

    ~ .ib-color-picker__detection {
      margin-left: 0;
    }
  }

  &__detection {
    margin-left: @ib-padding-xxx-small;

    &__icon-wrapper {
      border: 1px solid @gray-gray-200;
      background: @white-white-50;

      .ib-icon {
        opacity: 1;
        color: @gray-gray-200 !important;
      }
    }
  }

  &__popover {
    display: flex;
    flex-direction: column;
    gap: @ib-padding-xx-small;

    .react-colorful {
      @media (max-width: @ib-breakpoint-small) {
        width: 100%;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;