@import "src/simple-bot/styles/variables";

@sb-binding-group-placeholder-box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
@sb-binding-group-placeholder-bg-color: #FAFAFA;
@sb-binding-group-placeholder-child-bg-color: #E8E8E8;
@sb-binding-group-placeholder-possible-bg-color: #EAF0F7;
@sb-binding-group-placeholder-possible-box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.04);;

svg.sb-binding-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
  background: transparent;
  pointer-events: none;
  z-index: @sb-editor-group-container-z-index - 1;

  .sb-binding {
    position: absolute;
    pointer-events: visibleStroke;
    cursor: pointer;
    fill: transparent;
    stroke-linecap: round;
    stroke: @sb-binding-base-color;
    stroke-width: 2;

    &:hover {
      stroke: @sb-binding-hover-color;
    }

    &__subsidiary {
      stroke-width: 10;
      opacity: 0;
    }
  }

  &_highlighted {
    z-index: @sb-editor-group-container-z-index + 2; // NOTE: делаем выше чем выбранная гуппа, т.к. у выбранной группы +1

    .sb-binding, .sb-binding:hover {
      stroke: @sb-editor-elements-selected-color;
    }
  }
}

.sb-binding-context-menu {
  position: absolute;
  transform-origin: top left;
  z-index: @sb-bindings-context-menu-z-index;
}

.sb-binding-group-placeholder {
  pointer-events: none;
  position: absolute;
  width: @sb-editor-group-container-width;
  border: 2px solid @sb-editor-elements-selected-color;
  box-shadow: @sb-binding-group-placeholder-box-shadow;
  border-radius: 20px;
  background: @sb-binding-group-placeholder-bg-color;
  z-index: @sb-binding-group-placeholder-z-index;
  padding: 12px 16px 34px;

  &__header {
    display: flex;

    &::before {
      height: 32px;
      width: 32px;
      background: @sb-binding-group-placeholder-child-bg-color;
      content: '';
      border-radius: 50%;
    }

    &::after {
      margin-left: 6px;
      height: 32px;
      flex-grow: 1;
      background: @sb-binding-group-placeholder-child-bg-color;
      content: '';
      border-radius: 16px;
    }
  }

  &__content {
    margin-top: 16px;
    width: 100%;
    height: 48px;
    border-radius: 24px;
    background: @sb-binding-group-placeholder-child-bg-color;

    &::after {
      margin-left: 6px;
      width: 14px;
      height: 14px;
      background: @sb-binding-group-placeholder-child-bg-color;
      content: '';
      border-radius: 50%;
    }
  }
}

.sb-binding-group-placeholder-possible {
  pointer-events: none;
  position: absolute;
  width: @sb-editor-group-container-width;
  height: @sb-binding-group-placeholder-height;
  background: @sb-binding-group-placeholder-possible-bg-color;
  border-radius: 20px;
  box-shadow: @sb-binding-group-placeholder-possible-box-shadow;
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;