@import "src/simple-bot/styles/variables";

@sb-avatar-bg-color: #F5F5F5;

.sb-dialogs-block {
  display: flex;
  gap: 32px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__header {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__content {

    .sb-panel {
      margin-bottom: 16px;
      line-height: @sb-line-height-base;
      font-size: @sb-font-size-base;
      font-weight: @sb-font-weight-bold;
      color: @sb-font-color-base;
    }

    &__info {
      display: flex;
      gap: 16px;
      margin-top: 16px;
      align-items: center;

      &__channel-name {
        display: flex;
        gap: 4px;

        &__title {
          line-height: @sb-line-height-base;
          font-size: @sb-font-size-base;
          color: @sb-font-color-base;
        }
      }

      &__title-main {
        line-height: @sb-line-height-base;
        font-size: @sb-font-size-base;
        color: @sb-font-color-base;
      }

      &__title-optional {
        line-height: @sb-line-height-x-small;
        font-size: @sb-font-size-x-small;
        color: @sb-font-color-secondary;
      }
    }

    &__buttons {
      button {
        justify-content: center;
        white-space: break-spaces;
        width: 100%;
        margin-top: 16px;

        @media screen and (max-width: 1280px) {
          font-size: @sb-font-size-small !important;
          height: auto !important;
          min-height: 38px;
        }
      }

      > :first-child {
        margin-top: 32px;
      }
    }

    .ant-avatar {
      background-color: @sb-avatar-bg-color;
      color: @sb-primary-color;
      line-height: @sb-line-height-xxx-large !important;
      flex-shrink: 0;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;