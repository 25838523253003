@import "src/simple-bot/styles/variables";

.sb-knowledge-base-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    &_left {
      display: flex;
      align-items: center;
    }

    h2 {
      margin-right: 12px;
    }
  }

  &__content {
    margin-top: 10px;
    color: @sb-font-color-secondary;
    display: flex;
    flex-direction: column;
    flex: 1;

    .ant-divider {
      height: 20px;
      margin: 0 12px;
      border-left: @sb-border-default;
    }

    &__info {
      margin-top: auto;

      span {
        font-size: @sb-font-size-small;
        line-height: @sb-line-height-small;
      }
    }

    .sb-button {
      font-size: @sb-font-size-medium;
      height: 40px;
      margin: 20px auto 0 0;
    }
  }

  &__settings-modal {

    &__slider {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      margin-bottom: 24px;
    }

    &__select {
      margin-bottom: 24px;
    }

    &__small-text {
      font-size: @sb-font-size-small;
      margin-bottom: 20px;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;