@import "src/simple-bot/styles/variables";

@sb-channel-icon-background-color: #F5F5F5;

.sb-channel-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &_normal {
    background-color: @sb-channel-icon-background-color;
    width: 40px;
    height: 40px;
    border-radius: 12px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &_small {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;