@import "src/simple-bot/styles/variables";

.sb-menu__sub-menu {
  color: @sb-font-color-base !important;

  &:hover {
    background: @sb-dropdown-menu-list-item-bg-hover-color;
    color: inherit;
  }

  &:active {
    background: @sb-dropdown-menu-list-item-bg-active-color !important;
    color: inherit !important;
  }

  &-disabled {
    opacity: 0.4;
    color: inherit !important;
  }

  &-selected {
    color: @sb-primary-color;
    background: none;
  }

  .ant-menu-submenu-title {
    margin: 0;

    &:active {
      background: @sb-dropdown-menu-list-item-bg-active-color !important;
      color: inherit !important;
    }

    .ant-menu-submenu-arrow {
      color: inherit !important;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;