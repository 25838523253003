@import "src/inbox/styles/variables";

.ib-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: @ib-font-size-x-small;
  line-height: @ib-line-height-x-small;
  font-weight: @ib-font-weight-normal;
  min-width: 20px;
  min-height: 20px;
  padding: 2px 6px;
  border-radius: 45px;
  color: @ib-white-color;
  background: @ib-primary-color;

  &_disabled {
    color: @gray-gray-300;
    background: @ib-badge-disabled-bg-color;
  }

  &.mention {
    background-color: @orange-orange-500;
    color: @white-white-50;
    padding: 0;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;