@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-page-title {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 @ib-padding-large;
  height: 80px;
  flex-shrink: 0;

  @media (max-width: @ib-breakpoint-small) {
    height: 64px;
    padding: 0 @ib-padding-base;
  }

  .ib-account-widget {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: block;
      margin-right: @ib-padding-base;
    }
  }

  h2 {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  h4 {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: inline-flex;
      margin-right: auto;
    }
  }

  .ib-button {
    margin-left: @ib-padding-xx-small;

    @media (max-width: @ib-breakpoint-small) {
      .block-size(@ib-element-size-24);
    }

    .ib-icon {
      @media (max-width: @ib-breakpoint-small) {
        .block-size(@ib-element-size-24);
      }
    }
  }

  .ib-tags-search {
    margin: @ib-padding-base 0 0 @ib-padding-base;
    width: 420px;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  .ib-sort-button {
    margin-left: @ib-padding-xx-small;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  &__content {
    &_desktop {
      margin-left: auto;

      @media (max-width: @ib-breakpoint-small) {
        display: none;
      }
    }

    &_mobile {
      margin-left: 0;
      display: none;

      @media (max-width: @ib-breakpoint-small) {
        display: flex;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;