@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-timepicker {
  padding: 0 @ib-padding-xx-small 0 @ib-padding-base;
  height: 40px;
  border: 1px solid @gray-gray-200;
  border-radius: @ib-border-radius-x-small;
  background: @white-white-50;
  transition: all 0.2s ease-out;
  outline: none;
  box-shadow: none;
  width: 116px;

  .ant-picker-input {
    input {
      font-size: @ib-font-size-small;
      line-height: @ib-line-height-base;
      color: @gray-gray-900;
    }
  }

  &:hover, &.ant-picker-focused {
    border: 1px solid @blue-blue-700;
  }

  &.ant-picker-disabled {
    background: @gray-gray-100;
    border: 1px solid @gray-gray-200;

    .ant-picker-input input,
    .ant-picker-suffix .ib-icon {
      color: @gray-gray-200 !important;
    }
  }     
}

.ib-timepicker-popup {
  .ant-picker-panel-container {
    box-shadow: @ib-popover-box-shadow;
    border-radius: @ib-border-radius-x-small;
    padding: @ib-padding-xx-small 0 0 0;

    .ant-picker-panel {
      border-bottom: none;

      .ant-picker-time-panel {
        padding: 0;

        .ant-picker-time-panel-column {
          width: 50px;
          
          &:not(:first-child) {
            border-left: 1px solid @gray-gray-200;
          }

          .ant-picker-time-panel-cell-inner {
            font-size: @ib-font-size-small;
            line-height: @ib-line-height-x-large;
            color: @gray-gray-900;
            padding: 0 0 0 @ib-padding-base;
            background: @white-white-50 !important;

            &:hover {
              color: @blue-blue-700;
            }
          }

          .ant-picker-time-panel-cell-selected {
            .ant-picker-time-panel-cell-inner {
              color: @blue-blue-700;
            }
          }
        }
      }

      .ant-picker-footer {
        border-top: 1px solid @gray-gray-200;
        
        .ant-picker-ranges {
          padding: @ib-padding-xx-small;

          .ant-picker-ok {
            .ant-btn {
              text-shadow: none;
              box-shadow: none;
              font-size: @ib-font-size-small;
              line-height: @ib-line-height-base;
              font-weight: @ib-font-weight-bold;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 @ib-padding-base;
              gap: 8px;
              height: 40px;
              border-radius: @ib-border-radius-x-small;
              outline: none;
              cursor: pointer;
              transition: all 0.2s ease-out;
              overflow: hidden;
              flex-shrink: 0;
              border: none;
              background: @blue-blue-50;
              color: @blue-blue-700;

              &:hover {
                background: @blue-blue-200;
              }

              &:active {
                background: @blue-blue-200;
                color: @blue-blue-800;
              }
            }
          }
        }
      }
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;