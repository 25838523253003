@import "src/simple-bot/styles/variables";

.sb-add-scenario-list-card {
  border: 2px solid @sb-scenario-list-card-active;

  &:hover {
    border: 2px solid @sb-scenario-list-card-active;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .sb-button {
        width: 40px;
        height: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
      }

      &__text {
        margin-top: 20px;
        color: @sb-primary-color;
        font-size: @sb-font-size-x-large;
        font-weight: @sb-font-weight-bold;
        line-height: @sb-line-height-x-large;
        text-align: center;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;