@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-textarea {
  display: flex;
  padding: @ib-padding-xx-small;
  border: 1px solid @gray-gray-200;
  border-radius: @ib-border-radius-x-small;
  background: @white-white-50;
  transition: all 0.2s ease-out;
  cursor: text;

  textarea {
    font-size: @ib-font-size-small;
    line-height: @ib-line-height-base;
    color: @gray-gray-900;
    padding: 0 @ib-padding-xx-small;
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    min-height: 62px;
    max-height: 300px;

    &::-webkit-resizer {
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABLSURBVHgBpY7JDQAgCATFWJCUYoWWYCnQEYovL4yJ+2LJZDPO/YSIIjOT3sECAKCISNIONwAReYNOgMZbwOZ0AkanvtQeeXJY+lMq5BVDp+LKFxQAAAAASUVORK5CYII=') no-repeat right bottom;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &::placeholder {
      color: @gray-gray-300;
    }
  }

  &:focus-within {
    border: 1px solid @blue-blue-700;
  }

  &_success,
  &_success&:focus-within {
    border: 1px solid @green-green-500;
  }

  &_error,
  &_error&:focus-within {
    border: 1px solid @red-red-600;
  }

  &_disabled {
    background: @gray-gray-100;
    cursor: not-allowed;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;