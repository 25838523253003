@import "src/inbox/styles/variables";

.ib-message {
  display: inline;
  font-size: @ib-font-size-small;
  word-break: break-word;

  &_not-inline {
    & > *:last-child {
      display: inline;
    }
  }

  &_inline {
    & > *:first-child {
      display: inline;
    }
  }

  .mention, .mention > a {
    color: @blue-blue-700;
  }

  @media (max-width: @ib-breakpoint-small) {
    .DraftEditor-root {
      pointer-events: none;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;