@import "src/simple-bot/styles/variables";

.sb-upload {
  &.ant-upload.ant-upload-drag {
    background: @sb-control-color-background;
    border: @sb-border-default-dashed;
    border-width: 2px;
    border-radius: @sb-border-radius-medium;

    &:hover {
      border-color: @sb-primary-color;
    }
  }

  &__content {
    font-size: @sb-font-size-base;
    line-height: @sb-line-height-base;
    color: @sb-font-color-secondary;

    span {
      color: @sb-primary-color;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;