@import "src/simple-bot/styles/variables";

.sb-scroll {

  > *:first-child {
    height: 100%;
  }

  &.ps--active-y {
    padding-right: 28px;

    > *:first-child {
      height: unset;
    }
  }

  > .ps__rail-y {
    background: none !important;
    opacity: 1 !important;

    > .ps__thumb-y {
      width: 8px;
      background: @sb-scroll-color-thumb;
      margin-right: -2px;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;