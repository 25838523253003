@import "src/simple-bot/styles/variables";
@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-scenario-list-block {
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
  width: 100%;
  gap: @ib-padding-base;

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-gap: @ib-padding-base;
    justify-items: stretch;
    width: 100%;
    padding-bottom: @ib-padding-base;

    @media (max-width: @ib-breakpoint-medium) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: @ib-breakpoint-small) {
      grid-template-columns: 1fr;
    }

    &__item {
      min-width: 0;

      .sb-add-scenario-list-card {
        height: 100%;
      }
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: @ib-padding-large;

    &__description {
      text-align: center;
      padding-bottom: @ib-padding-base;
    }
  }

  &__add-scenario-list-item {
    @media (max-width: @ib-breakpoint-small) {
      display: none !important;
    }
  }

  &__skeleton {
    display: flex;
    flex-direction: column;
    gap: @ib-padding-small;
    padding: @ib-padding-xx-small;
    width: 100%;

    &__row {
      display: flex;
      gap: @ib-padding-small;

      > span {
        flex-grow: 1;

        &:not(:first-child) {
          @media (max-width: @ib-breakpoint-small) {
            display: none;
          }
        }

        .react-loading-skeleton {
          height: 32px;
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;