@import "src/simple-bot/styles/variables";

.sb-system-intent-table-row {
  display: flex;
  width: 100%;

  * {
    font-size: @sb-font-size-small;
    line-height: @sb-line-height-small;
  }

  &__cell {
    &:first-child {
      flex-grow: 1;
      overflow: hidden;
      padding-right: 10px;
    }

    p {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }
  }

  .sb-tag {
    color: @sb-font-color-base;
    height: 24px;
    padding: 1px 8px;
  }
}

.sb-intent-selector-group-filter {
  width: 250px;
}

.sb-user-intent-selector-table-column-name {
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sb-icon {
    margin-left: 8px;
    padding-right: 16px;
    color: @sb-error-color;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;