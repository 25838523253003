@import "src/simple-bot/styles/variables";
@import "src/simple-bot/styles/mixins";

.sb-bot-card {
  display: flex;
  flex-direction: column;

  &__header {
    justify-content: space-between;
    margin-top: 10px;

    &__left {
      h1 {
        font-size: @sb-font-size-title-medium;
        font-weight: @sb-font-weight-bold;
        line-height: @sb-line-height-title-medium;
        color: @sb-font-color-base;
        margin-bottom: 5px;
        .text-ellipsis;

        span {
          color: @sb-font-color-secondary-2;
        }
      }
    }

    &__right {
      display: inline-flex;

      .sb-button {
        height: 40px;
        font-size: @sb-font-size-medium;
        line-height: @sb-line-height-x-large;

        &_icon-primary-48 {
          width: 40px;
          margin-right: 12px;
        }
      }

      .sb-webchat {
        z-index: 2;
        position: fixed;
        right: 20px;
        height: 70vh;
        bottom: 20px;
      }

      &__actions-button {
        margin-right: 12px;

        &__import-upload.sb-upload {
          height: unset !important;

          &.ant-upload.ant-upload-drag {
            border: none;
            background: transparent;

            .ant-upload-drag-container {
              display: flex;
            }
          }
        }
      }
    }
  }

  &__content {
    flex-grow: 1;

    &__block {
      padding-top: 20px;

      &:not(:first-child) {
        padding-left: 20px;
      }

      &__inner-wrapper {
        background: @sb-control-color-background;
        padding: 20px 26px;
        border: @sb-border-default;
        border-radius: @sb-border-radius-large;
        height: 100%;

        &_hoverable {
          &:hover {
            cursor: pointer;
            box-shadow: @sb-box-shadow-default;
          }
        }

        &__header {
          h2 {
            font-size: @sb-font-size-x-large;
            font-weight: @sb-font-weight-bold;
            line-height: @sb-line-height-x-large;
            color: @sb-font-color-base;
            margin: 0;
          }
        }

        &__description {
          font-size: @sb-font-size-base;
          line-height: @sb-line-height-base;
          color: @sb-font-color-secondary;
          margin-bottom: auto;
        }
      }
    }
  }

  &__loader {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }
}

.ant-popover {
  width: 313px;

  .ant-popover-inner {
    box-shadow: @sb-box-shadow-default;

    .ant-popover-inner-content {
      padding-left: 20px;
      padding-right: 20px;

      font-size: @sb-font-size-small;
      line-height: @sb-line-height-small;
      font-weight: 400;

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;