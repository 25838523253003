@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-tooltip {
  display: flex;
  flex-shrink: 0;
  width: min-content;
}

.ib-tooltip-overlay {
  overflow: hidden;

  .ant-tooltip-inner {
    background-color: @gray-gray-700;
    box-shadow: none;
    border-radius: @ib-border-radius-xxxx-small;
    padding: @ib-padding-xxxx-small @ib-padding-xx-small;
    font-size: @ib-font-size-x-small;
    line-height: @ib-line-height-x-small;
    color: @white-white-50;
    font-weight: @ib-font-weight-normal;
    min-height: 24px;
  }

  .ant-tooltip-arrow-content {
    .block-size(22px);
    background-color: @gray-gray-700;
    box-shadow: none;
    transform: rotate(45deg);
  }

  .ant-tooltip-arrow {
    .block-size(30px);
    transform: none;
    z-index: -1;
  }

  &.ant-tooltip-placement-top {
    padding-bottom: @ib-tootip-arrow-offset;

    .ant-tooltip-arrow {
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }

  &.ant-tooltip-placement-topLeft {
    padding-bottom: @ib-tootip-arrow-offset;

    .ant-tooltip-arrow {
      left: 0;
      bottom: 0;
    }
  }

  &.ant-tooltip-placement-topRight {
    padding-bottom: @ib-tootip-arrow-offset;

    .ant-tooltip-arrow {
      right: 0;
      bottom: 0;
    }
  }

  &.ant-tooltip-placement-right {
    padding-left: @ib-tootip-arrow-offset;

    .ant-tooltip-arrow {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.ant-tooltip-placement-rightTop {
    padding-left: @ib-tootip-arrow-offset;

    .ant-tooltip-arrow {
      left: 0;
      top: -3px;
    }
  }

  &.ant-tooltip-placement-rightBottom {
    padding-left: @ib-tootip-arrow-offset;

    .ant-tooltip-arrow {
      left: 0;
      bottom: -3px;
    }
  }

  &.ant-tooltip-placement-bottom {
    padding-top: @ib-tootip-arrow-offset;

    .ant-tooltip-arrow {
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }

  &.ant-tooltip-placement-bottomLeft {
    padding-top: @ib-tootip-arrow-offset;

    .ant-tooltip-arrow {
      left: 0;
      top: 0;
    }
  }

  &.ant-tooltip-placement-bottomRight {
    padding-top: @ib-tootip-arrow-offset;

    .ant-tooltip-arrow {
      right: 0;
      top: 0;
    }
  }

  &.ant-tooltip-placement-left {
    padding-right: @ib-tootip-arrow-offset;

    .ant-tooltip-arrow {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.ant-tooltip-placement-leftTop {
    padding-right: @ib-tootip-arrow-offset;

    .ant-tooltip-arrow {
      right: 0;
      top: -3px;
    }
  }

  &.ant-tooltip-placement-leftBottom {
    padding-right: @ib-tootip-arrow-offset;

    .ant-tooltip-arrow {
      right: 0;
      bottom: -3px;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;