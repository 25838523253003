@import "src/simple-bot/styles/variables";

.sb-progress-status-modal {
  &__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    margin: @sb-padding-xxxx-small @sb-padding-large;

    &__icon {
      &_error {
        color: @sb-error-color !important;
      }

      &_success {
        color: @sb-success-color !important;
      }

      margin-bottom: @sb-padding-xx-large;
    }

    &__header {
      margin-bottom: @sb-padding-base;
    }

    .sb-spin {
      height: 100%;
      margin-bottom: @sb-padding-xx-large;

      span {
        font-size: @sb-font-size-xxx-large;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;