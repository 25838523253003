@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-channel-list-item {
  display: flex;
  flex-direction: column;
  padding: @ib-padding-large;
  border: 1px solid @gray-gray-200;
  border-radius: @ib-border-radius-x-large;
  height: 302px;
  width: calc((100% - 16px) / 2); // 16px - это gap в .ib-channel-list

  @media (max-width: @ib-breakpoint-small) {
    height: unset;
    width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: @ib-padding-large;

    &__row {
      display: flex;
      align-items: center;
      gap: @ib-padding-base;
      min-height: @ib-element-size-48;

      @media (max-width: @ib-breakpoint-small) {
        min-height: unset;
      }

      .ib-social {
        .block-size(@ib-element-size-40);
      }

      .ib-typography {
        flex-grow: 1;

        .ib-typography__paragraph {
          display: none;

          @media (max-width: @ib-breakpoint-small) {
            .text-ellipsis(2);
          }
        }

        h4 {
          .text-ellipsis(2);

          @media (max-width: @ib-breakpoint-small) {
            display: none;
          }
        }
      }

      .ib-tag {
        flex-shrink: 0;
      }

      .ib-button {
        .block-size(@ib-element-size-24);
      }

      &:first-child {
        .ib-tag, .ib-switch {
          @media (max-width: @ib-breakpoint-small) {
            display: none;
          }
        }
      }

      &:last-child {
        display: none;

        @media (max-width: @ib-breakpoint-small) {
          display: flex;
        }
      }
    }
  }

  &__settings {
    display: flex;
    flex-direction: column;
    gap: @ib-padding-base;
    margin-top: @ib-padding-large;
    padding-top: @ib-padding-large;
    border-top: 1px solid @gray-gray-100;

    &__row {
      display: flex;

      > div:first-child {
        flex-shrink: 0;
        width: 176px;

        @media (max-width: @ib-breakpoint-small) {
          width: 150px;
        }
      }

      div:last-child {
        .text-ellipsis;

        @media (max-width: @ib-breakpoint-small) {
          white-space: unset;
        }
      }

      &__link {
        display: flex;
        gap: 4px;

        a {
          .text-ellipsis;

          color: @blue-blue-700;
          font-size: @ib-font-size-small;
          line-height: @ib-line-height-base;

          @media (max-width: @ib-breakpoint-small) {
            white-space: unset;
          }
        }

        &__button {
          &_success {
            color: @green-green-500;

            &:hover {
              color: @green-green-500;
            }
          }
        }
      }
    }

    > span {
      line-height: @ib-line-height-x-small;
      font-size: @ib-font-size-base;
      width: 378px;

      @media (max-width: @ib-breakpoint-small) {
        width: 180px;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;