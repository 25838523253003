@import "src/simple-bot/styles/variables";

@sb-markdown-editor-variable-container-color: #E6E6E6;

.sb-markdown-editor {
  border: @sb-border-default;
  border-radius: @sb-border-radius-small;
  border-width: 2px;
  padding: 6px 12px 12px;
  font-size: @sb-font-size-base;
  line-height: @sb-line-height-base;
  transition: all 0.3s;

  .variable-container {
    font-size: @sb-font-size-small;
    background: @sb-markdown-editor-variable-container-color;
    padding: 2px 0;
    margin: 2px;
    border-radius: 6px;
    width: fit-content;
    white-space: nowrap;
    z-index: -1;
  }

  &_read-only {
    padding: 0;
    border: none;
    border-radius: 0;

    .sb-markdown-editor__toolbar {
      display: none;
    }
  }

  &_warning {
    border-color: @sb-editor-elements-warning-color !important;
  }

  [contenteditable] {
    p {
      margin: 0;
      word-break: break-word;
    }
  }

  &__toolbar {
    margin-bottom: 6px;

    &__actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4px;
        padding: 0;
        height: 24px;
        width: 24px;
        color: @sb-font-color-base;
        fill: @sb-font-color-base;
        font-size: @sb-font-size-small;
        line-height: @sb-font-size-small;

        &:hover, &_selected {
          color: @sb-editor-elements-selected-color;
          fill: @sb-editor-elements-selected-color;
        }
      }

      .ant-divider {
        height: 24px;
        margin: 6px;
      }

      &__link-tooltip-content.ant-input-group {
        display: flex;
        font-size: @sb-font-size-small;
        line-height: @sb-font-size-small;

        input {
          box-shadow: none;
          border-color: @sb-input-color-border;
          border-right-width: 0 !important;
          margin-right: 0 !important;
        }

        button {
          background-color: @sb-primary-color;
          border-color: @sb-primary-color;
        }
      }
    }
  }

  &:hover, &:focus-within {
    border-color: @sb-input-color-hover !important;
  }

  .highlighted {
    color: @sb-control-color-background;
    background: @sb-primary-color;
    border-radius: 2px;
    padding: 2px;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;