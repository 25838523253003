@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-scenarios-card {
  &__extra {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }

    &_mobile {
      display: none;

      @media (max-width: @ib-breakpoint-small) {
        display: block;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - @ib-padding-xx-small * 1 / 2), 1fr));
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-gap: @ib-padding-xx-small;
    justify-items: stretch;
    width: 100%;
    padding-bottom: @ib-padding-x-small;

    @media (max-width: @ib-breakpoint-medium) {
      grid-template-columns: 1fr;
    }

    &__scenario-item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: @ib-padding-xx-small;

      &__status {
        .block-size(8px);
        border-radius: 50%;
        background-color: @gray-gray-300;
        flex-shrink: 0;

        &_enabled {
          background-color: @green-green-500;
        }
      }

      :last-child {
        .text-ellipsis();
      }
    }

    &__show-more {
      cursor: pointer;
      gap: @ib-padding-xxxx-small;

      &:hover {
        .ib-badge {
          background-color: @blue-blue-600;
        }
      }

      .ib-badge {
        flex-grow: 0;
        padding: 0;
      }

      .ib-button {
        display: block;
        flex-shrink: 1;
        min-width: 0;
      }
    }
  }

  &__footer {
    display: flex;
    gap: @ib-padding-xxx-small;
    align-items: baseline;
    height: 24px;

    :first-child {
      .text-ellipsis();
    }

    .ib-divider {
      height: 20px;
      transform: translateY(@ib-padding-xxx-small);
    }

    :nth-child(3) {
      flex-grow: 1;
      min-width: 0;
      .text-ellipsis();
    }

    .ib-typography__paragraph {
      text-transform: lowercase;
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;