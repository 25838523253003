@import "src/simple-bot/styles/variables";

@sb-operand-max-width: 198px;

.main-case-action-condition-view {
  margin: 0 -28px 0 -4px;
  display: flex;
  flex-wrap: wrap;

  &_top {
    margin-right: -4px;
  }

  &__operator {
    margin: 4px;
  }

  &__constant {
    margin: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: @sb-operand-max-width;
  }

  &__variable {
    margin: 4px;
    padding: 0 8px;
    background: @sb-variable-tag-background-color;
    color: @sb-control-color-foreground;
    border-radius: @sb-border-radius-x-small;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: @sb-operand-max-width;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;