@import "src/simple-bot/styles/variables";

.user-answer {

  .input-settings {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 8px;
      width: 100%;
      line-height: @sb-line-height-base;

      &-prefix {
        color: @sb-font-color-secondary-2;
        margin-right: 8px;
        white-space: nowrap;
      }

      &-value {
        cursor: pointer;
        overflow: hidden;

        .configure {
          color: @sb-editor-elements-selected-color;
        }
      }
    }
  }

  &_warning .input-settings {
    border: 1px solid @sb-editor-elements-warning-color;
    border-radius: @sb-border-radius-small;
    padding: 2px 7px;
    margin: 5px -8px -3px -8px;

    &__item {
      margin-top: 0;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;