@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-account-widget {

  .ib-avatar_x-small {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
    }
  }

  .ib-avatar_medium {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  &__user-info-popover {
    .ant-popover-inner-content {
      width: 244px;

      @media (max-width: @ib-breakpoint-small) {
        width: 100%;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: @ib-padding-xx-small;

      &__info-area {
        display: flex;
        gap: @ib-padding-xx-small;

        &__text-part {
          display: flex;
          flex-direction: column;
        }
      }

      &__status-area {
        display: flex;
        align-items: center;
        gap: @ib-padding-xx-small;
        margin: @ib-padding-xxxx-small 0;

        *:first-child {
          flex-grow: 1;
        }

        *:last-child {
          min-width: 50px;
          text-align: right;
        }
      }

      &__buttons-area {
        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li > div {
            display: flex;
            gap: @ib-padding-xx-small;
            padding: @ib-padding-xx-small 0;
            cursor: pointer;

            &:hover {
              color: @blue-blue-700;

              .ib-icon {
                color: @blue-blue-700 !important;
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;