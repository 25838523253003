@import "src/simple-bot/styles/variables";

@sb-context-menu-icon-hover-bg-color: #F5F5F5;
@sb-context-menu-icon-active-bg-color: #E8E8E8;

.context-menu {
  position: relative;

  &__content {
    display: flex;
    align-items: center;

    > .sb-icon {
      width: 24px;
      height: 24px;
      background: transparent;
      color: @sb-font-color-secondary-2;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background: @sb-context-menu-icon-hover-bg-color;
      }

      &:active {
        background: @sb-context-menu-icon-active-bg-color;
      }
    }
  }

  & .ant-divider-horizontal {
    margin: 6px 0;
  }

  &_hover {
    // NOTE: размер контейнера кнопки немного больше справа, чтобы ховер не прерывался
    // при перемещении курсора из кнопки во всплывающее меню
    margin-right: -10px;
    padding-right: 10px;
  }

  &_hovered {
    visibility: visible !important;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;