@import "src/simple-bot/styles/variables";

.variable-selector-field {
  display: flex;
  min-height: @sb-line-height-xx-large;

  &__variable-button {
    display: inline-flex;
    align-items: center;
    gap: @sb-padding-xxxx-small;
    cursor: pointer;
    color: @sb-editor-elements-selected-color;

    span {
      font-style: italic;
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;