@import "src/simple-bot/styles/variables";

@sb-action-button-bg-color: #E8E8E8;

.menu-button {
  margin-top: 8px;
  padding: 6px 14px 6px 12px;
  min-height: 48px;
  width: 100%;
  background: @sb-action-button-bg-color;
  border-radius: @sb-border-radius-small;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-weight: @sb-font-weight-bold;
    width: 100%;

    span {
      display: -webkit-box;
      max-width: 100%;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      line-height: 18px;
      text-align: center;
      word-break: break-all;
    }

    input {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      color: inherit;
      padding: 0;
      border: none;
      background-color: transparent;
      box-shadow: none !important;
      border-radius: 0;
    }
  }

  .output-connection {
    margin-left: 8px;
  }

  .context-menu {
    position: absolute;
    left: calc(100% - 1px);
    top: 50%;
    transform: translateY(-50%);

    &__content > .sb-icon {
      background: transparent;
    }
  }

  &:hover, &_selected {
    &:not(.editing) {
      box-shadow: @sb-button-box-shadow;

      .context-menu {
        visibility: visible;
      }
    }
  }

  &_editing {
    background: @sb-control-color-background;
    border: 2px solid @sb-editor-elements-selected-color;
    box-shadow: none;

    .output-connection {
      position: absolute;
      right: -2px;
      top: 50%;
      opacity: 0;
      transform: translate(0, -50%);
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;