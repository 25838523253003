@import "src/simple-bot/styles/variables";

.create-elma365-app-element-action-settings-modal {
  .ant-divider {
    margin: 12px 0 0 0;
  }

  &__app-updated-info {
    margin-top: 12px;
  }

  .elma365-form-properties {
    margin-top: 16px;
  }

  .sb-modal__col-main .sb-scroll {
    padding-right: 0;

    > div:first-child {
      margin-right: 20px;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;