@import "src/simple-bot/styles/variables";
@import "src/inbox/styles/variables";

.sb-bot-settings-modal {
  h2 {
    font-size: @sb-font-size-medium;
    line-height: @sb-line-height-medium;
  }

  h2:not(:first-child) {
    margin-top: 14px;
  }

  .ant-modal-body {
    min-height: 0;
    height: calc(~"100vh - 50px");
  }

  .ant-tabs-content-holder {
    height: auto;
  }

  .ant-tabs-tabpane {
    display: flex;
    flex-direction: column;
  }

  &__general {
    max-height: 100%;

    @media (max-width: @ib-breakpoint-small) {
      flex-flow: column nowrap;

      & > .ant-col {
        max-width: 100% !important;
      }
    }

    &__main-area {
      .sb-select {
        max-width: 600px;
      }
    }

    .sb-panel-help {
      h3:not(:first-child) {
        margin-top: 14px;
      }

      li:not(:first-child) {
        margin-top: 14px;
      }
    }
  }

  &__integrations {
    h3, .sb-input, .sb-button {
      margin-left: 16px;
      max-width: 400px;
      display: flex;
      align-items: center;

      @media (max-width: @ib-breakpoint-small) {
        margin-left: 0;
      }

      .sb-tooltip {
        display: flex;
        margin-left: 4px;

        .sb-icon {
          margin-bottom: 4px;
        }
      }
    }

    &__check-error.sb-button {
      color: @sb-warning-color;
      border-color: @sb-warning-color;
    }

    &__check-success.sb-button {
      color: @sb-success-color;
      border-color: @sb-success-color;
    }

    &__tooltip {
      max-width: 400px;
    }
  }

  &__recognition-settings {
    &__slider {
      max-width: 400px;
      display: flex;
      align-items: center;
      gap: 24px;
      margin-bottom: 24px;
    }

    &__small-text {
      font-size: @sb-font-size-small;
      margin-bottom: 20px;
    }
  }

  &__api-settings {
    h3, .sb-input {
      display: flex;
      gap: 4px;
      max-width: 400px;
    }

    &__field {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  &__smtp-settings {
    h3, .sb-input, .sb-password {
      max-width: 400px;
    }

    .sb-switch {
      margin-top: 32px;
    }

    button {
      color: @sb-icon-color-base
    }
  }

  &__script-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 24px;

    @media (max-width: @ib-breakpoint-small) {
      flex-flow: column nowrap;
    }

    &__editor {
      width: 75%;

      @media (max-width: @ib-breakpoint-small) {
        width: 100%;
        height: 50%;
      }
    }
  }

  &__additional {
    .sb-switch {
      padding: 8px 0;
    }

    .ant-collapse-header {
      display: none;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
      padding-bottom: 16px;
    }

    .sb-typography {
      h3, .sb-input, .sb-switch {
        margin-left: 16px;
        max-width: 400px;
        display: flex;
        align-items: center;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;