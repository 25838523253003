@import "src/simple-bot/styles/variables";

@sb-switch-handle-size: 20px;
@sb-switch-handle-horizontal-margin: 4px;

.sb-switch {
  &__switch.ant-switch {
    min-width: 45px;
    height: 26px;
    line-height: 26px;
  }

  &__switch > .ant-switch-handle {
    width: @sb-switch-handle-size;
    height: @sb-switch-handle-size;
    top: 3px;
    left: @sb-switch-handle-horizontal-margin;

    &::before {
      border-radius: @sb-border-radius-small;
    }
  }

  &__switch.ant-switch-checked {
    background-color: @sb-primary-color;

    .ant-switch-handle {
      left: calc(100% - @sb-switch-handle-size - @sb-switch-handle-horizontal-margin);
    }
  }

  &__label {
    margin-left: 12px;
    vertical-align: middle;
    font-size: @sb-font-size-base;
    line-height: @sb-line-height-base;
    color: @sb-font-color-base;
  }

  .ant-switch-loading-icon {
    top: 4.5px;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;