@import "src/simple-bot/styles/variables";

.sb-layout {
  background: @sb-default-background-color;
  height: 100vh;

  &__header {
    // NOTE: для правильного отбрасывания тени хедера на основной контент
    position: relative;
    z-index: 1;
    background: @sb-default-background-color;
    height: 60px;
    padding-left: 36px;
    padding-right: 36px;
    box-shadow: @sb-box-shadow-default;

    @media screen and (max-width: 475px) {
      height: 70px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &__container {
      color: @sb-font-color-base;
      width: 100%;
      height: 100%;
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }
    }

    &__logo {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      cursor: pointer;

      &__elma-bot {
        height: 30px;
        width: 190px;

        @media screen and (max-width: 767px) {
          height: 25px;
          width: 180px;
        }
        @media screen and (max-width: 576px) {
          display: none;
        }
      }
    }

    &__menu {
      margin-top: 1px;
      padding-right: 118px;

      @media screen and (max-width: 1024px) {
        padding-right: 0;
      }

      .sb-tabs {
        overflow: visible;

        .ant-tabs-tab-btn {
          font-size: @sb-font-size-small;

          @media screen and (max-width: 620px) {
            font-size: @sb-font-size-x-small;
            margin-right: 5px;
          }
        }

        .ant-tabs-nav {
          margin-bottom: 0;
        }

        .ant-tabs-tab {
          margin-right: 24px;

          @media screen and (max-width: 767px) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &-main {
    background: transparent;
  }

  &-scrollable {
    background: transparent;
  }

  &-scrollable-content {
    background: transparent;
    flex-grow: 1;

    & > .sb-scroll {
      flex-grow: 1;
    }

    .sb-scroll > :first-child {
      height: unset !important;
    }

    .sb-scroll.ps--active-y {
      padding-right: 0;
    }
  }

  &-content {
    background: @sb-default-background-color;
    flex-grow: 1;
    margin: 10px 40px;
    padding: 0;
    min-height: 280px;
  }

  &__webchat-container {
    z-index: 2;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;