@import "src/simple-bot/styles/variables";

@sb-help-icon-color: #333333;
@sb-card-progress-bg-color: linear-gradient(270deg, #1CBF4A 0%, #096DD9 100%);
@sb-card-progress-bar-bg-color: #D9D9D9;

.sb-knowledge-base-card {
  font-size: @sb-font-size-base;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: @sb-font-color-base;
  min-height: 0;

  &__header {
    background: @sb-control-color-background;
    box-shadow: @sb-border-bottom-box-shadow;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 11;

    &__left {
      display: inline-flex;
      align-items: center;
      margin: 8px 0;
      overflow: hidden;

      &__title {
        display: flex;
        flex-direction: column;
        max-width: 100%;

        h3 {
          font-size: @sb-font-size-x-large;
          line-height: @sb-line-height-x-large;
          margin: 0;

          .sb-tag {
            margin-left: 8px;
          }
        }

        h4 {
          font-size: @sb-font-size-x-small;
          line-height: @sb-line-height-x-small;
          height: @sb-line-height-small;
          font-weight: normal;
          color: @sb-font-color-secondary-2;
          margin: 0 !important;
          display: flex;
          align-items: center;

          .sb-icon {
            margin: 0 0 0 6px;
          }
        }
      }
    }

    &__right {
      display: inline-flex;
      flex-wrap: nowrap;
      align-items: center;
      margin: 8px 0;

      .sb-button {
        height: 40px;
        margin-right: 12px;
        font-size: @sb-font-size-medium;
      }

      &__secondary-buttons {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  @media screen and (min-width: 769px) {
    &__header {
      flex-wrap: nowrap;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: @sb-content-page-background-color;
    flex-grow: 1;
    padding: 20px;
    min-height: 0;

    &__row {
      min-height: 0;
      height: 100%;

      &__entries {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        &__empty-results {
          text-align: center;
          margin-top: 120px;

          h4 {
            color: @sb-font-color-base;
            font-weight: @sb-font-weight-bold;
            font-size: @sb-font-size-x-large;
            line-height: @sb-line-height-x-large;
          }

          p {
            color: @sb-font-color-secondary;
            font-size: @sb-font-size-medium;
            line-height: @sb-line-height-medium;
          }

          .sb-button {
            margin: auto;
            height: 40px;
            font-size: @sb-font-size-medium;
            line-height: @sb-line-height-medium;
          }
        }

        &__controls-row {
          margin: 0 8px 12px 10px;
          flex-grow: 0;
          flex-shrink: 0;

          h3 {
            font-size: @sb-font-size-medium;
            line-height: @sb-line-height-medium;
            margin-bottom: 0;
            margin-right: 18px;
          }

          .col-1 {
            margin-left: 10px;
            margin-right: 10px;
            padding-top: 5px;
          }

          &__filter {
            padding-left: 70px;

            .sb-input {
              border-width: 1px;

              &.ant-input-affix-wrapper {
                border-right-width: 1px !important;

                &:not(.ant-input-affix-wrapper-disabled):hover {
                  border-right-width: 1px !important;
                }
              }

              .ant-input-suffix::before {
                transition: all 0.3s;
                position: absolute;
                content: '';
                background: @sb-input-color-border;
                width: 1px;
                height: 100%;
                right: 44px;
                pointer-events: none;
              }

              &:hover, &:focus-within {
                .ant-input-suffix::before {
                  background: @sb-input-color-hover;
                }
              }

              .ant-input-suffix {
                cursor: pointer;

                &:hover {
                  color: @sb-input-color-hover;
                }

                .sb-icon.badge::after {
                  position: absolute;
                  content: '';
                  background: @sb-primary-color;
                  border-radius: 50%;
                  width: 10px;
                  height: 10px;
                  transform: translate(-100%, 0);
                }
              }
            }
          }

          &__add-button > .sb-button {
            color: @sb-control-color-background !important;
            background: @sb-primary-color;
            border: none;
            box-shadow: none;
            width: 40px;
            height: 40px;

            &:hover {
              color: @sb-control-color-background;
              background: @sb-primary-color;
              border: none;
              box-shadow: @sb-button-box-shadow;
            }

            &:active {
              color: @sb-control-color-background;
              background: @sb-button-color-active;
              border: none;
              box-shadow: none;
            }
          }

          &__sort > .sb-button {
            color: @sb-control-color-foreground;
            border-color: @sb-button-bg-color-disabled;
            box-shadow: none;
            width: 40px;
            height: 40px;

            &:hover {
              color: @sb-primary-color;
              background: @sb-control-color-background;
              border: 1px solid @sb-primary-color;
              box-shadow: @sb-button-box-shadow;
            }

            &:active {
              color: @sb-button-color-active;
              background: @sb-control-color-background;
              border: 1px solid @sb-button-color-active;
              box-shadow: none;
            }
          }
        }

        &__list-row {
          flex-grow: 1;
          flex-shrink: 1;
          min-height: 0;
          position: relative;

          > .sb-spin {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0.5;
            background: @sb-content-page-background-color;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .scrollbar-container {
            padding-right: 8px;
          }

          &__list {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding-bottom: 20px;

            &__item {
              border-radius: @sb-border-radius-large;
              margin: 12px 10px 12px 20px;
              cursor: default;

              .ant-card-body {
                padding: 20px 22px 18px 22px;
              }

              &__title {
                align-items: center;
                margin-bottom: 12px;
                flex-flow: nowrap;
                column-gap: 8px;

                &__controls {
                  align-self: flex-start;
                }

                &__question-warning {
                  display: flex;
                  align-items: center;
                  color: @sb-warning-color;
                }

                .sb-switch {
                  margin-right: 16px;
                }

                .ant-dropdown-trigger {
                  display: flex;
                  align-items: center;
                }

                &__questions-container {
                  cursor: pointer;
                }
              }

              &__answer {
                margin-top: 10px;
              }

              .ant-divider {
                margin: 12px 0;
              }

              .ant-space-item .sb-tag {
                vertical-align: text-bottom;
              }

              &__source {
                display: inline-flex;
                align-items: flex-start;
                padding-top: 1px;

                .sb-button {
                  display: inline;
                  padding: 0 0 0 6px;
                  height: 24px;
                }
              }
            }

            &__pagination {
              margin-top: 20px;
            }
          }
        }
      }

      &__testing-panel-container {
        margin-left: 10px;
        width: 32%;
        min-width: 380px;

        .sb-collapse {
          height: 100%;
        }

        .sb-testing-panel {
          &__semantic {
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;

            &__updating {
              z-index: 1;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: @sb-control-color-background;
              opacity: 0.8;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &__title {
              padding: 0 24px;

              .sb-search {
                margin-bottom: 16px;

                &:only-child {
                  margin-bottom: 0;
                }
              }

              .scrollbar-container {
                height: unset;
                margin-bottom: 8px;

                .sb-panel ul {
                  margin: 0;
                }
              }

              .sb-button {
                width: 100%;
                margin-bottom: 24px;
                height: 40px;
                font-size: @sb-font-size-base;
                font-weight: @sb-font-weight-bold;
                color: @sb-font-color-secondary;
                justify-content: center;
                border: @sb-border-default-dashed;
              }
            }

            &__content {
              flex: 1 1 auto;
              overflow: hidden;

              .scrollbar-container {
                margin: 0 6px 0 0;
                padding: 0 18px 0 24px;
              }

              &__help {
                display: flex;
                flex-direction: column;
                height: 100%;
                align-items: center;
                justify-content: center;

                .sb-icon {
                  color: @sb-help-icon-color;
                  opacity: 0.2;
                }

                div {
                  margin-top: 16px;
                  text-align: center;
                  color: @sb-font-color-secondary;
                }
              }

              &__card {
                border: @sb-border-default;
                border-radius: @sb-border-radius-xxx-small;
                padding: 10px 16px;
                margin-bottom: 8px;
                cursor: pointer;

                &:hover {
                  box-shadow: @sb-box-shadow-default;
                }

                &__title {
                  display: flex;
                  font-size: @sb-font-size-small;
                  line-height: @sb-line-height-x-large;

                  &__question {
                    font-weight: @sb-font-weight-bold;
                    color: @sb-font-color-secondary;
                    flex: 1 1 auto;
                  }

                  &__score {
                    font-weight: @sb-font-weight-normal;
                  }
                }

                &__progress {
                  margin-top: 8px;
                  display: flex;
                  justify-content: flex-end;
                  height: 2px;
                  background: @sb-card-progress-bg-color;

                  &__bar {
                    background: @sb-card-progress-bar-bg-color;
                    height: 100%;
                  }
                }

                .sb-markdown-editor {
                  margin-top: 8px;
                  font-size: @sb-font-size-small;
                  line-height: @sb-line-height-small;
                  height: @sb-line-height-small;
                  overflow: hidden;
                  width: 100%;
                }

                .sb-button {
                  margin-top: 8px;
                  height: @sb-line-height-small;
                  font-weight: @sb-font-weight-bold;
                }

                &__description {
                  margin-top: 8px;
                  font-size: @sb-font-size-small;
                  line-height: @sb-line-height-small;
                  font-weight: @sb-font-weight-bold;
                  color: @sb-font-color-secondary-2;
                  display: flex;
                  align-items: center;

                  .sb-icon {
                    margin-right: 6px;
                  }
                }
              }
            }
          }

          &__web-chat-panel-header {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .sb-icon {
              color: @sb-primary-color;
              cursor: pointer;
              margin-left: 12px;
            }
          }

          &__web-chat {
            height: 100%;
            width: 100%;
            padding-bottom: 16px;

            &__help {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              margin-top: 24px;
              width: 90%;
              max-height: 90%;

              .sb-icon {
                color: @sb-help-icon-color;
                opacity: 0.2;
              }

              div {
                margin-top: 16px;
                text-align: center;
                color: @sb-font-color-secondary;
              }
            }
          }
        }
      }
    }
  }

  &__import-progress-modal, &__diagnostic-modal {
    &__import-result, &__results {
      .sb-icon {
        color: @sb-error-color;
        margin: 0 4px;

        svg {
          margin-bottom: -5px;
        }
      }

      &__warning {
        margin-top: 20px;
      }
    }
  }

  &__import-modal {
    .sb-panel-help {
      padding: 10px 24px;
    }

    .ant-modal-body {
      margin: 10px 0 !important;
    }

    .ant-modal-content {
      padding: 20px !important;

      .ant-modal-title {
        font-size: @sb-font-size-x-large !important;
        line-height: @sb-line-height-x-large !important;
      }
    }

    &__examples {
      h4 {
        color: @sb-font-color-secondary;
        margin: 0;
      }

      ul {
        margin-bottom: 24px;
      }

      .sb-button {
        width: min-content;
        height: 22px;
      }
    }

    &__formats {
      p {
        display: flex;
        align-items: center;
        margin: 0 0 6px 0;

        svg {
          margin-right: 4px;
        }
      }
    }

    &__source-selector {
      margin-top: 20px;

      .ant-tabs-nav {
        margin-bottom: 16px;
      }

      .ant-tabs-tab-btn {
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
        height: @sb-line-height-base;
        padding: 0 0 @sb-line-height-base 0;
      }

      .ant-tabs-content-holder {
        height: auto;
      }

      small {
        display: block;
        margin-top: 8px;
      }

      .sb-upload {
        margin-top: 8px;
      }

      .sb-input {
        margin-top: 4px;
      }
    }

    p&__validation-error {
      color: @sb-error-color;
      margin: 0;
    }
  }

  &__data-entry-edit-modal {
    &__child-tags {
      margin-top: 12px;
      display: inline-flex;
      flex-wrap: wrap;

      .sb-tag {
        margin: 0 8px 4px 0;
        height: 28px;
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
        color: @sb-font-color-base;
      }
    }

    &__child-select {
      .ant-select-selection-item {
        color: @sb-font-color-secondary-2;
      }
    }

    &__child-select-dropdown {
      .ant-select-item {
        padding: 12px 12px 20px 12px;

        &:not(:last-child) {
          border-bottom: @sb-border-default;
        }
      }

      &__question {
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
        font-weight: @sb-font-weight-bold;
        margin-bottom: 6px;
      }
    }

    .sb-markdown-editor {
      margin: 8px 0;
    }
  }
}

.sb-question-warning-tooltip {
  max-width: unset;

  .ant-tooltip-inner {
    padding: 0;

    > div {
      padding: 12px 20px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-size: @sb-font-size-x-small;
      line-height: @sb-line-height-x-small;

      .sb-button {
        font-size: @sb-font-size-x-small;
        line-height: @sb-line-height-x-small;
        font-weight: @sb-font-weight-normal;
        padding: 0 2px;
      }
    }
  }
}

.sb-data-entry-list-filter-menu {
  width: 400px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    background: @sb-control-color-background;
    box-shadow: @sb-box-shadow-default;
    padding: 20px;

    h2 {
      font-size: @sb-font-size-x-large;
      line-height: @sb-line-height-x-large;
      font-weight: @sb-font-weight-bold;
      color: @sb-font-color-base;
      margin-bottom: 10px;
    }

    h3 {
      font-size: @sb-font-size-base;
      line-height: @sb-line-height-base;
      font-weight: @sb-font-weight-bold;
      color: @sb-font-color-base;
      margin-bottom: 12px;
    }

    .sb-select {
      margin-bottom: 24px;
    }
  }

  &__buttons {
    display: inline-flex;

    .sb-button:first-child {
      margin-right: 12px;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;