@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-tags-popover {
  width: 272px;

  @media (max-width: @ib-breakpoint-small) {
    overflow: hidden;
    border-radius: @ib-border-radius-x-small;
    position: fixed;
    top: unset !important;
    right: @ib-padding-base !important;
    bottom: @ib-padding-base !important;
    left: @ib-padding-base !important;
    width: unset;
  }

  &::after {
    @media (max-width: @ib-breakpoint-small) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: @ib-modal-mask-color;
      z-index: -1;
      pointer-events: none;
    }
  }

  .ant-popover-inner {
    .ant-popover-inner-content {
      padding: @ib-padding-base 0;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;