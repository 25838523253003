@import "src/simple-bot/styles/variables";

@sb-checkmark-base-color: #D9D9D9;

.sb-select-scenario-card {
  height: 160px;
  width: 100%;
  border-radius: @sb-border-radius-medium;
  background: @sb-control-color-background;
  border: @sb-border-default;

  &_selected {
    background-color: @sb-scenario-list-card-active;
    color: @sb-control-color-background;

    .sb-checkmark {
      border-color: @sb-control-color-background !important;
    }
  }

  .ant-card-body {
    height: 100%;
    padding: 0;
  }

  &:hover, &:focus {
    box-shadow: @sb-box-shadow-default;
  }

  &:active {
    border: 2px solid @sb-scenario-list-card-active;
  }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 18px 19px 2px 20px;
    overflow: hidden;

    &__first-row {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &__title {
        font-weight: @sb-font-weight-bold;
        font-size: @sb-font-size-x-large;
        line-height: @sb-line-height-x-large;
        overflow-y: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      .sb-checkmark {
        width: 25px;
        height: 25px;
        border: 2px solid @sb-checkmark-base-color;
        border-radius: 15px;
        flex-shrink: 0;
        flex-grow: 0;

        .sb-icon {
          color: @sb-control-color-background;
        }
      }
    }

    &__description {
      margin-top: 5px;
      font-size: @sb-font-size-small;
      line-height: @sb-line-height-small;
      overflow-y: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;