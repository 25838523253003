@import "src/simple-bot/styles/variables";

.sb-bot-template-card {
  height: 160px;
  width: 100%;
  border-radius: @sb-border-radius-medium;
  background: @sb-control-color-background;
  border: @sb-border-default;

  &_selected {
    background-color: @sb-scenario-list-card-active;
    color: white;
  }

  .ant-card-body {
    height: 100%;
    padding: 0;
  }

  &:hover, &:focus {
    box-shadow: @sb-box-shadow-default;
  }

  &:active {
    border: 2px solid @sb-scenario-list-card-active;
  }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 18px 5px 5px 20px;
    overflow: hidden;

    &__title {
      font-weight: @sb-font-weight-bold;
      font-size: @sb-font-size-x-large;
      line-height: @sb-line-height-x-large;
      margin-top: 7px;
    }

    &__description {
      font-size: @sb-font-size-small;
      line-height: @sb-font-size-base;
      margin-top: 5px;
    }

    &__icon {

    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;