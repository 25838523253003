@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-bot-cards {
  display: grid;
  grid-template-columns: repeat(4, calc(25% - @ib-padding-base * 3 / 4));
  grid-gap: @ib-padding-base;
  justify-items: stretch;
  width: 100%;

  &__wrapper-one-column {
    grid-column-start: span 1;

    @media (max-width: @ib-breakpoint-small) {
      grid-column-start: span 4;
    }
  }

  &__wrapper-two-columns {
    grid-column-start: span 2;

    @media (max-width: @ib-breakpoint-small) {
      grid-column-start: span 4;
    }
  }

  &__wrapper-free-height {
    .ib-card {
      height: auto;
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;