@import "src/simple-bot/styles/variables";

.sb-create-bot-wizard__content {

  &__scenarios-selection-step {
    height: 100%;
    display: flex;
    flex-direction: column;

    .sb-typography {
      margin-bottom: 6px;

      h2 {
        font-size: @sb-font-size-x-large;
        line-height: @sb-line-height-x-large;
        margin-bottom: 10px;
        @media (max-height: 720px) {
          margin-bottom: 4px;
        }
      }
    }

    &__scenarios-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin-top: 29px !important;
      @media (max-height: 720px) {
        margin-top: 20px !important;
      }

      .sb-button {
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
        margin-right: 2px;
        margin-top: 2px;
      }
    }

    &__scenario-count {
      font-weight: 400;
      color: #8C8C8C;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;