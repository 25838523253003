@import "src/simple-bot/styles/variables";
@import "src/simple-bot/styles/mixins";
@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-channels-card {
  &__content {
    &__empty-list-message {
      margin-bottom: @ib-padding-base;
    }

    .sb-add-channel-button {
      margin-bottom: @ib-padding-xx-small;
    }

    &__list {
      display: table;
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px @ib-padding-base;
      margin-top: -@ib-padding-base;

      &__channel-container {
        display: table-row;

        &__icon {
          display: table-cell;
          vertical-align: middle;
          padding-right: @ib-padding-base;

          .ib-social {
            vertical-align: middle;
          }
        }

        &__title {
          display: table-cell;
          width: 100%;
          vertical-align: middle;
        }

        &__status {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;