@import "src/inbox/styles/variables";

.ib-webchat {
  background: @ib-white-color;
  border-radius: @ib-border-radius-small;
  box-shadow: @ib-box-shadow-default;
  padding-bottom: @ib-padding-base;
  width: 370px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: @ib-breakpoint-small) {
    width: 100vw !important;
    height: 100vh !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  &_fullscreen {
    height: 100%;
    width: 100%;

    .ib-webchat__header__close-button,
    .ib-webchat__header__size-button {
      display: none;
    }
  }

  &_expanded {
    width: 50vw;
    height: 90vh !important;

    @media (max-width: @ib-breakpoint-small) {
      width: 100vw !important;
      height: 100vh !important;
    }
  }

  &__header {
    box-shadow: @ib-border-bottom-box-shadow;
    padding: @ib-padding-xx-small @ib-padding-xxxx-small @ib-padding-xx-small @ib-padding-base;
    gap: @ib-padding-xx-small;

    &__title {
      min-width: 0;

      h1 {
        font-weight: @ib-font-weight-bold;
        font-size: @ib-font-size-x-large;
        line-height: @ib-line-height-x-large;
        color: @ib-font-color-base;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-weight: @ib-font-weight-bold;
        font-size: @ib-font-size-x-small;
        line-height: @ib-line-height-x-small;
        color: @ib-font-color-tertiary;
      }

      h1,
      p {
        margin-bottom: 0;
      }
    }

    &__size-button {
      @media (max-width: @ib-breakpoint-small) {
        display: none;
      }
    }
  }

  &__content {
    height: 100%;
    flex-grow: 1;
    background: @ib-white-color;
    overflow: hidden;

    .webchat__basic-transcript__transcript {
      padding-left: @ib-padding-xxx-small;
      padding-right: @ib-padding-xxx-small;

      .webchat__stacked-layout__avatar-gutter > div {
        border: 1px solid @ib-webchat-avatar-gutter-border-color !important;
        box-sizing: content-box;
      }

      .webchat__bubble__content > div {
        padding: 0 !important;
      }

      .webchat__bubble__content {
        padding: @ib-padding-xx-small @ib-padding-small;

        p {
          padding: 0;
          margin: 0;
        }
      }

      .webchat__stacked-layout--from-user {
        .webchat__fileContent__fileName {
          color: @ib-webchat-outbound-attachment-primary-font-color;
        }

        .webchat__fileContent__size {
          color: @ib-webchat-outbound-attachment-secondary-font-color;
          font-size: @ib-font-size-x-small;
        }

        .webchat__fileContent__downloadIcon {
          fill: @ib-webchat-outbound-attachment-primary-font-color;
          transform: scale(1.5);
        }
      }

      .webchat__basic-transcript__activity--from-bot {
        .webchat__fileContent__downloadIcon {
          transform: scale(1.5);
        }
      }
    }

    .webchat__suggested-action__button {
      border-radius: @ib-border-radius-x-large !important;

      &:hover {
        box-shadow: @ib-box-shadow-default !important;
      }
    }

    .webchat__send-box__main {
      border-radius: @ib-border-radius-small;
      margin: 0 @ib-padding-base;
      min-height: @ib-element-size-48 !important;

      &:hover,
      &:focus-within {
        border-color: @blue-blue-600 !important;
      }

      input::placeholder {
        color: @ib-font-color-tertiary !important;
      }
    }

    .webchat__send-box__button {
      background: @blue-blue-600 !important;
      border-radius: @ib-border-radius-small !important;
      margin: @ib-padding-xxxx-small;

      &:hover {
        box-shadow: @ib-button-box-shadow !important;
      }
    }

    .webchat__imageAvatar__image > img {
      width: 50%;
    }

    &__spinner {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .webchat__suggested-actions {
      .react-film__filmstrip {
        padding-left: @ib-padding-x-small;

        &__list {
          flex-wrap: wrap;
          max-height: 200px;
          overflow-x: hidden;
          overflow-y: auto;

          &::-webkit-scrollbar {
            background-color: transparent;
            width: @ib-element-size-12;
          }

          &::-webkit-scrollbar-thumb {
            background-color: @ib-scroll-color;
            border-radius: @ib-border-radius-x-large;
            border: 2px solid transparent;
            background-clip: content-box;
          }
        }

        &__item {
          padding-left: 0 !important;
        }
      }
    }

    .webchat__basic-transcript__scrollable::-webkit-scrollbar {
      background-color: transparent;
      width: @ib-element-size-12;
    }

    .webchat__basic-transcript__scrollable::-webkit-scrollbar-thumb {
      background-color: @ib-scroll-color;
      border-radius: @ib-border-radius-x-large;
      border: 2px solid transparent;
      background-clip: content-box;
    }

    .ib-quoted-message {
      display: flex;
      padding: @ib-padding-xxxx-small @ib-padding-x-small;

      &__placeholder {
        width: @ib-element-size-48;
      }

      &__container {
        padding-left: @ib-padding-x-small;

        &__border {
          border-left: 2px solid @ib-primary-color;
          padding-left: @ib-padding-xxxx-small;
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;