@import "src/simple-bot/styles/variables";

.main-case-action-condition-editor {
  margin-right: 0 !important;

  &__radio-buttons {
    height: 50px;
    max-height: 50px;
    overflow: hidden;
    background: @sb-control-color-background;
    border: 2px solid @sb-input-color-border;
    border-radius: @sb-border-radius-small;
    box-sizing: border-box;
    box-shadow: none;
    display: flex;
    align-items: center;
    transition: all 0.3s;

    .ant-radio-button-wrapper-checked {
      background: @sb-input-color-hover !important;
      fill: white;
    }

    .sb-input, .sb-date-picker, .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
    }

    :not(:last-child) {
      align-items: center;
      height: 100%;
      display: flex;
      border: none;
      box-shadow: none;
      box-sizing: border-box;

      &:before {
        content: none;
      }
    }

    &__variable-icon {
      display: flex;
      align-items: center;

      * {
        width: 20px;
        height: 20px;
      }
    }

    &__divider {
      border-left: 1px solid @sb-input-color-border !important;
    }

    &:hover, &:focus-within {
      border-color: @sb-input-color-hover !important;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;