@import "src/simple-bot/styles/variables";

@sb-pagination-button-size: 48px;

.sb-pagination {
  .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next, .ant-pagination-item-link {
    height: @sb-pagination-button-size;
    min-width: @sb-pagination-button-size;
    line-height: @sb-pagination-button-size;
    font-size: @sb-font-size-x-large;
    border: none;
    border-radius: @sb-border-radius-small;
    font-weight: @sb-font-weight-normal;
    color: @sb-font-color-secondary;

    a {
      color: @sb-font-color-secondary;
    }

    &:hover {
      color: @sb-primary-color;
      background: @sb-control-color-background;
      border: none;
      box-shadow: @sb-button-box-shadow;
    }

    &:active {
      color: @sb-button-color-active;
      background: @sb-control-color-background;
      border: none;
      box-shadow: none;
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus {
      color: @sb-button-color-disabled;
      background: @sb-control-color-background;
      border: none;
      box-shadow: none;
    }
  }

  .ant-pagination-item-active a {
    color: @sb-primary-color;
    font-weight: @sb-font-weight-bold;
  }

  .ant-pagination-jump-prev:focus-visible,
  .ant-pagination-jump-next:focus-visible,
  .ant-pagination-jump-prev:hover,
  .ant-pagination-jump-next:hover {
    .ant-pagination-item-link-icon {
      opacity: 0;
    }

    .ant-pagination-item-ellipsis {
      opacity: 1;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;