@import "src/inbox/styles/variables";

.ib-channels-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    overflow-y: overlay;
    margin-top: @ib-padding-small;

    &-empty {
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      &__placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: @ib-padding-large;

        @media (max-width: @ib-breakpoint-small) {
          margin-bottom: @ib-padding-base;
        }

        svg {
          margin-bottom: 6px;
        }

        .ib-typography {
          text-align: center;
          margin-bottom: 24px;
        }
      }
    }

    .infinite-scroll-component {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
          
    &__spin {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      margin: 24px 0 16px 0;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;