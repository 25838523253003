@import "src/simple-bot/styles/variables";

@sb-scenario-template-card-active: #1890FF;

.sb-scenario-template-card {
  height: 215px;
  width: auto;
  border-radius: @sb-border-radius-large;
  background: #FFFFFF;
  border: none;

  .ant-card-body {
    height: 100%;
    padding: 20px;
  }

  &:hover, &:focus {
    box-shadow: @sb-box-shadow-default;
  }

  &:active {
    border: 2px solid @sb-scenario-template-card-active;
  }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__top-row {
    display: flex;
    justify-content: space-between;
    overflow-y: hidden;
    flex-shrink: 0;

    h3 {
      font-weight: @sb-font-weight-bold;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 4px;
      overflow-y: hidden;
	    text-overflow: ellipsis;
	    -webkit-line-clamp: 2;
	    display: -webkit-box;
	    -webkit-box-orient: vertical;
    }

    .sb-icon {
      margin: 3px;
      flex-shrink: 0;
    }
  }

  &__description-row {
    flex-shrink: 1;
    flex-grow: 1;
    overflow-y: hidden;
  }

  &__tag-row {
    align-self: stretch;
    margin: -7px 0 0 -7px;
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin: 7px 0 0 7px;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;