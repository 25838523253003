@import "src/simple-bot/styles/variables";

.sb-scenario-list-card {
  height: 180px;
  width: 100%;
  border-radius: @sb-border-radius-large;
  background: @sb-control-color-background;
  border: @sb-border-default;

  &_unclickable {
    pointer-events: none;
    box-shadow: none;
  }

  &_loading {
    opacity: 0.5;
  }

  &_unclickable:active, &_unclickable:hover, &_unclickable:focus {
    pointer-events: none;
    box-shadow: none !important;
    border: @sb-border-default !important;
  }

  .ant-card-body {
    height: 100%;
    padding: 10px;

    > .sb-icon.i-icon-loading-four {
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 12px);
      color: @sb-primary-color;
    }
  }

  &:hover, &:focus {
    box-shadow: @sb-box-shadow-default;

    .sb-scenario-list-card__top-row > h3 {
      text-decoration: underline;
    }
  }

  &:active {
    border: 2px solid @sb-scenario-list-card-active;
  }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 2px 10px 16px;
  }

  &__top-row {
    display: flex;
    flex-grow: 0;
    justify-content: space-between;

    &__title {
      flex-grow: 1;
      display: inline;

      .ant-input {
        pointer-events: all;
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        color: inherit;
        padding: 0;
        border: none;
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
      }

      .text-part {
        margin-right: 12px;
        word-break: break-word;
        vertical-align: top;
      }

      &__tag-container {
        display: inline-block;
        vertical-align: top;
      }
    }

    h3 {
      font-size: @sb-font-size-x-large;
      font-weight: @sb-font-weight-bold;
      line-height: @sb-line-height-x-large;
      max-height: 96px;
      overflow: hidden;
      margin-bottom: 0;
      min-height: 40px;

      .sb-tooltip {
        margin-bottom: 5px;
      }
    }

    &__icon {
      margin-top: 4px;
    }
  }

  &__second-row {
    flex-grow: 1;
    font-size: @sb-font-size-x-small;
    line-height: @sb-line-height-x-small;
  }

  &__bottom-row {
    flex-grow: 0;
    align-self: start;
  }

  &__import-upload.sb-upload {
    height: unset !important;

    &.ant-upload.ant-upload-drag {
      border: none;
      background: transparent;

      .ant-upload-drag-container {
        display: flex;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;