@import "src/inbox/styles/variables";

.ib-list {
  font-size: @ib-font-size-small;
  line-height: @ib-line-height-base;
  font-weight: @ib-font-weight-normal;

  .ant-list-header {
    padding: @ib-padding-xx-small 0;
  }

  .ant-list-items > li,
  .ant-list-empty-text,
  .ant-list-footer {
    border-width: @ib-border-thickness-1 0 0 0;
    border-color: @gray-gray-100;
    border-style: solid;
    padding: @ib-padding-xx-small 0;
  }

  .ant-list-empty-text {
    color: @gray-gray-300;
    text-align: left;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;