@import "src/simple-bot/styles/variables";

.sb-bot-list-card {
  height: 250px;
  border-radius: 20px;

  @media screen and (max-width: 1280px) {
    height: 200px;
  }

  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 35px;

    &::before,
    &::after {
      display: none;
    }

    > .sb-icon.i-icon-loading-four {
      color: @sb-primary-color;
      margin: auto;
    }
  }

  &_unclickable {
    pointer-events: none;
    box-shadow: none;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    &__title {
      margin-bottom: 0;

      .ant-input {
        pointer-events: all;
        font-family: @sb-font-family;
        font-size: @sb-font-size-x-large;
        line-height: @sb-line-height-x-large;
        font-weight: @sb-font-weight-bold;
        color: @sb-font-color-base;
        padding: 0;
        border: none;
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
      }
    }

    &__title-text {
      font-family: @sb-font-family;
      font-size: @sb-font-size-x-large;
      line-height: @sb-line-height-x-large;
      color: @sb-font-color-base;
      font-weight: @sb-font-weight-bold;
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }

  .sb-tabs {
    .ant-tabs-nav {
      margin: 0 0 12px 0;
    }

    .ant-tabs-tab-btn {
      font-size: @sb-font-size-small;
      line-height: @sb-line-height-small;
      margin: 0 12px 0 0;
    }
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: @sb-font-size-small;
    line-height: @sb-line-height-small;
  }

  &__scenario-count {
    color: @sb-font-color-secondary;

    @media screen and (max-width: 1366px) {
      white-space: nowrap;
    }
  }

  &__divider {
    background-color: @sb-divider-color-secondary;
    margin: 0 12px;
    height: 20px;

    @media screen and (max-width: 1366px) {
      margin: 0 6px;
    }
  }

  &__scenario-date {
    color: @sb-primary-color;

    @media screen and (max-width: 1366px) {
      white-space: nowrap;
    }
  }

  &__state {
    display: flex;
    align-items: center;

    &_on,
    &_off,
    &_disabled {
      &::before {
        content: '';
        position: relative;
        top: -1px;
        width: 12px;
        height: 12px;
        background-color: @sb-editor-elements-selected-color;
        border-radius: 50%;
        margin-right: 6px;
      }
    }

    &_off {
      &::before {
        background: @sb-add-button-border-hover-color;
      }
    }

    &_disabled {
      opacity: 0.5;

      &::before {
        background: @sb-editor-elements-selected-color;
      }
    }
  }

  &__import-upload.sb-upload {
    height: unset !important;

    &.ant-upload.ant-upload-drag {
      border: none;
      background: transparent;

      .ant-upload-drag-container {
        display: flex;
      }
    }
  }

  &__channels {
    display: flex;

    .sb-channel-icon {
      margin-right: 12px;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;