@import "src/inbox/styles/variables";

@ib-hidable-column-width: 320px;

.ib-layout-base {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__alert {
    height: 40px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: @orange-orange-100;

    .ib-icon {
      color: @ib-error-color !important;
    }

    &__extra {
      @media (max-width: @ib-breakpoint-small) {
        display: none;
      }
    }

    a {
      color: @blue-blue-700;
    }
  }

  &__content {
    display: flex;
    flex-grow: 1;
    overflow: hidden;

    @media (max-width: @ib-breakpoint-small) {
      flex-direction: column-reverse;
    }

    &__main-section {
      background: transparent;
      flex-grow: 1;
      overflow: auto;

      .ant-layout-content {
        display: flex;
      }
    }
  }

  &__webchat-container {
    z-index: 2;
    position: absolute;
    right: @ib-padding-medium;
    bottom: @ib-padding-medium;

    @media (max-width: @ib-breakpoint-small) {
      width: 100vw !important;
      height: 100vh !important;
      right: 0 !important;
      bottom: 0 !important;
    }
  }

  .ant-spin-dot-item {
    background-color: @ib-primary-color;
  }
}

.ib-column-fixed {
  flex: 0 0 360px;
  max-width: 360px;
  border-right: 1px solid @gray-gray-100;

  @media (max-width: @ib-breakpoint-small) {
    display: none;
  }
}

.ib-column-stretch {
  height: 100%;
  min-height: 0;
  flex: 1 1 auto;
  min-width: 0;

  @media (max-width: @ib-breakpoint-small) {
    display: none;
  }
}

.ib-column-hidable {
  flex: 0 0 @ib-hidable-column-width;
  border-left: 1px solid @gray-gray-100;
  width: @ib-hidable-column-width;

  @media (max-width: @ib-breakpoint-medium) {
    position: fixed;
    height: 100%;
    right: -@ib-hidable-column-width;
    top: 0;
    transition: right 300ms;
  }

  @media (max-width: @ib-breakpoint-small) {
    display: none;
    border-left: none;
  }

  &_expanded {
    @media (max-width: @ib-breakpoint-medium) {
      right: 0;
    }

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
      width: 100%;
    }
  }
}

.ib-column-mobile {
  display: none;

  @media (max-width: @ib-breakpoint-small) {
    display: unset;
    height: 100%;
    min-height: 0;
    flex: 1 1 auto;
    min-width: 0;
  }
}

::-webkit-scrollbar {
  width: @ib-scroll-size;
  height: @ib-scroll-size;
}

::-webkit-scrollbar-thumb {
  border-radius: @ib-scroll-size;
  background-color: @ib-scroll-color;
}

::-webkit-scrollbar-button {
  display: none;
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;