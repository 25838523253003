@import "src/simple-bot/styles/variables";

.sb-table {
  height: 100%;
  display: flex;
  flex-direction: column;

  > .sb-scroll.ps--active-y + .ant-table-wrapper {
    margin-right: 28px;
  }

  .ant-table-selection-column {
    display: none;
  }

  thead > tr:last-child {

    th, td {
      border-bottom: none;
    }
  }

  tbody > tr {
    cursor: pointer;

    &:first-child {

      > th, > td {
        border-top: 1px solid @sb-cell-color-border;
      }
    }
  }

  th, td {
    font-family: @sb-font-family;
    font-style: normal;
    font-size: @sb-font-size-small;
    line-height: @sb-line-height-small;
    color: @sb-font-color-base;
    padding: 13px 12px !important;
    background-color: @sb-control-color-background !important;
    border-color: @sb-cell-color-border !important;

    &:empty::after {
      content: "\00a0";
    }
  }

  th {
    font-weight: @sb-font-weight-bold !important;
  }

  td {
    font-weight: normal !important;
  }

  tr:hover > td {
    color: @sb-cell-color-hover;
  }

  tr.ant-table-row-selected > td {
    color: @sb-cell-color-hover;
    background-color: @sb-cell-color-selected !important;
  }

  &__empty {
    text-align: center;
  }

  .ant-table-layout-fixed {

    th, td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    col:not([width]) {
      display: none;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;