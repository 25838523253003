@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-quoted-message {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  gap: @ib-padding-xx-small;
  border-left: solid 2px @blue-blue-700;
  padding-left: @ib-padding-xx-small;

  &_appear {
    opacity: 0;
    max-height: 0;
    overflow: hidden;

    &_done {
      opacity: 1;
      transition: all 0.4s;
      max-height: unset;
    }
  }

  &_clickable {
    cursor: pointer;
  }

  &_internal {
    border-left-color: @orange-orange-500;
  }

  &_with-attachments {
    .DraftEditor-root {
      color: @gray-gray-300;
      overflow-y: unset;
    }
  }

  & > div {
    transition: max-height 1s;
  }

  &__body {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: @ib-padding-xxxx-small;

    &__sender {
      .text-ellipsis;
    }

    &__attachments {
      display: flex;
      flex-direction: column;
      gap: @ib-padding-xxxx-small;

      .ib-icon {
        margin-right: @ib-padding-xxxx-small;
        vertical-align: text-bottom;
      }
    }

    &__text {
      display: flex;
      align-items: center;

      .ib-icon {
        display: inline-block;
        margin-right: @ib-padding-xxxx-small;
      }

      .ib-message {
        .text-ellipsis(2);
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;