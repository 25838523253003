@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-contact-edit-modal {
  &__title {
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: @ib-padding-xx-small;
  }

  &__form-item {
    &:not(:last-child) {
      margin-bottom: @ib-padding-base;
    }

    .ib-input, .ib-tags-widget, .ib-typography__paragraph_error {
      margin-top: @ib-padding-xxxx-small;
    }
  }

  &__delete-button {
    margin-left: auto !important;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  .ib-button_secondary {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  .ib-button_fill {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
    }
  }

  &__person-list {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ib-button, .ib-input {
      width: 100%;
      flex-shrink: 0;
    }

    .ib-input {
      margin-bottom: @ib-padding-base;
    }

    ul {
      list-style: none;
      padding: 0;
      max-height: 200px;
      overflow-y: auto;

      @media (max-width: @ib-breakpoint-small) {
        max-height: unset;
        flex-grow: 1;
      }

      li > .ib-typography {
        display: flex;
        align-items: center;
        gap: @ib-padding-xx-small;
        padding: @ib-padding-xxx-small 0;
        cursor: pointer;

        @media (max-width: @ib-breakpoint-small) {
          padding: @ib-padding-xx-small 0;
          gap: @ib-padding-base;
        }

        &:hover {
          > .ib-typography__paragraph {
            color: @blue-blue-700;
          }
        }

        .ib-avatar {
          @media (max-width: @ib-breakpoint-small) {
            .block-size(@ib-element-size-48);
          }
        }

        > .ib-typography__paragraph {
          .text-ellipsis();
        }

        .ib-icon {
          color: @ib-success-color !important;
          margin-left: auto;
        }
      }
    }

    &__spin-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: @ib-padding-base;
      margin: @ib-padding-xxxx-small 0;
    }

    &__empty-list-entry {
      display: flex;
      align-items: center;
      gap: @ib-padding-xx-small;
      margin-bottom: @ib-padding-small;
    }
  }

  &__person-list-popover {
    .ant-popover-inner-content {
      width: 272px;

      @media (max-width: @ib-breakpoint-small) {
        width: unset;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;