@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-confirm-modal {
  overflow: hidden;
  background: @ib-modal-mask-color;

  @media (max-width: @ib-breakpoint-small) {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .ant-modal {
    transform-origin: unset !important;
    animation-duration: 0.1s !important;
    top: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: @ib-breakpoint-small) {
      margin: @ib-padding-base;
      padding-bottom: 0;
      width: 100% !important;
      max-width: 100% !important;
      height: auto;
      max-height: unset;
    }

    .ant-modal-content {
      display: flex;
      flex-direction: column;
      background-color: @ib-white-color;
      border-radius: @ib-border-radius-small;
      box-shadow: @ib-modal-box-shadow;
      max-height: calc(100vh - 2 * @ib-padding-base);

      @media (max-width: @ib-breakpoint-small) {
        .block-size(100%);
        border-radius: @ib-border-radius-x-small;
      }

      .ant-modal-close {
        .block-size(68px);

        @media (max-width: @ib-breakpoint-small) {
          display: none;
        }

        .ant-modal-close-x {
          .block-size(100%);
          display: flex;
          align-items: center;
          justify-content: center;

          .i-icon-close {
            display: flex;

            @media (max-width: @ib-breakpoint-small) {
              display: none;
            }
          }

          .i-icon-close-small {
            display: none;

            @media (max-width: @ib-breakpoint-small) {
              display: flex;
            }
          }
        }
      }

      .ant-modal-header {
        border-bottom: none;
        padding: @ib-padding-large 56px @ib-padding-medium @ib-padding-large;
        border-radius: @ib-border-radius-small @ib-border-radius-small 0 0;

        @media (max-width: @ib-breakpoint-small) {
          padding: @ib-padding-base @ib-padding-base @ib-padding-small @ib-padding-base;
          border-radius: @ib-border-radius-x-small @ib-border-radius-x-small 0 0;
        }

        h4 {
          .text-ellipsis();
        }

        .ib-spin {
          z-index: 100;
          display: flex;
          align-items: center;
          justify-content: center;
          background: @white-white-50;
          opacity: 0.5;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: @ib-border-radius-small;

          @media (max-width: @ib-breakpoint-small) {
            border-radius: 0;
          }
        }
      }

      .ant-modal-body {
        flex-grow: 1;
        overflow-y: overlay;
        font-size: @ib-font-size-small;
        line-height: @ib-line-height-base;
        font-weight: @ib-font-weight-normal;
        color: @ib-font-color-base;
        padding: 0 @ib-padding-large;

        @media (max-width: @ib-breakpoint-small) {
          padding: 0 @ib-padding-base;
        }
      }

      .ant-modal-footer {
        border-top: none;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        padding: @ib-padding-medium @ib-padding-large @ib-padding-large @ib-padding-large;

        @media (max-width: @ib-breakpoint-small) {
          padding: @ib-padding-base;
        }

        .ib-button {
          &:not(:first-child) {
            margin-left: 6px;
          }

          @media (max-width: @ib-breakpoint-small) {
            width: 100%;

            &:not(:first-child) {
              margin-left: 0;
              margin-top: 6px;
            }
          }
        }

        .ib-button_secondary {
          @media (max-width: @ib-breakpoint-small) {
            display: none;
          }
        }

        .ib-button_fill {
          display: none;

          @media (max-width: @ib-breakpoint-small) {
            display: flex;
          }
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;