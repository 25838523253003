@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-channel-edit-modal {
  .ib-tabs {
    height: auto;

    .ant-tabs-content-holder {
      height: auto;
    }
  }

  .ant-modal {
    width: 470px !important;

    @media (max-width: @ib-breakpoint-small) {
      width: 100% !important;
    }
  }

  &_channel-selection {
    .ant-modal {
      width: 420px !important;

      @media (max-width: @ib-breakpoint-small) {
        width: 100% !important;
      }
    }

    &__list {
      width: 100%;
      margin-top: @ib-padding-large;
      display: inline-flex;
      flex-wrap: wrap;
      gap: @ib-padding-base;

      &__item {
        cursor: pointer;
        width: calc(50% - 8px);
        height: 40px;
        padding: 0 @ib-padding-base;
        display: flex;
        align-items: center;
        border: 1px solid @gray-gray-200;
        border-radius: @ib-border-radius-x-small;
        transition: all 0.2s ease-out;

        &:hover {
          border: 1px solid @blue-blue-700;
        }

        .ib-social {
          margin-right: @ib-padding-xx-small;
        }

        .ib-radio {
          margin-left: auto;
        }
      }
    }
  }

  &_wide {
    .ant-modal {
      width: 990px !important;

      @media (max-width: @ib-breakpoint-small) {
        width: 100% !important;
      }
    }

    .ant-modal-body {
      display: flex;
    }
  }

  &__instruction {
    margin-bottom: @ib-padding-base;

    ul {
      margin: @ib-padding-base 0 0 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: @ib-padding-base;

      li {
        list-style: none;
        display: flex;
        align-items: flex-start;

        .ib-badge {
          margin-right: @ib-padding-xx-small;
        }
      }
    }
  }

  &__form-item {
    &:not(:last-child) {
      margin-bottom: @ib-padding-base;
    }

    &__switch {
      display: flex;
      align-items: center;
      gap: @ib-padding-xxxx-small;
      margin-top: @ib-padding-xxxx-small;
    }

    .ib-input, .ib-color-picker, .ib-select, .ib-typography__paragraph_error, .ib-typography__paragraph_disabled {
      margin-top: @ib-padding-xxxx-small;
    }

    .ib-typography__paragraph_disabled .ib-button {
      display: inline;
      margin-left: @ib-padding-xxxx-small;
      .block-size(16px);

      .ib-icon {
        .block-size(16px);
      }
    }
  }

  &__form-group {
    display: flex;
    gap: @ib-padding-base;

    > div {
      flex: 1;
    }
  }

  &__code-snippet {
    margin-bottom: @ib-padding-base;

    .ib-textarea {
      margin-top: @ib-padding-xxxx-small;
    }

    .ib-button {
      margin-top: @ib-padding-xx-small;
    }
  }

  &__livechat {
    display: flex;
    flex-grow: 1;

    @media (max-width: @ib-breakpoint-small) {
      max-width: 100%;
    }

    &__settings {
      overflow-y: auto;
      padding-right: @ib-padding-base;
      flex-grow: 1;
      // HACK: исправлен баг отображения Select внутри TabPane в Chrome, при котором Select начинает занимать
      // больше 100% ширины в разметке, если среди options есть значение с длинным value.
      width: 432px;

      @media (max-width: @ib-breakpoint-small) {
        width: auto;
        padding-right: 0;
      }

      &__subtitle {
        margin-bottom: @ib-padding-base;

        &:not(:first-child) {
          padding-top: @ib-padding-xxxx-small;
        }
      }
    }

    &__preview-mobile-toggle {
      z-index: 200;
      display: none;
      position: fixed;
      right: @ib-padding-base;
      top: @ib-padding-medium;
      align-items: center;

      @media (max-width: @ib-breakpoint-small) {
        display: flex;
      }

      .ib-typography__paragraph {
        margin-right: @ib-padding-xx-small;
      }
    }

    &__preview {
      width: 478px;
      height: 100%;
      min-height: 630px;
      flex-shrink: 0;
      margin-left: @ib-padding-base;
      background: @gray-gray-50;
      border-radius: @ib-border-radius-xx-small;
      position: relative;
      overflow: hidden;

      @media (max-width: @ib-breakpoint-small) {
        display: none;
      }

      &_mobile-visible {
        z-index: 100;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0;

        .elma-bot-live-chat__chat-container__title__close-icon {
          opacity: 0;
        }
      }

      .elma-bot-live-chat {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: relative;

        .elma-bot-live-chat__toggle-button {
          position: absolute;
        }

        .elma-bot-live-chat__chat-container {
          position: absolute;
        }
      }
    }
  }

  .ant-modal-footer {
    .ib-button_secondary {
      @media (max-width: @ib-breakpoint-small) {
        display: none;
      }
    }

    .ib-button_fill {
      display: none;

      @media (max-width: @ib-breakpoint-small) {
        display: flex;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;