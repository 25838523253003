@import "src/simple-bot/styles/variables";

.sb-channels-card__content__list-row__list__item {
  border-radius: @sb-border-radius-large;
  margin: 12px 10px 12px 20px;
  cursor: default;

  .ant-card-body {
    padding: 20px 22px 18px 22px;
  }

  &__title {
    align-items: center;
    margin-bottom: 12px;
    flex-flow: nowrap;

    &__name {
      display: flex;
      align-items: center;
      font-size: @sb-font-size-x-large;
      font-weight: @sb-font-weight-bold;

      .sb-channel-icon {
        margin-right: 16px;
      }
    }

    &__controls {
      align-self: flex-start;
    }

    .sb-channel-status {
      margin-right: 16px;
    }

    .sb-switch {
      margin-right: 16px;
    }

    .ant-dropdown-trigger {
      display: flex;
      align-items: center;
    }
  }

  .ant-divider {
    margin: 24px 0;
  }

  .ant-space-item .sb-tag {
    vertical-align: text-bottom;
  }

  &__properties {
    display: grid;
    grid-template-columns: 200px minmax(0, 1fr);
    row-gap: 8px;
    color: @sb-font-color-base;

    &__label {
      opacity: 0.5;
    }

    &__value {
      .ant-btn-sm {
        padding: 0;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;