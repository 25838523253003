@import "src/simple-bot/styles/variables";

@sb-button-group-divider-color: #D9D9D9;

.sb-radio-button-group {
  display: flex;
  flex-direction: column;
  border-radius: @sb-border-radius-small;
  background: #FFFFFF;
  overflow: hidden;
  padding: 8px;

  .sb-radio-button:not(:first-child):before {
    display: none;
  }

  &.bordered .sb-radio-button:not(:first-child) {
    height: 35px;

    &:before {
      display: block;
      position: relative;
      top: unset;
      left: unset;
      height: 1px;
      width: 48px;
      margin: 4px -8px;
      padding: 0;
      background: @sb-button-group-divider-color;
      pointer-events: none;
      transition: none;
    }
  }

  &:hover {
    box-shadow: @sb-button-box-shadow;

    &.bordered .sb-radio-button:not(:first-child):before {
      background: @sb-primary-color;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;