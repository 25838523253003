@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-channel-diagnostics-modal {
  .ant-modal {
    width: 95vw !important;
    height: 90vh !important;

    @media (max-width: @ib-breakpoint-small) {
      .block-size(100%) !important;
    }

    .ant-modal-content {
      height: 100%;
    }
  }

  .ant-modal-body {
    display: flex;
  }

  &__form-item {
    width: 100%;
  }

  .ant-modal-footer {
    .ib-button_secondary {
      @media (max-width: @ib-breakpoint-small) {
        display: none;
      }
    }

    .ib-button_fill {
      display: none;

      @media (max-width: @ib-breakpoint-small) {
        display: flex;
      }
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;