@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-chat-list-item {
  display: flex;
  width: 100%;
  padding: @ib-padding-base;
  border-radius: 10px;
  gap: 8px;
  cursor: pointer;

  &_selected,
  &:hover {
    background-color: @ib-chat-list-item-hover-bg-color;
  }

  &__content {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: @ib-padding-xxxx-small;

    &__title {
      display: flex;
      font-size: @ib-font-size-base;
      line-height: @ib-line-height-base;
      margin-bottom: 4px;
      gap: 2px;

      &__participant {
        flex-grow: 1;
        min-width: 0;

        * {
          .text-ellipsis();
        }
      }

      &__time {
        color: @gray-gray-300;
        flex-shrink: 0;
      }

      .ib-typography__paragraph {
        margin-bottom: 0 !important;
      }
    }

    &__text-row {
      display: flex;
      gap: 2px;

      &__message {
        flex-grow: 1;
        min-width: 0;
        max-height: 48px;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 84%, rgba(0,0,0,0));
        -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 84%, rgba(0,0,0,0));

        .ib-typography__paragraph {
          margin-bottom: 0;
        }

        .ib-typography__paragraph_disabled {
          display: inline;
          position: relative;
          margin-right: 2px;
          max-width: 120px;
          .text-ellipsis();
        }
      }

      .ib-tag {
        margin-left: 4px;
      }
    }

    .ib-typography__paragraph {
      margin-bottom: 8px;
    }
  }

  // Стили для скелетонов
  &_loading {
    pointer-events: none;
    padding: 6px 16px;

    &:first-of-type {
      padding-top: 16px;
    }

    & > *:first-child {
      line-height: 0;
    }
  }

  &_loading & {
    &__content {
      &__title {
        &__participant {
          margin-bottom: 8px;
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;