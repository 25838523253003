@import "src/simple-bot/styles/variables";

@sb-group-container-bg-color: rgba(255, 255, 255, 0.8);
@sb-trigger-group-container-bg-color: rgba(250, 250, 250, 0.8);

.group-container {
  position: absolute;
  padding: 12px 16px;
  background: @sb-group-container-bg-color;
  border-radius: 20px;
  width: @sb-editor-group-container-width;
  z-index: @sb-editor-group-container-z-index;
  border: 2px solid transparent;

  .context-menu {
    visibility: hidden;
  }

  .sb-end-scenario-output-select .context-menu {
    visibility: visible;

    &__content {
      display: block;
    }
  }

  &_is-trigger-group {
    background: @sb-trigger-group-container-bg-color;
  }

  &:hover {
    box-shadow: @sb-box-shadow-default;

    .group-container__header .context-menu {
      visibility: visible;
    }

    > .add-button {
      .add-button__button {
        border-color: @sb-add-button-border-hover-color;
        color: @sb-font-color-base;
      }
    }
  }

  &_warning {
    border: 2px solid @sb-editor-elements-warning-color;
    background: @sb-control-color-background;
  }

  &_selected {
    z-index: @sb-editor-group-container-z-index + 1;
    border: 2px solid @sb-editor-elements-selected-color;
    background: @sb-group-container-bg-color;

    .group-container__header .context-menu {
      visibility: visible;
    }

    > .add-button {
      .add-button__button {
        border-color: @sb-add-button-border-hover-color;
        color: @sb-font-color-base;
      }
    }
  }

  &_child-selected {
    z-index: @sb-editor-group-container-z-index + 1;
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .group-icon {
      cursor: grab;
      display: flex;
      align-items: center;
      padding-right: 6px;
    }

    .group-title {
      line-height: @sb-line-height-base;
      flex-grow: 1;
      font-size: @sb-font-size-base;
      font-weight: @sb-font-weight-bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: grab;
      display: flex;
      align-items: center;
      align-self: stretch;

      &__pencil {
        display: none;
        color: @sb-font-color-secondary-2;
      }

      &_empty {
        color: transparent;

        &:hover {
          .group-title__pencil {
            display: block;
          }
        }
      }

      input {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        color: inherit;
        padding: 0;
        border: none;
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
        caret-color: @sb-control-color-foreground;
      }
    }
  }

  &__description {
    font-size: @sb-font-size-small;
    color: @sb-font-color-secondary;
    margin-bottom: 16px;
  }

  > .output-connection {
    margin: 16px 0 0 auto;
  }

  > .input-connection {
    position: absolute;
    height: 0;
    width: 0;

    &_right {
      top: 50%;
      right: -3px;
    }

    &_left {
      top: 30px;
      left: -3px;
    }
  }

  .group-elements-container {
    position: relative;

    .placeholder-container {
      margin-bottom: 16px;
    }

    .group-element {
      position: relative;
      z-index: @sb-editor-group-element-z-index;
      top: auto !important;
      left: auto !important;
      height: auto !important;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      padding: 16px;
      background: @sb-group-element-bg-color;
      border: 1px solid @sb-group-element-border-color;
      border-radius: 20px;

      &_is-trigger {
        .output-connection {
          margin: 12px 0 0 auto;
        }

        .group-element__header {
          margin-bottom: 12px;
        }
      }

      &:hover {
        .group-element__header .context-menu {
          visibility: visible;
        }

        .start-elma365-process-action, .create-elma365-app-element-action {
          &__content .dashed-button {
            border-color: @sb-add-button-border-hover-color;
            color: @sb-font-color-base;
          }
        }
      }

      &_warning {
        border: 2px solid @sb-editor-elements-warning-color;
        padding: 15px;
      }

      &_selected {
        z-index: @sb-editor-group-element-z-index + 1;
        border: 2px solid @sb-editor-elements-selected-color;
        padding: 15px;

        .group-element__header .context-menu {
          visibility: visible;
        }

        .start-elma365-process-action, .create-elma365-app-element-action {
          &__content .dashed-button {
            border-color: @sb-add-button-border-hover-color;
            color: @sb-font-color-base;
          }
        }
      }

      &_child-selected {
        z-index: @sb-editor-group-element-z-index + 1;
      }

      &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;

        .group-element-icon {
          cursor: grab;
          display: flex;
          align-items: center;
          padding-right: 6px;
        }

        .group-element-title {
          line-height: 24px;
          flex-grow: 1;
          font-size: @sb-font-size-base;
          font-weight: @sb-font-weight-bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: grab;
        }
      }

      span.ant-typography {
        color: @sb-font-color-secondary;
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
      }

      h1.ant-typography {
        color: @sb-font-color-base;
        font-size: @sb-font-size-x-large;
        line-height: @sb-line-height-x-large;
        font-weight: 400;
        margin: 0;
      }
    }
  }

  .dashed-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: @sb-font-size-base;
    font-weight: @sb-font-weight-bold;
    line-height: @sb-line-height-base;
    background: @sb-control-color-background;
    color: @sb-font-color-secondary-2;
    border: 1px dashed @sb-add-button-border-color;
    border-radius: @sb-border-radius-small;
    cursor: pointer;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;