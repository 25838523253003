@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-operator-group-edit-modal {
  .ant-modal {
    width: 830px !important;

    @media (max-width: @ib-breakpoint-small) {
      width: 100% !important;
    }
  }

  &__form-item {
    &:not(:last-child) {
      margin-bottom: @ib-padding-base;
    }

    .ib-input, .ib-typography__paragraph_error {
      margin-top: @ib-padding-xxxx-small;
    }
  }

  &__form-subtitle {
    margin-bottom: @ib-padding-base;
    display: flex;
    gap: @ib-padding-xx-small;

    &:not(:first-child) {
      padding-top: @ib-padding-xxxx-small;
    }
  }

  &__delete-button {
    margin-left: auto !important;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  .ib-button_secondary {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  .ib-button_fill {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
    }
  }

  &__operator-list {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ib-button, .ib-input {
      width: 100%;
      flex-shrink: 0;
    }

    .ib-input {
      margin-bottom: @ib-padding-xx-small;
    }

    ul {
      list-style: none;
      padding: 0;
      max-height: 200px;
      overflow-y: auto;
      margin-bottom: @ib-padding-xx-small;

      @media (max-width: @ib-breakpoint-small) {
        max-height: unset;
        flex-grow: 1;
      }

      li {
        > .ib-typography {
          display: flex;
          align-items: center;
          gap: @ib-padding-xx-small;
          padding: @ib-padding-xxx-small 0;
          cursor: pointer;

          &:hover {
            > .ib-typography__paragraph {
              color: @blue-blue-700;
            }
          }

          > .ib-typography__paragraph {
            .text-ellipsis();
          }

          .ib-icon {
            opacity: 0;
          }
        }

        &.ib-operator-group-edit-modal__operator-list__item-checked {
          .ib-icon {
            opacity: 1;
          }
        }
      }
    }

    &__spin-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: @ib-padding-base;
      margin: @ib-padding-xxxx-small 0;
      
      @media (max-width: @ib-breakpoint-small) {
        flex-grow: 1;
      }
    }

    &__empty-list-entry {
      display: flex;
      align-items: center;
      gap: @ib-padding-xx-small;
      padding: @ib-padding-xxx-small 0;
      margin: 0 0 @ib-padding-xx-small @ib-element-size-28;

      @media (max-width: @ib-breakpoint-small) {
        margin: 0 0 auto @ib-element-size-28;
      }
    }
  }

  &__operator-list-popover {
    .ant-popover-inner-content {
      width: 272px;

      @media (max-width: @ib-breakpoint-small) {
        width: unset;
        height: 304px;
      }
    }
  }

  &__clear-list-button {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
      margin-left: auto;
    }
  }

  &__operators-table {
    &__name-cell {
      display: flex;
      align-items: center;
      gap: @ib-padding-xx-small;
    }

    &__groups-cell {
      display: flex;
      flex-wrap: wrap;
      gap: @ib-padding-xx-small;
    }

    &__actions-cell {
      padding: @ib-padding-base 0 @ib-padding-base @ib-padding-xxx-small !important;

      .ib-button {
        margin-left: auto;
      }
    }

    &__expanded-row-actions {
      margin-top: @ib-padding-large;
      display: inline-flex;
      flex-wrap: wrap;
      gap: @ib-padding-base;
    }

    .ant-table-expanded-row {
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: @ib-padding-base;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          gap: 28px;
          align-items: flex-start;

          > div:first-child {
            min-width: 60px;
          }

          > div:last-child {
            display: flex;
            flex-wrap: wrap;
            gap: @ib-padding-xxx-small;
          }
        } 
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;