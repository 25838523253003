@import "src/inbox/styles/variables";

.ib-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-tabs-content-holder {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 0;
  }

  .ant-tabs-content {
    height: 100%;
    min-height: 0;
  }

  .ant-tabs-top-bar {
    border: none;
  }

  .ant-tabs-nav {
    margin: 0 0 16px 0;

    &:before {
      display: none;
    }

    &-list {
      &:before {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        bottom: 0;
        background: @gray-gray-200;
      }
    }

    .ant-tabs-ink-bar {
      height: 0;
    }
  }

  .ant-tabs-nav-wrap {
    margin: 0;
  }

  .ant-tabs-tab {
    margin: 0;
    padding: 0;
    display: inline-block;

    &:first-child {
      .ant-tabs-tab-btn {
        margin: 0;
      }
    }
  }

  .ant-tabs-tab-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    text-shadow: none !important;
    transition: none;
    font-style: normal;
    font-weight: @ib-font-weight-normal;
    font-size: @ib-font-size-small;
    line-height: @ib-line-height-base;
    color: @gray-gray-300 !important;
    padding: @ib-padding-xx-small 0;
    margin: 0 0 0 20px;
    border-style: solid;
    border-width: 0 0 2px 0;
    border-color: transparent;

    &:hover {
      border-color: @gray-gray-300;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      font-weight: @ib-font-weight-bold;
      color: @blue-blue-700 !important;
      border-color: @blue-blue-700;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;