@import "src/simple-bot/styles/variables";

.sb-date-picker {
  width: 100%;
  box-shadow: none;
  border-radius: @sb-border-radius-small;
  border-style: solid;
  border-color: @sb-add-button-border-color;
  border-width: 2px;
  padding: 6px 15px 6px 12px;

  &:hover,
  &.ant-picker-focused {
    border-right-width: 2px !important;
    border-color: @sb-input-color-hover;
  }

  .ant-picker-suffix {
    color: @sb-font-color-base;
  }

   .ant-picker-input > input {
    color: @sb-font-color-base;
    font-family: @sb-font-family;
    font-size: @sb-font-size-base;
  }

  .ant-picker-input > span {
    padding-top: 5px;
  }

  &__dropdown {

    .ant-picker-panel-container {
      box-shadow: @sb-box-shadow-default;
      border-radius: @sb-border-radius-small;
      padding: 12px 20px;

      .ant-picker-header-super-prev-btn,
      .ant-picker-header-super-next-btn {
        display: none;
      }

      .ant-picker-header {

        .ant-picker-header-view {
          font-weight: @sb-font-weight-bold;

          .ant-picker-year-btn {
            margin-left: 4px;
          }

          &:hover button {
            color: @sb-primary-color;
          }
        }
      }

      .ant-picker-panel {
        border-bottom: none;
      }

      .ant-picker-content {

        thead {
          display: none;
        }

        .ant-picker-cell {
          color: @sb-button-color-disabled;
          font-family: @sb-font-family;
          font-size: @sb-font-size-small;
          font-weight: normal;
          line-height: @sb-line-height-small;
          padding: 0 2px;

          &.ant-picker-cell-in-view {
            color: @sb-font-color-base;
          }

          .ant-picker-cell-inner {
            min-width: 30px;
            height: 30px;
            border-radius: 15px;
            border: 1px solid transparent;
            padding: 2px 0;
          }

          &:hover .ant-picker-cell-inner {
            background-color: @sb-dropdown-menu-list-item-bg-hover-color;
          }

          &.ant-picker-cell-today .ant-picker-cell-inner {
            border-color: @sb-primary-color;

            &::before {
              border: none;
            }
          }

          &.ant-picker-cell-selected .ant-picker-cell-inner {
            border-color: @sb-primary-color;
            background-color: @sb-primary-color;
          }
        }
      }

      .ant-picker-footer {
        font-family: @sb-font-family;
        font-size: @sb-font-size-small;
        font-weight: @sb-font-weight-bold;
        line-height: @sb-line-height-small;
        padding: 12px 0 7px 0;

        .ant-picker-today-btn {
          color: @sb-primary-color;
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;