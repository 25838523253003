@import "src/inbox/styles/variables";

.ib-sider {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 80px;
  border-right: 1px solid @gray-gray-100;

  &_chat-selected {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 24px;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }

    svg {
      cursor: pointer;
    }
  }

  &__menu {
    flex-grow: 1;
    min-height: 0;
  }

  &__account {
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  @media (max-width: @ib-breakpoint-small) {
    flex-direction: row;
    width: 100%;
    height: 64px;
    border-right: unset;
    border-top: 1px solid @gray-gray-100;
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;