@import "src/simple-bot/styles/variables";
@import "src/inbox/styles/variables";

@sb-avatar-bg-color: #F5F5F5;

.ib-dialogs-card {
  display: flex;
  gap: @ib-padding-large;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    gap: @ib-padding-xx-small;

    .sb-panel {
      margin-bottom: @ib-padding-base;
      line-height: @sb-line-height-base;
      font-size: @sb-font-size-base;
      font-weight: @sb-font-weight-bold;
      color: @sb-font-color-base;
    }

    &__info {
      display: flex;
      gap: @ib-padding-base;
      align-items: center;

      &__title-main {
        line-height: @sb-line-height-base;
        font-size: @sb-font-size-base;
        color: @sb-font-color-base;
      }

      &__title-optional {
        line-height: @sb-line-height-x-small;
        font-size: @sb-font-size-x-small;
        color: @sb-font-color-secondary;
      }
    }

    .ant-avatar {
      background-color: @sb-avatar-bg-color;
      color: @sb-primary-color;
      line-height: @sb-line-height-xxx-large !important;
      flex-shrink: 0;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;