@import "src/simple-bot/styles/variables";

.send-email-settings-modal {
  &__send-attachments {
    display: flex;
    height: 60px;

    &__button {
      width: 100%;

      span {
        width: 100%;
      }

      .sb-upload {
        height: 50px !important;
      }
    }

    &__variable-selector {
      margin: auto 10px;
    }
  }

  .sb-markdown-editor {
    &__toolbar__actions__button {
      color: @sb-font-color-base;
      fill: @sb-font-color-base;

      &:hover, &_selected {
        color: @sb-editor-elements-selected-color;
        fill: @sb-editor-elements-selected-color;
      }
    }
  }

  .sb-tags-input {
    padding: 8px;

    .ant-input {
      margin: 2px 8px 2px 0;
    }

    .sb-tag {
      margin: 2px 4px 2px 0;
    }
  }

  &__recipients {
    display: flex;
  }

  &__var-button {
    transition: all 0.3s;
    fill: @sb-icon-color-base !important;

    &:hover {
      fill: @sb-icon-color-hover !important;
    }
  }

  &__cc-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 8px;
    color: @sb-icon-color-base !important;
    padding: 0;
    transition: all 0.3s;

    &:hover {
      color: @sb-icon-color-hover !important;
    }
  }

  &__attachments {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    padding: 2px 0;

    &:empty {
      padding: 0;
    }

    &__content {
      display: flex;
      align-items: center;

      .sb-icon {
        margin-right: 6px;
        margin-left: 0;
        flex: 1 0 auto;
      }

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .sb-tag {
      margin: 2px;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;