@import "src/simple-bot/styles/variables";

@sb-progress-height: 48px;
@sb-progress-bar-background: #95D480;

.sb-progress {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: @sb-control-color-background;
  height: @sb-progress-height;
  border-radius: @sb-border-radius-small;

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: @sb-border-default;
    border-radius: @sb-border-radius-small;
  }

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: @sb-progress-bar-background;
    transition: width 0.2s;
  }

  &__value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: @sb-font-size-base;
    line-height: @sb-line-height-base;
    color: @sb-font-color-base;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;