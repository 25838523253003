@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-bots-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: overlay;
    margin-top: @ib-padding-large;

    &-empty {
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      &__placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: @ib-padding-large;

        @media (max-width: @ib-breakpoint-small) {
          margin-bottom: @ib-padding-base;
        }

        svg {
          margin-bottom: @ib-padding-xxx-small;
        }

        .ib-typography {
          text-align: center;
          margin-bottom: @ib-padding-large;
        }
      }
    }

    &__table {
      padding: 0 @ib-padding-base;

      @media (max-width: @ib-breakpoint-small) {
        padding: 0;
      }

      &__name {
        display: inline-flex;
        align-items: center;
        gap: @ib-padding-xx-small;

        > .ib-typography__paragraph {
          @media (max-width: @ib-breakpoint-small) {
            font-weight: @ib-font-weight-bold;
          }
        }
      }

      &__channels {
        display: flex;
        flex-wrap: wrap;
        gap: @ib-padding-small;

        &__channel-name {
          display: inline-flex;
          flex-wrap: nowrap;
          align-items: center;
          gap: @ib-padding-xxxx-small;
        }
      }

      &__actions {
        padding: @ib-padding-base @ib-padding-xxx-small !important;
        width: @ib-element-size-32;
        cursor: default !important;
      }

      &__expanded-row-actions {
        margin-top: @ib-padding-large;
        display: inline-flex;
        flex-wrap: wrap;
        gap: @ib-padding-base;
      }

      .ant-table-expanded-row {
        ul {
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: @ib-padding-base;
          padding: 0;
          margin: 0;

          li {
            display: flex;
            gap: @ib-padding-x-large;
            align-items: flex-start;

            > div:first-child {
              min-width: 90px;
            }
          }
        }
      }
    }
  }

  &__upload {
    height: unset !important;

    & .ant-upload-btn {
      padding: 0 !important;
      font-size: @ib-font-size-small;
      line-height: @ib-line-height-base;
    }

    &.ant-upload.ant-upload-drag {
      border: none;
      background: transparent;

      .ant-upload-drag-container {
        display: flex;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;