@import "src/simple-bot/styles/variables";

@sb-bar-height: 32px;
@sb-bar-bar-background: #53A6FF;

.sb-bar {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: @sb-control-color-background;
  height: @sb-bar-height;

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: @sb-bar-bar-background;
    transition: width 0.2s;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;