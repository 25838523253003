@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-tags-widget {
  display: flex;
  flex-wrap: wrap;
  gap: @ib-padding-xxx-small;

  &__add-button {
    display: flex;
    align-items: center;
    height: 24px;
    width: fit-content;
    padding: 0 @ib-padding-xx-small;
    border: 1px dashed @gray-gray-300;
    border-radius: @ib-border-radius-xxx-large;
    cursor: pointer;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;