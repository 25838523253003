@import "src/inbox/styles/variables";

.ib-attachment-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &_file {
      width: @ib-element-size-40;
      height: @ib-element-size-40;
    }

    &_thumbnail {
      display: grid;
      grid-template-columns: 1fr;
      width: @ib-element-size-100;
      height: @ib-element-size-100;
    }

    &__thumbnail {
      grid-row-start: 1;
      grid-column-start: 1;
    }

    &__body {
      grid-row-start: 1;
      grid-column-start: 1;
      place-self: center;
      z-index: 1;
    }
  }

  &__content + &__info {
    margin-left: 10px;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;