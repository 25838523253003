@import "src/inbox/styles/variables";

.ib-contacts-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-empty {
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      &__placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
          margin-bottom: 6px;
        }

        .ib-typography {
          text-align: center;
          margin-bottom: 24px;
        }
      }
    }

    &__table-header {
      display: none;
      align-items: center;
      width: 100%;
      padding: @ib-padding-small;
      padding-left: @ib-padding-base;
      border-bottom: 8px solid @gray-gray-50;

      @media (max-width: @ib-breakpoint-small) {
        display: flex;
      }

      .ib-checkbox {
        margin-right: 22px;
      }

      &__actions {
        margin-left: auto;
      }
    }

    &__table {
      padding: 0 @ib-padding-base @ib-padding-base @ib-padding-base;

      @media (max-width: @ib-breakpoint-small) {
        padding: 0;
      }

      &__name {
        display: inline-flex;
        align-items: center;
        gap: 8px;

        > .ib-typography__paragraph {
          @media (max-width: @ib-breakpoint-small) {
            font-weight: @ib-font-weight-bold;
          }
        }
      }

      &__phone-number {
        white-space: nowrap;
      }

      &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: @ib-padding-xxx-small;
      }

      &__actions {
        padding: @ib-padding-base @ib-padding-xxx-small !important;
        width: 32px;
        cursor: default !important;
      }

      &__expanded-row-actions {
        margin-top: @ib-padding-large;
        display: inline-flex;
        flex-wrap: wrap;
        gap: 16px;
      }

      .found {
        background-color: @red-red-300;
      }

      .ant-table-expanded-row {
        ul {
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 0;
          margin: 0;

          li {
            display: flex;
            gap: 28px;
            align-items: flex-start;

            > div:first-child {
              min-width: 90px;
            }
          }
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;