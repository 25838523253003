@import "src/simple-bot/styles/variables";

.sb-scenario-list-block {
  width: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    h2 {
      margin-right: 16px;
    }

    &__buttons {
      display: flex;
      flex-wrap: wrap;

      .sb-button {
        margin: 0 16px;
        font-size: @sb-font-size-base!important;
        line-height: @sb-line-height-base!important;
      }
    }

    &__sort-panel {
      font-size: @sb-font-size-base;
      line-height: @sb-line-height-base;
      color: @sb-font-color-base;
      margin-left: auto;

      &__text {
        margin-right: 9px;
      }

      .ant-radio-button-wrapper {
        background-color: transparent !important;
        border-style: none !important;
        padding: 0;
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
        color: @sb-font-color-base;

        &:hover {
          color: @sb-primary-color;
        }

        &:not(:first-child)::before {
          background-color: transparent !important;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: none !important;
      }

      .ant-radio-button-wrapper-checked {
        color: @sb-primary-color;
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
        text-shadow: 0 0 .5px @sb-primary-color, 0 0 .5px @sb-primary-color;

        &::after {
          position: absolute;
          content: '';
          height: 2px;
          bottom: 8px;
          left: 0;
          width: 100%;
          background: @sb-primary-color;
        }
      }

      .ant-radio-button-wrapper-checked:last-child::after {
        margin-right: 0;
      }

      .ant-divider-vertical {
        color: @sb-divider-color;
        background-color: @sb-divider-color;
        height: 20px;
      }
    }
  }

  &__content {
    &__list {
      flex-grow: 1;
      margin-top: 15px;

      .ant-row > .ant-col {
        flex: 0 0 auto !important;
      }

      .ant-list-item {
        margin-bottom: 24px !important;
      }

      .ant-spin-container::after {
        background: transparent;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;