@import "src/inbox/styles/variables";

.ib-radio {
  &.ant-radio-wrapper {
    color: @ib-font-color-base;
    font-size: @ib-font-size-small;
    line-height: @ib-line-height-x-small;
    align-items: center;

    &:hover {
      .ant-radio-inner {
        border-color: @ib-primary-color;
      }
    }
  }

  .ant-click-animating-node {
    display: none;
  }

  .ant-radio {
    top: unset;
    font-size: @ib-line-height-x-small;
    line-height: @ib-line-height-x-small;
  }

  .ant-radio-inner {
    width: 16px;
    height: 16px;
    border-color: @ib-radio-border-color;
    background-color: @ib-white-color;

    &:after {
      width: 10px;
      height: 10px;
      top: 2px;
      left: 2px;
    }
  }

  .ant-radio-checked {
    &:after {
      animation-name: unset;
    }

    .ant-radio-inner {
      border-color: @ib-primary-color;

      &:after {
        background-color: @ib-primary-color;
      }
    }
  }

  .ant-radio-disabled .ant-radio-inner {
    background-color: @ib-radio-disabled-bg-color;

    &:after {
      background-color: @ib-radio-border-color;
    }
  }

  .ant-radio-disabled + span {
    color: @gray-gray-300;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;