@import "src/simple-bot/styles/variables";
@import "src/simple-bot/styles/mixins";

.elma365-related-app-element {
  &__caption {
    display: inline-flex;
    align-items: center;

    .sb-tooltip {
      display: flex;
      margin-left: 4px;
    }
  }

  &__variable {
    cursor: pointer;
    overflow: hidden;
    margin-left: 4px;

    &__configure {
      color: @sb-editor-elements-selected-color;
    }
  }

  &__tooltip .ant-tooltip-inner {
    padding: 8px;
    width: 260px;
  }

  .sb-table {
    .elma365-form-properties-table;

    td {
      border-top-width: 0 !important;

      &:nth-child(2) {
        width: 308px;
      }

      &:nth-child(3) {
        width: 0;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;