@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-message-list-item {
  display: flex;
  align-items: flex-end;
  padding: @ib-padding-xxxx-small;
  transition: transform 0.2s;

  &_loading {
    visibility: hidden;
  }

  &_inbound {
    flex-direction: row;
    max-width: 70%;

    @media (max-width: @ib-breakpoint-small) {
      max-width: calc(100% - 46px);
    }
  }

  &_outbound {
    flex-direction: row-reverse;
    max-width: 70%;
    margin-left: auto;

    @media (max-width: @ib-breakpoint-small) {
      max-width: calc(100% - 46px * 2);
    }
  }

  &_history {
    justify-content: center;
  }

  &_swiping {
    transition: unset; // NOTE: предотвращает анимацию свойства при управлении положением жестом
  }

  &__title {
    margin: @ib-padding-xx-small @ib-padding-xxxx-small;
    padding: @ib-padding-xxxxx-small @ib-padding-xx-small;
    border: 1px solid @ib-message-item-secondary-color;
    border-radius: @ib-border-radius-x-small;
    color: @ib-message-item-secondary-color;
    font-size: @ib-font-size-x-small;
    line-height: @ib-line-height-x-small;
  }

  &__participant {
    margin: @ib-padding-xxxx-small;
    width: 36px;

    @media (max-width: @ib-breakpoint-small) {
      width: 32px;
    }

    &__avatar {
      .block-size(36px);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin: @ib-padding-xxxx-small;
    padding: @ib-padding-xx-small @ib-padding-base;
    border-radius: @ib-border-radius-x-large;

    &_inbound {
      background-color: @ib-message-item-inbound-background-color;
    }

    &_outbound {
      background-color: @ib-message-item-outbound-background-color;
    }

    &_internal {
      background-color: @orange-orange-100;

      .lock-icon {
        color: @orange-orange-500 !important;
      }
    }

    .ib-typography__paragraph {
      margin-bottom: @ib-padding-xxxxx-small;
    }

    &__attachments {
      display: flex;
      align-items: flex-start;
      margin: -@ib-padding-xxxx-small;

      &_horizontal {
        flex-direction: row;
        flex-wrap: wrap;
      }

      &_vertical {
        flex-direction: column;
      }

      &__item {
        padding: @ib-padding-xxxx-small;
      }
    }

    &__attachments + &__body {
      margin-top: @ib-padding-xx-small;
    }

    &__body {
      font-size: @ib-font-size-small;
      line-height: @ib-line-height-base;

      &__footer {
        display: inline-flex;
        justify-content: space-between;
        color: @ib-message-item-secondary-color;
        float: right;
        display: inline-flex;
        margin-left: 8px;

        &_inbound {
          justify-content: flex-start;
        }

        &_outbound {
          justify-content: flex-end;
        }

        &__timestamp {
          font-size: @ib-font-size-x-small;
          line-height: @ib-line-height-base;
          display: flex;
          align-items: center;
          gap: 4px;

          &__text {
            padding-top: 2px;
          }
        }

        &_inbound &__timestamp {
          flex-direction: row-reverse;
        }

        &__status {
          display: flex;
          padding: 3px 0 1px 4px;

          &_failure .ib-icon path:first-child {
            fill: @ib-error-color;
            stroke: @ib-error-color;
          }

          .ib-button {
            padding: 0;
            height: unset;
            background: none;

            .ib-icon {
              width: 16px !important;
              height: 16px !important;
            }
          }
        }
      }
    }

    &__quoted-messages {
      margin-bottom: @ib-padding-xx-small;
    }
  }

  &__reply-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .block-size(20px);
    right: -24px;
    opacity: 0;
    transition: opacity 0.3s;

    &_enter-done {
      opacity: 1;
    }

    .ib-icon {
      transform: scale(-1, 1);
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;