@import "src/simple-bot/styles/variables";

// FIXME: Перенести в SbMenu соотв. стили.
@sb-dropdown-menu-text-font-size: 20px;
@sb-dropdown-menu-text-small-font-size: 14px;

.dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  background: #FFFFFF;
  box-shadow: @sb-box-shadow-default;
  border-radius: 6px;
  overflow: auto;
  z-index: @sb-editor-dropdown-menu-z-index;
  padding: 12px 0;

  &&_down {
    min-width: 246px;
  }

  &&_small {
    .ant-menu .ant-menu-item-group .ant-menu-item-group-title {
      font-size: @sb-dropdown-menu-text-small-font-size;
    }

    .ant-menu li.ant-menu-item {
      font-size: @sb-dropdown-menu-text-small-font-size;
      line-height: 22px;
      height: 38px;
    }
  }

  &&_icon {
    border-radius: @sb-border-radius-small;
    padding: 6px 0;

    .ant-menu-item {
      padding: 6px 12px !important;
      height: unset !important;

      .sb-icon {
        margin-right: 0 !important;
      }
    }
  }

  .ant-menu {
    color: @sb-font-color-base;

    &-vertical {
      border-right: none;
    }

    .ant-menu-item-group {
      .ant-menu-item-group-title {
        padding: 8px 24px;
        color: @sb-font-color-base;
        font-size: @sb-dropdown-menu-text-font-size;
        font-weight: @sb-font-weight-bold;
      }

      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    li.ant-menu-item {
      margin: 0;
      padding: 8px 24px;
      font-size: @sb-dropdown-menu-text-font-size;
      line-height: 28px;
      height: 44px;
      display: flex;
      align-items: center;

      .ant-menu-title-content {
        margin-left: 0;

        & > .sb-icon {
          display: block;
        }
      }

      .sb-icon {
        margin-right: 12px;
        flex-shrink: 0;
      }

      &:hover {
        background: @sb-dropdown-menu-list-item-bg-hover-color;
        color: inherit;
      }

      &:active {
        background: @sb-dropdown-menu-list-item-bg-active-color;
        color: inherit;
      }

      &-disabled {
        opacity: 0.4;
        color: inherit !important;
      }

      &-selected {
        color: @sb-primary-color;
        background: none;
      }
    }

    .ant-menu-item-divider {
      margin: 4px 0;
      background: @sb-dropdown-menu-items-divider-color;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;