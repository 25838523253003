@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-chat-info {
  width: 100%;
  min-height: 64px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 @ib-padding-large;

  @media (max-width: @ib-breakpoint-medium) {
    padding: 0 calc(@ib-padding-large - 6px) 0 @ib-padding-large;
  }

  @media (max-width: @ib-breakpoint-small) {
    padding: 0 @ib-padding-small;
  }

  &__actions-button.ib-button {
    flex-shrink: 0;
    margin-right: 6px;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  &__contact-info {
    overflow: hidden;
    display: none;
    flex-grow: 1;
    padding: @ib-padding-small;
    align-items: center;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
    }

    &__data {
      overflow: hidden;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      margin-left: @ib-padding-xx-small;

      .ib-typography__paragraph {
        .text-ellipsis();
      }
    }
  }

  &__back-button.ib-button, &__menu-button.ib-button {
    display: none;
    flex-shrink: 0;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
    }

    .ib-icon {
      width: 24px !important;
      height: 24px !important;
    }
  }

  &__operator {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }

    > .ib-typography {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      &__paragraph {
        &:first-child {
          .text-ellipsis();
        }
      }
    }

    &_disabled {
      > .ib-typography {
        cursor: not-allowed;
      }
    }
  }

  &__reassign-list {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ib-button, .ib-input {
      width: 100%;
      flex-shrink: 0;
    }

    .ib-input {
      margin-bottom: @ib-padding-x-small;
    }

    ul {
      list-style: none;
      padding: 0;
      max-height: 200px;
      overflow-y: auto;
      margin-bottom: @ib-padding-x-small;

      @media (max-width: @ib-breakpoint-small) {
        max-height: unset;
        flex-grow: 1;
      }

      li > .ib-typography {
        display: flex;
        align-items: center;
        gap: @ib-padding-xx-small;
        padding: @ib-padding-xxx-small 0;
        cursor: pointer;

        @media (max-width: @ib-breakpoint-small) {
          padding: @ib-padding-xx-small 0;
          gap: 16px;
        }

        &:hover {
          > .ib-typography__paragraph {
            color: @blue-blue-700;
          }
        }

        .ib-avatar {
          @media (max-width: @ib-breakpoint-small) {
            .block-size(48px);
          }
        }

        > .ib-typography__paragraph {
          .text-ellipsis();
        }

        .ib-icon {
          color: @ib-success-color !important;
          margin-left: auto;
        }
      }
    }

    &__spin-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: @ib-padding-base;
      margin: @ib-padding-xxxx-small 0;
    }

    &__empty-list-entry {
      display: flex;
      align-items: center;
      gap: @ib-padding-xx-small;
      margin-bottom: @ib-padding-x-small;
      padding: @ib-padding-xxx-small 0;
    }
  }

  &__reassign-list-popover {
    .ant-popover-inner-content {
      width: 272px;

      .ant-tabs-content-holder {
        height: unset;
      }
    }
  }

  &__expander {
    display: none;
    margin-left: auto;

    @media (max-width: @ib-breakpoint-medium) {
      display: flex;
    }

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;