@import "src/simple-bot/styles/variables";
@import "src/simple-bot/styles/mixins";

.sb-user-modal {
  &__content {
    position: relative;
    margin: 0 16px;
    font-size: @sb-font-size-base;

    .sb-tag {
      margin: 2px 8px 2px 0;
    }

    > .ant-row {
      margin: 12px 0;
    }

    .ant-col {
      margin: auto 0;
    }

    .sb-spin {
      .center-position;
      display: none;
    }

    &_loading {
      .ant-row {
        opacity: 0;
      }

      .sb-spin {
        display: block;
      }
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;