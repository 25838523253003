@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-popover {
  padding: 0;
  width: unset;

  @media (max-width: @ib-breakpoint-small) {
    position: fixed !important;
    top: unset !important;
    right: @ib-padding-base !important;
    bottom: @ib-padding-base !important;
    left: @ib-padding-base !important;
    width: unset !important;
  }

  &-mask {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: @ib-modal-mask-color;
    z-index: 1;
    transition: all 0.2s ease-out;
    pointer-events: none;

    &_visible {

      @media (max-width: @ib-breakpoint-small) {
        display: block;
      }
    }
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    box-shadow: @ib-popover-box-shadow;
    border-radius: @ib-border-radius-x-small;

    .ant-popover-inner-content {
      padding: @ib-padding-base;
      color: @gray-gray-900;
      font-size: @ib-font-size-small;
      line-height: @ib-line-height-base;

      ul.ib-popover__menu {
        margin: 0;
        padding: 0 @ib-padding-xxxxx-small 0 0;
        list-style: none;

        li {
          > div {
            display: flex;
            gap: @ib-padding-xx-small;
            padding: @ib-padding-xx-small 0;
            cursor: pointer;

            .ib-icon {
              .block-size(20px);
            }

            &:hover {
              color: @blue-blue-700;

              .ib-icon {
                color: @blue-blue-700 !important;
              }
            }
          }

          &:not(:last-child) {
            margin-bottom: @ib-padding-xxxx-small;
          }

          > .ib-typography {
            padding: 0;
            margin-bottom: @ib-padding-small;
          }

          .ant-upload {
            padding: 0 !important;

            &:hover {
              color: @ib-primary-color;
            }
          }
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;