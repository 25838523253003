@import "src/inbox/styles/variables";

.ib-contact-merge-modal {
  &_one-column .ant-modal,
  &_two-column .ant-modal {
    width: 468px !important;
  }

  &_three-column .ant-modal {
    width: 682px !important;
  }

  &_four-column .ant-modal {
    width: 896px !important;
  }

  .ant-modal {
    @media (max-width: @ib-breakpoint-small) {
      width: 100% !important;
    }
  }

  &__form-item {
    .ib-radio-group {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      gap: @ib-padding-xx-small;

      &.ant-radio-group-outline {
        justify-items: center;
      }

      @media (max-width: @ib-breakpoint-small) {
        grid-auto-flow: row;
      }
    }

    &:not(:last-child) {
      margin-bottom: @ib-padding-base;
    }

    .ib-radio-group, .ib-tags-widget {
      margin-top: @ib-padding-xxxx-small;
    }

    .ib-list {
      margin: -@ib-padding-xx-small 0;

      .ant-list-empty-text .ib-typography__paragraph {
        color: @gray-gray-300;
      }
    }

    &__header,
    &__channel {
      display: flex;
      flex-flow: row;
      align-items: center;
      gap: @ib-padding-xx-small;

      &__title {
        flex: 1;
      }
    }
  }

  &_one-column &__form-item .ib-radio-group {
    grid-auto-flow: row;
  }

  .ib-button_secondary {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  .ib-button_fill {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;