@import "src/simple-bot/styles/variables";

.sb-tags-select {
  border: @sb-border-default;
  border-radius: @sb-border-radius-small;
  padding: 6px 12px;
  width: 100%;

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;

    .ant-select-selection-item {
      background: @sb-tags-select-item-background-color;
      border: none;
      border-radius: @sb-border-radius-x-small;
      margin: 2px 8px 2px 0;
      padding: 0 8px;

      .ant-select-selection-item-content {
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
        margin-right: 6px;
      }
    
      .ant-select-selection-item-remove {
        display: flex;
        align-items: center;
      }
    }
  }

  &__dropdown {
    border-radius: @sb-border-radius-small;

    .ant-select-item {
      padding: 7px 24px;
    }

    .ant-select-item-option {
      color: @sb-font-color-base;
      font-size: @sb-font-size-small;
      font-weight: normal;
      line-height: @sb-line-height-small;
      background-color: transparent;
    }

    .ant-select-item-option-selected {
      color: @sb-primary-color;
      font-weight: @sb-font-weight-bold;
    }

    .ant-select-item-option-active {
      background-color: @sb-dropdown-menu-list-item-bg-hover-color;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;