@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-context-menu {
  &__modal {
    overflow: hidden;
    background: @ib-context-menu-mask-color;
    padding: @ib-padding-base;
    display: none;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
    }

    .ant-modal {
      top: unset;
      margin: 0;
      padding-bottom: 0;
      transform-origin: unset !important;
      animation-duration: 0.1s !important;

      .ant-modal-content {
        background-color: @ib-white-color;
        border-radius: @ib-border-radius-small;
        box-shadow: @ib-context-menu-box-shadow;

        .ant-modal-body {
          padding: @ib-padding-xx-small 0;
          font-size: @ib-font-size-small;
          line-height: @ib-line-height-base;
          font-weight: @ib-font-weight-normal;
          color: @ib-font-color-base;
        }
      }
    }
  }

  &__context-menu {
    min-width: 122px;
    box-shadow: @ib-popover-box-shadow;
    border-radius: @ib-border-radius-x-small;
    background-color: @ib-white-color;
    padding: 0 @ib-padding-base;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }

    ul {
      margin: 0;
      padding: @ib-padding-base 0;
      list-style: none;

      li:not(:last-child) {
        padding-bottom: @ib-padding-xxxx-small;
      }

      li > div {
        padding: @ib-padding-xx-small 0;
      }

      li > span > div {
        display: flex;
        gap: @ib-padding-xx-small;
        padding: @ib-padding-xx-small 0;
        cursor: pointer;

        .ib-icon {
          .block-size(20px)
        }

        &:hover {
          color: @blue-blue-700;

          .ib-icon {
            color: @blue-blue-700 !important;
          }
        }
      }
    }

    .ant-dropdown-menu-item:hover {
      background-color: unset;
    }
  }

  &__items {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
    }

    &__item {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: @ib-padding-xx-small;
      padding: @ib-padding-xx-small @ib-padding-base;
      font-size: @ib-font-size-small;
      line-height: @ib-line-height-base;

      &:hover {
        color: @blue-blue-700;

        .ib-icon {
          color: @blue-blue-700 !important;
        }
      }

      &_disabled, &_disabled:hover {
        color: @gray-gray-300;
        cursor: default;

        .ib-icon {
          color: @gray-gray-300 !important;
        }
      }

      span {
        .text-ellipsis;
      }

      .ant-upload {
        padding: 0 !important;

        &:hover {
          color: @ib-primary-color;
        }
      }

      .sb-upload {
        height: unset !important;

        .ant-upload {
          font-size: @ib-font-size-small !important;
          line-height: @ib-line-height-base !important;
        }

        &.ant-upload.ant-upload-drag {
          border: none;
          background: transparent;

          .ant-upload-drag-container {
            display: flex;
          }
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;