@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-operator-groups-widget {
  display: flex;
  flex-wrap: wrap;
  gap: @ib-padding-xxx-small;

  &__add-button {
    display: flex;
    align-items: center;
    height: 24px;
    width: fit-content;
    padding: 0 @ib-padding-xx-small;
    border: 1px dashed @gray-gray-300;
    border-radius: @ib-border-radius-xxx-large;
    cursor: pointer;
  }

  &__popover {
    width: 272px;

    .ant-popover-inner {
      .ant-popover-inner-content {
        padding: 0;
      }
    }

    &__empty-content {
      display: flex;
      flex-direction: column;
      gap: @ib-padding-xxx-small;
      padding: @ib-padding-base;

      .ib-button {
        margin-top: @ib-padding-xxx-small;
      }
    }

    &__content {
      height: 288px;
      padding: @ib-padding-base 0;
      display: flex;
      flex-direction: column;

      .ib-input, .ib-button {
        margin: 0 @ib-padding-base;
      }

      &__empty-search-text {
        flex-grow: 1;
        padding: @ib-padding-xx-small @ib-padding-base;
        text-align: center;
      }
      
      &__group-list {
        margin: @ib-padding-xx-small 0;
        flex-grow: 1;
        overflow-y: auto;

        &__item {
          display: flex;
          align-items: center;
          padding: 0 @ib-padding-base;
          gap: @ib-padding-xx-small;
          height: 36px;
          cursor: pointer;

          &:hover {
            background: @gray-gray-50;
          }

          .ib-icon {
            opacity: 0;
          }
          
          &_selected {
            .ib-icon {
              opacity: 1;
            }
          }

          .ib-typography__paragraph {
            .text-ellipsis();
            color: @gray-gray-900;
          }
        }
      }

      .ib-button {
        margin: 0 @ib-padding-base;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;