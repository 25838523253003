@import "src/inbox/styles/variables";

.suggestions-popover {
  min-width: 210px;
  max-height: 180px;
  overflow: auto;
  overflow-y: overlay;
  border-radius: @ib-border-radius-x-small;
  padding: @ib-padding-base 0;
  cursor: default;

  @media (max-width: @ib-breakpoint-small) {
    width: calc(100vw - @ib-padding-base * 2);
    margin-left: @ib-padding-base;
  }

  & > div {
    cursor: pointer;
  }

  &__empty-list-entry {
    display: flex;
    align-items: center;
    gap: @ib-padding-xx-small;
    cursor: default !important;
    padding: @ib-padding-xxxx-small @ib-padding-base;

    &__spin-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: @ib-element-size-40;
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;