@import "src/simple-bot/styles/variables";

@sb-answer-title-font-size: 14px;

.answers {
  .answer-wrapper:not(:first-child) {
    margin-top: 8px;
  }

  .placeholder {
    color: @sb-font-color-secondary-2;
    cursor: pointer;
    margin: 0 0 -1px 0;
  }

  &_warning .placeholder {
    border: 1px solid @sb-editor-elements-warning-color;
    border-radius: @sb-border-radius-small;
    padding: 2px 7px;
    margin: -3px -8px -4px -8px;
  }

  .answer-title {
    font-size: @sb-answer-title-font-size;
    font-weight: @sb-font-weight-bold;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;