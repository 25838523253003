@import "src/inbox/styles/variables";

.ib-radio-button {
  font-size: @ib-font-size-small;
  line-height: @ib-line-height-base;
  font-weight: @ib-font-weight-normal;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 @ib-padding-base;
  height: @ib-element-size-32;
  border-radius: @ib-border-radius-x-small !important;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
  box-shadow: none !important;

  &.ant-radio-button-wrapper-disabled {
    cursor: not-allowed;
  }

  &.ant-radio-button-wrapper {
    border: @ib-border-thickness-1 solid @gray-gray-300;
    background: @white-white-50;
    color: @gray-gray-300;

    &:hover {
      border-color: @blue-blue-600;
      color: @blue-blue-600;
    }

    &.ant-radio-button-wrapper-disabled {
      border-color: @gray-gray-200;
      color: @gray-gray-200;
    }

    &:before {
      content: none;
    }
  }

  &.ant-radio-button-wrapper-checked {
    border: @ib-border-thickness-1 solid @blue-blue-700 !important;
    background: @blue-blue-700;
    color: @white-white-50;

    &:hover {
      border-color: @blue-blue-600 !important;
      background: @blue-blue-600;
      color: @white-white-50;
    }

    &.ant-radio-button-wrapper-disabled {
      border-color: @gray-gray-100 !important;
      background: @gray-gray-100;
      color: @gray-gray-200;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;