@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-contact-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: @white-white-50;

  @media (max-width: @ib-breakpoint-small) {   
  }

  &__header {
    display: none;
    padding: @ib-padding-base @ib-padding-small;
    
    @media (max-width: @ib-breakpoint-small) {   
      display: flex;
    }
  }

  &__content {
    display:flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: @ib-padding-base @ib-padding-large;

    @media (max-width: @ib-breakpoint-small) {   
      padding: @ib-padding-xxx-small @ib-padding-base;
    }

    &__block {
      &:not(:first-child) {
        margin-top: 32px;

        @media (max-width: @ib-breakpoint-small) {   
          margin-top: 24px;
        }
      }

      &__contact-title {
        display: flex;
        align-items: center;
        padding-bottom: @ib-padding-xx-small;

        .ib-avatar {
          margin-right: 8px;
        }

        > .ib-typography {
          flex-grow: 1;

          > * {
            .text-ellipsis(2);
          }
        }
      }

      &__row {
        display: flex;
        align-items: flex-start;
        margin-top: @ib-padding-base;

        > div:first-child {
          min-width: 106px;
          margin-right: 14px;

          @media (max-width: @ib-breakpoint-small) {   
            margin-right: 34px;
          }
        }

        .ib-typography__paragraph {
          display: flex;
          align-items: flex-start;

          svg {
            width: 20px;
            height: 20px;
            margin-right: 4px;
            flex-shrink: 0;
          }
        }
      }

      &__tags {
        margin-top: @ib-padding-base;

        .ib-tags-widget {
          margin-top: @ib-padding-xx-small;
        }
      }

      .ib-button {
        margin-top: 24px;
        width: 100%;

        @media (max-width: @ib-breakpoint-small) {   
          margin-top: 16px; 
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;