@import "src/inbox/styles/variables";

.ib-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;

  .ant-table-thead > tr > th {
    background: transparent;
    color: @gray-gray-400;
    font-size: @ib-font-size-small;
    line-height: @ib-line-height-base;
    font-weight: @ib-font-weight-bold;
    padding: @ib-padding-base @ib-padding-xx-small;
    border-bottom: 1px solid @gray-gray-100;

    &::before {
      display: none;
    }
  }

  .ant-table-tbody .ant-table-row > td {
    background: transparent;
    padding: @ib-padding-base @ib-padding-xx-small;
    border-bottom: 1px solid @gray-gray-100;

    @media (max-width: @ib-breakpoint-small) {
      border-bottom: 8px solid @gray-gray-50;
    }
  }

  .ant-table-tbody > .ant-table-row-selected > td,
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: @gray-gray-50;

    @media (max-width: @ib-breakpoint-small) {
      background: @white-white-50;
    }
  }

  .ib-table__mobile-main-cell {
    @media (max-width: @ib-breakpoint-small) {
      padding-left: @ib-padding-base !important;
    }
  }

  .ib-table__mobile-actions-cell {
    @media (max-width: @ib-breakpoint-small) {
      padding-left: @ib-padding-base !important;
      padding-right: 0 !important
    }
  }

  .ant-table-selection-column {
    padding: @ib-padding-base @ib-padding-xx-small !important;

    @media (max-width: @ib-breakpoint-small) {
      padding: @ib-padding-base !important;
    }

    & + .ib-table__mobile-main-cell {
      @media (max-width: @ib-breakpoint-small) {
        padding-left: @ib-padding-xx-small !important;
      }
    }
  }

  .ant-table-row-expand-icon-cell {
    display: none;
    padding: @ib-padding-base @ib-padding-small !important;

    @media (max-width: @ib-breakpoint-small) {
      display: table-cell;
    }
  }

  .ant-table-expand-icon-col {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: table-column;
    }
  }

  thead {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  // NOTE: для мобилки отображаем только нужную колонку таблицы, остальные скрываем
  col:not(.ant-table-selection-col, .ant-table-expand-icon-col):not(:nth-child(2)) {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  // NOTE: для мобилки отображаем только нужную колонку таблицы, остальные скрываем
  .ant-table-row {
    th:not(.ant-table-selection-column, .ant-table-row-expand-icon-cell, .ib-table__mobile-main-cell, .ib-table__mobile-actions-cell),
    td:not(.ant-table-selection-column, .ant-table-row-expand-icon-cell, .ib-table__mobile-main-cell, .ib-table__mobile-actions-cell) {
      @media (max-width: @ib-breakpoint-small) {
        display: none;
      }
    }

    td:not(.ant-table-selection-column, .ant-table-row-expand-icon-cell, .ib-table__mobile-main-cell, .ib-table__mobile-actions-cell) {
      @media (max-width: @ib-breakpoint-small) {
        pointer-events: none;
      }
    }
  }

  .ant-table-expanded-row {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: table-row;
    }

    td {
      border-bottom: 8px solid @gray-gray-50;
      background: @white-white-50;
      padding: @ib-padding-small @ib-padding-base;
    }

    &:hover {
      td {
        background: @white-white-50;
      }
    }
  }

  &__header {
    flex-shrink: 0;

    .ant-table-tbody {
      visibility: collapse;
    }
  }

  &__body-wrapper {
    flex-grow: 1;
    overflow: overlay;

    .ant-table-thead {
      visibility: collapse;
    }

    .infinite-scroll-component {
      text-align: center;
    }

    &__spin {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      margin: 24px 0 16px 0;
    }
  }

  &__last-row > td {
    border-bottom: none !important;
  }

  &__last-expanded-row > td {
    border-bottom: none !important;
  }

  &__row-expanded > td {
    border-bottom: 1px solid @gray-gray-100 !important;
  }

  &__row-click-active {
    cursor: pointer;
  }

  &__skeleton {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: @ib-padding-xx-small;
    width: 100%;

    &__row {
      display: flex;
      gap: 12px;

      > span {
        flex-grow: 1;

        &:not(:first-child) {
          @media (max-width: @ib-breakpoint-small) {
            display: none;
          }
        }

        .react-loading-skeleton {
          height: 32px;
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;