@import "src/simple-bot/styles/variables";

.sb-search {
  transition: border 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: @sb-control-color-background;
  border: 1px solid @sb-input-color-border;
  box-sizing: border-box;
  box-shadow: none;
  max-height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;

  .ant-input-search-button {
    display: flex;
    align-items: center;
  }

  &-big {
    height: 51px;
    border-radius: @sb-border-radius-small;
  }

  &-small {
    height: 36px;
    border-radius: @sb-border-radius-x-small;

    & > .ant-input-group {
      & > .ant-input {
        padding: 6px 12px !important;
      }

      & > .ant-input-group-addon {
        & > .ant-input-search-button {
          padding: 7px 10px !important;
        }
      }
    }
  }

  &-big:hover, &-big:focus-within {
    border: 1px solid @sb-primary-color;
  }

  &-small:hover, &-small:focus-within {
    border: 1px solid @sb-primary-color;
  }

  & > .ant-input-group {
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;

    input {
      height: 100%;

      &::placeholder {
        color: @sb-font-color-secondary-2 !important;
      }
    }

    & > .ant-input {
      border: none;
      box-shadow: none;
      background: none;
      padding: 6px 21px;
      font-size: @sb-font-size-base;
      color: @sb-font-color-base;

      &::placeholder {
        color: @sb-font-color-secondary;
        font-size: @sb-font-size-base;
      }
    }

    .ant-input-affix-wrapper {
      height: 100%;
      border-radius: @sb-border-radius-small;
      max-height: 47px;
      border: none;
      margin-top: 2px;

      &:focus {
        box-shadow: none;
      }

      &-focused {
        box-shadow: none;
        border: none;
      }
    }

    & > .ant-input-group-addon {
      background: none;
      width: auto;

      & > .ant-input-search-button {
        border: none;
        box-shadow: none;
        background: none;
        color: @sb-icon-color-hover;
        padding: 6px 14px;

        &::after {
          content: none;
        }
      }

      .i-icon-handle-x {
        color: @sb-icon-color-base;
      }

      .i-icon-loading-four {
        animation: spin 1.2s infinite linear;
        color: @sb-primary-color;
      }
    }

    .ant-input-clear-icon {
      width: 22px;
      height: 22px;
      margin-left: 0;
      margin-right: 4px;
    }
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;