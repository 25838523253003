@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-channel-tune-modal {
  .ant-modal {
    width: 470px !important;

    @media (max-width: @ib-breakpoint-small) {
      width: 100% !important;
    }

    .ant-modal-content {
      min-height: 378px;
    }
  }

  .ib-tabs .ant-tabs-content-holder {
    height: unset;
  }

  &__form-item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    > .ib-input,
    > .ib-typography__paragraph_error,
    > .ib-radio-group,
    > .ib-select,
    > .ib-operator-groups-widget {
      margin-top: 4px;
    }

    > .ib-radio-group {
      margin-top: 8px;
    }

    &__dialogs-per-operator {
      display: flex;
      margin-top: 4px;

      .ib-button {
        width: 40px;
      }

      .ib-input {
        margin: 0 4px;
        width: 64px;

        input {
          text-align: center;
        }
      }
    }

    &__bot-switch, &__worktime-switch {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &__worktime-list {
      margin: @ib-padding-xxxx-small 0 0 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        margin-top: @ib-padding-xxxx-small;

        .ib-checkbox {
          flex-shrink: 0;
        }

        .ib-typography__paragraph {
          margin-left: @ib-padding-xx-small;
          width: 140px;
        }

        .ib-select {
          width: 76px;

          .ant-select-selector {
            height: 32px;
            padding: 0 calc(@ib-padding-xx-small + 20px)  0 @ib-padding-xx-small;
          }
        }

        > span {
          margin: 0 @ib-padding-base;
        }
      }

      &__timepicker {
        width: 80px !important;
        height: 32px !important;
        padding: 0 @ib-padding-xx-small !important;
      }
    }
  }

  .ant-modal-footer {
    .ib-button_secondary {
      @media (max-width: @ib-breakpoint-small) {
        display: none;
      }
    }

    .ib-button_fill {
      display: none;

      @media (max-width: @ib-breakpoint-small) {
        display: flex;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;