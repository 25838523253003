@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-chat-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: @ib-padding-base;
  background: @ib-white-color;
  padding: @ib-padding-base 0;
  padding-bottom: 0;

  &__title {
    display: flex;
    gap: 4px;
    padding: 0 @ib-padding-large;
    height: 32px;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  &__title-mobile {
    display: none;
    gap: 16px;
    padding: 0 @ib-padding-base;
    height: 32px;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
    }

    .ib-typography {
      flex-grow: 1;
      min-width: 0;
      padding-top: 4px;
    }
  }

  &__search {
    padding: 0 @ib-padding-large;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  &__alert {
    margin: 0 @ib-padding-large;
    padding: @ib-padding-small;
    gap: @ib-padding-xx-small;
    border-radius: @ib-border-radius-x-small;
    background: @orange-orange-100;
    display: flex;
    flex-flow: row;

    @media (max-width: @ib-breakpoint-small) {
      margin: 0 @ib-padding-base;
    }

    .ib-icon {
      color: @ib-error-color !important;
    }

    a {
      color: @blue-blue-700;
    }
  }

  &__tabs {
    flex-grow: 1;
    min-height: 0;
    padding: 0 @ib-padding-xx-small;

    @media (max-width: @ib-breakpoint-small) {
      padding: 0;
    }

    .ant-tabs-nav {
      // NOTE: контент табов немного выступает слева и справа, поэтому блок с заголовками
      // смещается дополительно до 24px (до 16px в мобильной версии)
      padding: 0 @ib-padding-base;

      &-list {
        flex-grow: 1;
        min-width: 0;
        justify-content: space-between;

        .ant-tabs-tab-btn {
          margin: 0;
        }
      }

      .ant-tabs-nav-operations {
        display: none;
      }
    }

    &__loading {
      position: relative;
      height: 60px;

      .sb-spin {
        .center-position;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;