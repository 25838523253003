@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

 // todo: добавить стили для десктопа
.ib-modal {
  overflow: hidden;
  background: @ib-modal-mask-color;

  ::-webkit-scrollbar {
    width: @ib-scroll-size;
    height: @ib-scroll-size;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: @ib-scroll-size;
    background-color: @ib-scroll-color;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  @media (max-width: @ib-breakpoint-small) {
    padding: 0 !important;
  }

  .ant-modal {
    transform-origin: unset !important;
    animation-duration: 0.1s !important;
    padding: 0 !important;
    top: unset;

    @media (max-width: @ib-breakpoint-small) {
      margin: 0;
      width: 100% !important;
      max-width: 100% !important;
      height: 100% !important;
      max-height: unset;
    }

    .ant-modal-content {
      display: flex;
      flex-direction: column;
      background-color: @ib-white-color;
      border-radius: @ib-border-radius-small;
      box-shadow: @ib-modal-box-shadow;
      max-height: 100%;

      @media (max-width: @ib-breakpoint-small) {
        border-radius: 0;
        box-shadow: none;
        width: 100%;
        height: 100%;
      }

      .ant-modal-close {
        width: 68px;
        height: 68px;

        @media (max-width: @ib-breakpoint-small) {
          top: 0;
          left: 0;
          right: unset;
          bottom: unset;
          width: 56px;
          height: 64px;
        }

        .ant-modal-close-x {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .i-icon-close {
            display: flex;

            @media (max-width: @ib-breakpoint-small) {
              display: none;
            }
          }

          .i-icon-close-small {
            display: none;

            @media (max-width: @ib-breakpoint-small) {
              display: flex;
            }
          }
        }
      }

      .ant-modal-header {
        border-bottom: none;
        padding: @ib-padding-large 56px @ib-padding-medium @ib-padding-large;
        border-radius: @ib-border-radius-small @ib-border-radius-small 0 0;

        @media (max-width: @ib-breakpoint-small) {
          padding: @ib-padding-medium @ib-padding-medium @ib-padding-medium 56px;
          border-radius: 0;
        }

        h4 {
          .text-ellipsis();
        }

        .ib-spin {
          z-index: 100;
          display: flex;
          align-items: center;
          justify-content: center;
          background: @white-white-50;
          opacity: 0.5;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: @ib-border-radius-small;

          @media (max-width: @ib-breakpoint-small) {
            border-radius: 0;
          }
        }
      }

      .ant-modal-body {
        flex-grow: 1;
        overflow-y: overlay;
        font-size: @ib-font-size-small;
        line-height: @ib-line-height-base;
        font-weight: @ib-font-weight-normal;
        color: @ib-font-color-base;
        padding: 0 @ib-padding-large;

        @media (max-width: @ib-breakpoint-small) {
          padding: @ib-padding-x-small @ib-padding-base;
        }
      }

      .ant-modal-footer {
        border-top: none;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        padding: @ib-padding-medium @ib-padding-large @ib-padding-large @ib-padding-large;

        @media (max-width: @ib-breakpoint-small) {
          padding: @ib-padding-large @ib-padding-base;
        }

        .ib-button {
          &:not(:first-child) {
            margin-left: 6px;
          }

          @media (max-width: @ib-breakpoint-small) {
            width: 100%;

            &:not(:first-child) {
              margin-left: 0;
              margin-top: 6px;
            }
          }
        }
      }
    }
  }

  &_fixed {
    display: block !important;
    padding: 100px 0 @ib-padding-x-large;
    overflow-y: auto;

    @media (max-height: 720px) {
      padding: @ib-padding-x-large 0;
    }
  }

  &_tall {
    padding: @ib-padding-x-large 0;
  }

  &_centered {
    .ant-modal {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .ant-modal-content {
        max-height: calc(100vh - 2 * @ib-padding-base);

        @media (max-width: @ib-breakpoint-small) {
          max-height: 100%;
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;