@import "src/simple-bot/styles/variables";

@sb-tag-box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);

.sb-tag {
  display: inline-flex;
  align-items: center;
  max-width: 400px;
  font-weight: normal;
  overflow-x: hidden;

  &_hoverable:hover {
    box-shadow: @sb-tag-box-shadow;
  }

  &_editing {
    width: 100%;

    .ant-input {
      width: 100%!important;
      margin: 0!important;
      pointer-events: all;
      background-color: transparent;
    }
  }

  &_large {
    height: unset!important;
    max-width: unset;
    border-radius: @sb-border-radius-large;
    padding: 2px 12px;
    font-size: @sb-font-size-x-large;
    line-height: @sb-line-height-x-large;

    &__text {
      overflow-x: hidden;
      word-break: break-word;
    }
  }

  &_medium {
    height: 32px;
    border-radius: @sb-border-radius-large;
    padding: 2px 12px;
    font-size: @sb-font-size-x-large;
    line-height: @sb-line-height-x-large;

    &__text {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &_small {
    height: 24px;
    border-radius: @sb-border-radius-x-small;
    padding: 0 8px;
    font-size: @sb-font-size-base;
    line-height: @sb-line-height-base;

    &__text {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &_small-uncut {
    height: unset!important;
    max-width: unset;
    border-radius: @sb-border-radius-x-small;
    padding: 0 8px;
    font-size: @sb-font-size-base;
    line-height: @sb-line-height-base;

    &__text {
      overflow-x: hidden;
      word-break: break-word;
    }
  }

  &_x-small {
    height: @sb-line-height-small;
    border-radius: @sb-border-radius-x-small;
    padding: 0 8px;
    font-size: @sb-font-size-small;
    line-height: @sb-line-height-small;

    &__text {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .sb-icon {
    margin-left: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: @sb-control-color-foreground;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;