@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-preview-modal {
  position: absolute;
  inset: 0;
  overflow: hidden;
  z-index: 1000;

  &__background {
    background: @gray-gray-900;
    opacity: @ib-opacity-85;
    position: fixed;
    inset: 0;

    @media (max-width: @ib-breakpoint-small) {
      opacity: 1;
    }
  }

  &__preview-container {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 @ib-preview-modal-left-right-buttons-width @ib-preview-modal-bottom-row-height @ib-preview-modal-left-right-buttons-width;
    pointer-events: none;

    @media (max-width: @ib-breakpoint-small) {
      padding: 0;
    }

    &_full-height {
      padding: 0 @ib-preview-modal-left-right-buttons-width;
    }

    &_loading img {
      display: none;
    }

    &__preview {
      pointer-events: all;
      object-fit: contain;
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__controls {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: stretch;
    pointer-events: none;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }

    &__top-row {
      align-self: flex-end;
      padding: @ib-padding-large;
      pointer-events: all;

      .ib-button,
      .ib-button .ib-icon {
        .block-size(@ib-element-size-32);
      }
    }

    &__middle-row {
      flex-grow: 1;
      min-height: 0;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      &__wrapper {
        height: 100%;
        width: @ib-preview-modal-left-right-buttons-width;
        pointer-events: all;
      }

      .ib-button {
        .block-size(100%);
      }

      .ib-button .ib-icon {
        .block-size(@ib-element-size-48);
      }
    }

    &__bottom-row {
      display: flex;
      width: 100%;
      height: @ib-preview-modal-bottom-row-height;
      justify-content: space-between;
      align-items: center;
      pointer-events: all;

      &__sender {
        flex-basis: 25%;
        flex-shrink: 0;
        max-width: 280px;
        display: flex;
        gap: @ib-padding-xx-small;
        padding: @ib-padding-medium @ib-padding-base;

        &__info {
          display: flex;
          flex-direction: column;
          gap: @ib-padding-xxxx-small;

          & > * {
            color: @gray-gray-200 !important;
          }
        }
      }

      &__carousel {
        flex-shrink: 1;
        min-width: 0;
        padding: @ib-padding-small;

        &:only-child {
          margin: 0 auto;
        }
      }

      &__toolbar {
        flex-basis: 25%;
        flex-shrink: 0;
        max-width: 280px;
        display: flex;
        justify-content: flex-end;
        gap: @ib-padding-large;
        padding: @ib-padding-large;

        .ib-button,
        .ib-button .ib-icon {
          .block-size(@ib-element-size-32);
        }
      }
    }
  }

  &__controls_mobile {
    display: none;
    .block-size(100%);

    @media (max-width: @ib-breakpoint-small) {
      display: block;
    }

    &_hidden &__top-row {
      transform: translateY(-100%);
    }

    &_hidden &__bottom-row {
      transform: translateY(100%);
    }

    &__top-row {
      position: fixed;
      width: 100%;
      background-color: @gray-gray-900;
      opacity: @ib-opacity-85;
      display: flex;
      justify-items: stretch;
      justify-content: space-between;
      align-items: center;
      padding: @ib-padding-small @ib-padding-base;
      transition: transform 0.25s ease-in-out;

      & > *:first-child,
      & > *:last-child {
        width: 40px;
      }

      &__sender {
        flex-grow: 1;
        min-width: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ib-typography__paragraph,
        .ib-typography__paragraph_secondary {
          width: 100%;
          text-align: center;
          color: @gray-gray-200;
        }
      }
    }

    &__middle-row {
      flex-grow: 1;
      min-height: 0;
    }

    &__bottom-row {
      position: fixed;
      width: 100%;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-items: stretch;
      transition: transform 0.25s ease-in-out;
      background-color: @gray-gray-900;
      opacity: @ib-opacity-85;

      &__carousel {
        display: flex;
      }

      &__toolbar {
        display: flex;
        width: 100%;
        padding: 0 @ib-padding-base @ib-padding-base;
        align-items: center;
        justify-content: space-between;

        .ib-typography__paragraph_secondary {
          text-align: center;
          flex-grow: 1;
          color: @gray-gray-200;
          text-transform: lowercase;
        }
      }
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;