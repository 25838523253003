@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-bot-scenarios-page {
  display: flex;
  flex-direction: column;
  min-height: 0;
  .block-size(100%);
  gap: @ib-padding-base;

  &__top-bar {
    display: flex;
    padding: 0 @ib-padding-large;
    gap: @ib-padding-base;
    align-items: center;
    width: 100%;
    min-height: 80px;
    min-width: 0;

    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }

    &__title {
      display: flex;
      flex-grow: 1;

      &__link {
        color: @ib-primary-color;
        cursor: pointer;
      }
    }

    &__buttons {
      display: flex;
      gap: @ib-padding-xx-small;

      .ib-input {
        min-width: 240px;
      }

      &__upload {
        height: unset !important;

        & .ant-upload-btn {
          padding: 0 !important;
          font-size: @ib-font-size-small;
          line-height: @ib-line-height-base;
        }

        &.ant-upload.ant-upload-drag {
          border: none;
          background: transparent;

          .ant-upload-drag-container {
            display: flex;
          }
        }
      }
    }

    &_mobile {
      display: none;
      flex-direction: column;
      padding: @ib-padding-medium @ib-padding-base 0;
      gap: @ib-padding-large;

      @media (max-width: @ib-breakpoint-small) {
        display: flex;
      }

      &__title-row {
        display: flex;
        width: 100%;
        align-items: center;
        gap: @ib-padding-base;

        .ib-typography {
          flex-grow: 1;
          min-width: 0;
        }

        .ib-button_link {
          .block-size(@ib-element-size-32);

          .ib-icon {
            .block-size(@ib-element-size-24);
          }
        }
      }

      &__actions-row {
        display: flex;
        align-items: center;
        gap: @ib-padding-base;
      }
    }
  }

  &__content {
    padding: 0 calc(@ib-padding-large - @ib-scroll-size) @ib-padding-large @ib-padding-large;
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;