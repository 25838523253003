@import "src/simple-bot/styles/variables";
@import "src/simple-bot/styles/mixins";

@sb-actions-divider-color: #D9D9D9;

.sb-analytics-card {
  font-size: @sb-font-size-base;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: @sb-font-color-base;

  &__header {
    background: @sb-control-color-background;
    box-shadow: @sb-border-bottom-box-shadow;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 11;

    &__left {
      display: inline-flex;
      align-items: center;
      margin: 8px 0;
      overflow: hidden;

      .sb-button {
        margin-right: 10px;
      }

      &__title {
        display: flex;
        flex-direction: column;
        max-width: 100%;

        h3 {
          font-size: @sb-font-size-x-large;
          line-height: @sb-line-height-x-large;
          margin: 0;
        }

        h4&__subtitle {
          font-size: @sb-font-size-x-small;
          line-height: @sb-line-height-x-small;
          height: @sb-line-height-x-small;
          font-weight: @sb-font-weight-normal;
          color: @sb-font-color-base;
          margin: 0 !important;
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    &__header {
      flex-wrap: nowrap;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    background: @sb-content-page-background-color;
    flex-grow: 1;
    padding: 36px 0 36px 36px;

    .sb-spin {
      .center-position;
    }

    .sb-tabs .ant-tabs-nav {
      padding-right: 36px;
    }

    .sb-scroll {
      padding-right: 36px;

      .ps__rail-y {
        margin-right: 14px;
      }
    }

    &__filters {
      display: flex;
      align-items: center;
      justify-content: space-between;

      // todo: вынести в отдельный компонент
      &__view-mode {
        min-width: 86px;
        margin-right: 32px;

        .ant-radio-button-wrapper {
          height: 44px;
          padding: 10px;
          color: @sb-font-color-base;
          border-width: 2px;
          border-left-width: 0;
          border-color: @sb-border-color-default;
          outline: none !important;
          box-shadow: none !important;

          &:hover {
            color: @sb-input-color-hover;
          }

          &:first-child {
            border-left-width: 2px;
            border-radius: @sb-border-radius-small 0 0 @sb-border-radius-small;
          }

          &:last-child {
            border-radius: 0 @sb-border-radius-small @sb-border-radius-small 0;
          }

          &-checked.ant-radio-button-wrapper:first-child {
            border-right-color: @sb-input-color-hover;
          }

          &-checked {
            color: @sb-input-color-hover;
            border-color: @sb-input-color-hover;

            &:first-child {
              border-color: @sb-input-color-hover;
            }

            &:hover {
              border-color: @sb-input-color-hover;

              &::before {
                background-color: @sb-input-color-hover;
              }
            }

            &::before {
              width: 2px;
              top: -2px;
              left: -2px;
              padding: 2px 0;
              background-color: @sb-input-color-hover;
            }
          }
        }
      }

      &__action.sb-button {
        margin-left: 12px;
        height: 42px;
        padding: 0 10px;
      }
    }

    &__block {
      background: @sb-control-color-background;
      padding: 32px;

      border: @sb-border-default;
      border-radius: @sb-border-radius-large;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &__title h3 {
        font-size: @sb-font-size-x-large;
        line-height: @sb-line-height-x-large;
        font-weight: @sb-font-weight-bold;
        margin-bottom: 12px;
      }

      &__content {
        position: relative;
        height: 400px;
        width: 100%;
        transition: opacity 0.3s;

        &_with-table {
          height: unset;

          &__table-cell {
            display: flex;

            span {
              display: flex;
              align-items: center;
              min-width: 65px;
            }
          }
        }

        &_loading {
          opacity: 0.5;
        }

        .tooltip-content {
          background-color: @sb-control-color-background;
          padding: 4px 8px;
          border-radius: @sb-border-radius-xxx-small;
          box-shadow: @sb-box-shadow-default;

          ul {
            margin: 0;
            font-size: @sb-font-size-small;
          }
        }

        .recharts-legend-item {
          display: inline-flex !important;
          align-items: center;

          &-text {
            font-size: @sb-font-size-small;
            color: @sb-font-color-base !important;
          }
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;