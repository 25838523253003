@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-tags-selector {
  .ib-input {
    margin: 0 @ib-padding-base;
  }

  &__empty-list {
    display: flex;
    flex-direction: column;
    gap: @ib-padding-xxx-small;
    padding: 0 @ib-padding-base;

    .ib-button {
      margin-top: @ib-padding-xxx-small;
    }
  }

  &__empty-search-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 180px;
    margin: @ib-padding-xx-small 0;

    .ib-typography__paragraph {
      margin-top: @ib-padding-xxx-small;
    }
  }

  &__tag-list {
    margin: @ib-padding-xx-small 0;
    height: 180px;
    overflow-y: auto;

    &__item {
      display: flex;
      align-items: center;
      padding: 0 @ib-padding-base;
      gap: 8px;
      height: 36px;
      cursor: pointer;

      &:hover {
        background: @gray-gray-50;
      }

      .ib-icon {
        opacity: 0;
      }

      &_selected {
        .ib-icon {
          opacity: 1;
        }
      }

      &__mini-tag {
        flex-shrink: 0;
        width: 20px;
        height: 6px;
        border-radius: @ib-border-radius-xx-large;
      }

      .ib-typography__paragraph {
        .text-ellipsis();
        color: @gray-gray-900;
      }
    }
  }

  &__buttons {
    margin: @ib-padding-xx-small @ib-padding-base 0 @ib-padding-base;
    display: flex;
    gap: @ib-padding-small;

    .ib-button_secondary {
      flex-grow: 1;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;