@import "src/simple-bot/styles/variables";

.sb-select__dropdown {
  border-radius: @sb-border-radius-small;

  &_type-light {
    padding: 13px 0;

    .ant-select-item {
      padding: 7px 24px;
    }

    .ant-select-item-option {
      color: @sb-font-color-base;
      font-size: @sb-font-size-small;
      font-weight: normal;
      line-height: @sb-line-height-small;
      background-color: transparent;
    }

    .ant-select-item-option-selected {
      color: @sb-primary-color;
      font-weight: @sb-font-weight-bold;
    }

    .ant-select-item-option-active {
      background-color: @sb-dropdown-menu-list-item-bg-hover-color;
    }
  }

  &_type-default {
    padding-left: 24px;

    .ant-select-item {
      padding: 10px 0;
    }

    .ant-select-item-option {
      color: black;
      font-size: 20px !important;
      line-height: 28px;
      font-weight: 400;
    }

    .ant-select-item-option-selected {
      color: @sb-primary-color;
      background-color: transparent !important;
      font-weight: @sb-font-weight-bold;
    }

    .ant-select-item-option-active {
      background-color: transparent !important;
    }
  }
}

.sb-select {
  width: 100%;

  .ant-select-selector {
    box-shadow: none !important;
    border-style: solid;
    background-color: transparent !important;
  }

  &.ant-select-multiple {

    .ant-select-selector {

      .ant-select-selection-overflow-item {
        display: inline-flex;

        & + .ant-select-selection-overflow-item {

          .ant-select-selection-item {
            background: @sb-variable-path-background-color;
          }

          &::before {
            content: ".";
            margin: 3px 4px;
          }
        }

        &.ant-select-selection-overflow-item-suffix {
          margin-left: 8px;

          &::before {
            content: none;
          }
        }

        .ant-select-selection-item {
          border-radius: @sb-border-radius-x-small;
          background: @sb-variable-tag-background-color;
          margin: 2px 0;
          padding-right: 8px;

          .ant-select-selection-item-remove {
            padding: 2px 0;

            .anticon-close > svg {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }

  &_size-big {

    .ant-select-selector {
      border-radius: 12px !important;
      border-width: 2px !important;
      height: 50px !important;
    }

    &.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-right-width: 2px !important;
    }
  }

  &_size-small {

    .ant-select-selector {
      border-radius: 10px !important;
      border-width: 1px !important;
      height: 36px !important;
    }
  }

  &_type-light {

    .ant-select-selector {
      border-color: @sb-input-color-border !important;
      color: @sb-font-color-base;
      font-size: @sb-font-size-base;
      line-height: @sb-line-height-base;

      .ant-select-selection-placeholder {
        color: @sb-font-color-secondary-2;
        opacity: 1;
      }
    }

    .ant-select-arrow {
      transition: @sb-animation-transition;
      color: @sb-font-color-secondary-2;
    }

    &.ant-select-focused,
    &:not(.ant-select-disabled):hover {

      .ant-select-selector {
        border-color: @sb-input-color-hover !important;
      }
    }
  }

  &_type-light&_size-big {

    .ant-select-selector {
      padding: 8px 12px;
    }

    .ant-select-arrow {
      top: 16px;
      right: 28px;
    }

    .ant-select-selection-item {
      padding-right: 24px;
    }
  }

  &_type-light&_size-small {

    .ant-select-selector {
      padding: 3px 12px;
    }

    .ant-select-arrow {
      top: 14px;
      right: 18px;
    }
  }

  &_type-default {

    .ant-select-selector {
      border-color: @sb-primary-color !important;
      padding-left: 24px !important;
      padding-right: 24px !important;
    }

    .ant-select-selection-item {
      color: @sb-primary-color;
      font-size: 20px;
      font-weight: @sb-font-weight-bold;
      position: relative;
      top: 8px;
    }

    .ant-select-arrow {
      color: @sb-primary-color;
      font-size: 16px;
      font-weight: @sb-font-weight-bold;
      top: 24px;
      right: 24px;
    }
  }

  .ant-select-clear {
    margin: 0;
    transform: translate(0, -50%);
    width: 16px;
    height: 16px;
  }

  &__option {
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    width: 100%;

    svg {
      margin-right: 4px;
      flex-shrink: 0;
    }
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  .ant-select-item-empty {
    display: flex;
    justify-content: center;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;