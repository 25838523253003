@import "src/simple-bot/styles/variables";
@import "src/simple-bot/styles/mixins";

.sb-trigger-view__table-row {
  * {
    font-size: @sb-font-size-small;
    line-height: @sb-line-height-small;
  }

  &__content {
    display: flex;
    flex-direction: row;

    p {
      .text-ellipsis;
      width: 100%;
      margin: 0;
    }
  }

  &__col-name {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__col-buttons {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    justify-content: center;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;