@import "src/simple-bot/styles/variables";

.script-editor {
}

.script-editor-modal {
  .ant-modal-title {
    display: flex;
    justify-content: space-between;

    div {
      flex: 1;
    }

    button:last-child {
      justify-content: flex-end;
      color: rgba(0, 0, 0, 0.50);

      &:hover {
        color: rgba(0, 0, 0, 1);
      }

      .sb-icon {
        margin-bottom: 4px;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;