@import "src/simple-bot/styles/variables";

.sb-employee-auth-card {
  margin-top: auto;
  margin-bottom: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: @sb-font-color-base;
  background: @sb-control-color-background;
  border: @sb-border-default;
  border-radius: @sb-border-radius-large;

  &__title {
    font-size: @sb-font-size-xx-large;
    align-self: center;
    margin: 20px;
  }

  &__content {
    align-self: center;
    margin: 20px;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;