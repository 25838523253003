@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-contact-channel-selector {
  display: flex;
  flex-grow: 1;

  &__typography {
    display: flex;
    align-items: start;
    gap: @ib-padding-xx-small;
    width: 100%;
    cursor: pointer;

    &_empty {
      cursor: unset;
    }

    > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      :first-child {
        height: @ib-element-size-24;
      }
    }

    &__popover-expand-icon {
      @media (max-width: @ib-breakpoint-small) {
        display: none;
      }

      &_mobile {
        display: none;

        @media (max-width: @ib-breakpoint-small) {
          display: block;
        }
      }
    }
  }

  &__popover {
    .ant-popover-inner-content {
      width: 272px;

      @media (max-width: @ib-breakpoint-small) {
        width: 100%;
      }

      .ant-tabs-content-holder {
        height: unset;
      }
    }
  }

  &__channel-list {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ib-button {
      width: 100%;
      flex-shrink: 0;
    }

    ul {
      list-style: none;
      padding: 0;
      max-height: 200px;
      overflow-y: auto;
      margin: 0 -@ib-padding-base @ib-padding-x-small;

      @media (max-width: @ib-breakpoint-small) {
        max-height: unset;
        flex-grow: 1;
      }

      li {
        padding: 0 @ib-padding-base;

        &:hover {
          background-color: @gray-gray-50;
        }

        & > .ib-typography {
          display: flex;
          align-items: start;
          gap: @ib-padding-xx-small;
          padding: @ib-padding-xxx-small 0;
          cursor: pointer;

          > .ib-typography__paragraph {
            .text-ellipsis();
          }

          :nth-child(2) {
            display: flex;
            flex-direction: column;

            :first-child {
              height: @ib-element-size-24;
            }
          }

          .ib-icon {
            color: @ib-success-color !important;
            margin-left: auto;
          }
        }
      }
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;