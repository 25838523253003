@import "src/simple-bot/styles/variables";

.sb-steps {
  width: 400px;
  margin-left: auto;
  margin-right: auto;

  .ant-steps-item-icon {
    margin-left: 30px !important;
    margin-top: -6px !important;
  }

  .ant-steps-item-tail {
    width: unset !important;
    right: -39px !important;
    margin-left: 67px !important;
  }

  .ant-steps-item-tail::after {
    background-color: @sb-primary-color !important;
  }

  &__title {
    margin-left: -100px;
    margin-right: -100px;
    margin-top: 2px;
    color: @sb-font-color-base !important;
    font-size: @sb-font-size-x-large;
    line-height: @sb-line-height-x-large;
    font-weight: 400 !important;
  }

  &__icon {
    color: @sb-control-color-background;
    background-color: @sb-step-color-active;
    display: block;
    width: 44px;
    height: 44px;
    padding-top: 7px;
    margin: 0 8px 0 0;
    font-size: @sb-font-size-x-large;
    line-height: @sb-line-height-x-large;
    text-align: center;
    border: 1px solid @sb-step-color-active;
    border-radius: 44px;
    transition: background-color 0.3s, border-color 0.3s;
  }

  .ant-steps-item-finish &__icon {
    background-color: @sb-primary-color;
    border-color: @sb-primary-color;
  }

  .ant-steps-item-wait &__icon {
    background-color: transparent;
    border-color: @sb-step-color-wait;
    color: @sb-step-color-wait;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;