@import "src/inbox/styles/variables";

.ib-message-input {
  width: 100%;
  padding: @ib-padding-base @ib-padding-large;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: @ib-breakpoint-small) {
    padding: @ib-padding-base;
    gap: 4px;
  }

  &_internal {
    background-color: @orange-orange-50;
  }

  .DraftEditor-root {
    color: @ib-font-color-base;
    font-weight: @ib-font-weight-normal;
    font-size: @ib-font-size-small;
    line-height: @ib-line-height-base;
    padding: 0;
    min-height: @ib-line-height-base;
    overflow-y: auto;
    max-height: calc(@ib-line-height-base * 6.5);

    @media (max-width: @ib-breakpoint-small) {
      max-height: calc(@ib-line-height-base * 3.5);
    }

    .public-DraftEditorPlaceholder-inner {
      color: @ib-message-input-placeholder-color;
      position: absolute;
      pointer-events: none;
    }

    span[data-text=true] {
      overflow-wrap: anywhere;
    }
  }

  &__quoted-messages {
    display: flex;
    flex-direction: column;
    gap: @ib-padding-xx-small;
  }

  &__attachments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: @ib-padding-xx-small;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    min-height: 40px;

    &__left {
      display: flex;
      align-items: center;
      gap: 4px;

      @media (max-width: @ib-breakpoint-small) {
        gap: 16px;
      }

      & > .ib-button:first-child {
        margin-left: -8px;
      }

      .ib-tag {
        cursor: pointer;
        max-width: unset;
      }

      &__visibility {
        display: flex;

        @media (max-width: @ib-breakpoint-small) {
          display: none;
        }

        &_mobile {
          display: none;

          @media (max-width: @ib-breakpoint-small) {
            display: unset;
          }
        }
      }
    }
  }

  &__mention {
    color: @blue-blue-700;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;