@import "src/simple-bot/styles/variables";

.sb-collapse {
  background: @sb-control-color-background;
  border: @sb-border-default;
  border-radius: @sb-border-radius-large;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__panel.ant-collapse-item {
    display: flex;
    flex-direction: column;
    border-bottom: @sb-border-default;

    &:last-child {
      border-bottom: none;
    }

    &.ant-collapse-item-active {
      flex: 1 1 auto;
      overflow: hidden;

      .ant-collapse-content-active {
        flex: 1 1 auto;
        overflow: hidden;
      }
    }

    .ant-collapse-header {
      font-size: @sb-font-size-medium;
      line-height: @sb-line-height-medium;
      font-weight: @sb-font-weight-bold;
      color: @sb-font-color-base;
      padding: 18px 16px 18px 24px;
    }

    .ant-collapse-content {
      border: none;

      .ant-collapse-content-box {
        height: 100%;
        padding: 0;
        overflow: hidden;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;