@import "src/simple-bot/styles/variables";

.sb-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-tabs-content-holder {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 0;
  }

  .ant-tabs-content {
    height: 100%;
    min-height: 0;
  }

  .ant-tabs-top-bar {
    border: none;
  }

  .ant-tabs-nav {
    margin: 0 0 20px 0;

    &:before {
      display: none;
    }

    .ant-tabs-ink-bar {
      height: 0;
    }
  }

  .ant-tabs-nav-wrap {
    margin: 0;
  }

  .ant-tabs-tab {
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  .ant-tabs-tab-btn {
    height: @sb-line-height-medium;
    font-style: normal;
    font-weight: @sb-font-weight-bold;
    font-size: @sb-font-size-medium;
    line-height: @sb-line-height-medium;
    color: @sb-font-color-base !important;
    padding: 0 0 28px 0;
    margin: 0 20px 0 0;
    border-style: solid;
    border-width: 0 0 2px 0;
    border-color: transparent;

    &:hover {
      color: @sb-primary-color !important;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: @sb-primary-color !important;
      border-color: @sb-primary-color;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;