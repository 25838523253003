@import "src/inbox/styles/variables";

.ib-progress {
  display: flex;
  width: @ib-element-size-40;
  height: @ib-element-size-40;
  border-radius: @ib-element-size-40 / 2;
  padding: @ib-padding-xxxxx-small;
  border: none;

  &__content {
    position: relative;
    width: @ib-element-size-40 - @ib-padding-xxxx-small;
    height: @ib-element-size-40 - @ib-padding-xxxx-small;
    border-radius: (@ib-element-size-40 - @ib-padding-xxxx-small) / 2;

    &__body {
      position: absolute;
      left: 0;
      top: 0;
      animation: rotation 2s linear infinite;

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    &__icon {
      position: absolute;
      left: @ib-padding-xxx-small;
      top: @ib-padding-xxx-small;
    }
  }

  &_interactive {
    cursor: pointer;
    transition: all 0.2s ease-out;

    & .ant-progress-inner .ant-progress-circle-path,
    & .ant-progress-status-success .ant-progress-inner .ant-progress-circle-path,
    & .i-icon path {
      transition: all 0.2s ease-out;
    }
  }

  &_default&_interactive &__content {
    transition: all 0.2s ease-out;
  }

  &_default {
    background: none;

    & .ant-progress-circle-trail,
    & .i-icon path {
      stroke: @gray-gray-200;
    }

    & .ant-progress-inner .ant-progress-circle-path,
    & .ant-progress-status-success .ant-progress-inner .ant-progress-circle-path {
      stroke: @blue-blue-700;
    }
  }

  &_default&_interactive:hover &__content {
    background: @gray-gray-200;
  }

  &_default&_interactive:hover {
    & .ant-progress-inner .ant-progress-circle-path,
    & .ant-progress-status-success .ant-progress-inner .ant-progress-circle-path,
    & .i-icon path {
      stroke: @white-white-50;
    }
  }

  &_blue {
    background: @blue-blue-700;

    & .ant-progress-circle-trail {
      stroke: none;
    }

    & .ant-progress-inner .ant-progress-circle-path,
    & .ant-progress-status-success .ant-progress-inner .ant-progress-circle-path,
    & .i-icon path {
      stroke: @white-white-50;
    }
  }

  &_blue&_interactive:hover {
    background: @white-white-50;

    & .ant-progress-inner .ant-progress-circle-path,
    & .ant-progress-status-success .ant-progress-inner .ant-progress-circle-path,
    & .i-icon path {
      stroke: @blue-blue-700;
    }
  }

  &_gray {
    background: fade(@gray-gray-500, 70%);

    & .ant-progress-circle-trail {
      stroke: none;
    }

    & .ant-progress-inner .ant-progress-circle-path,
    & .ant-progress-status-success .ant-progress-inner .ant-progress-circle-path,
    & .i-icon path {
      stroke: @white-white-50;
    }
  }

  &_gray&_interactive:hover {
    background: fade(@white-white-50, 70%);

    & .ant-progress-inner .ant-progress-circle-path,
    & .ant-progress-status-success .ant-progress-inner .ant-progress-circle-path,
    & .i-icon path {
      stroke: @gray-gray-500;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;