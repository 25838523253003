@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-thumbnail {
  display: flex;
  border: solid 1px @gray-gray-200;
  border-radius: @ib-border-radius-x-small;
  overflow: hidden;
  cursor: default;

  &_interactive {
    cursor: pointer;
  }

  &_small {
    .block-size(@ib-element-size-44);
  }

  &_medium {
    .block-size(@ib-element-size-100);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;