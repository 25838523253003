@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-radio-group {
  &.ant-radio-group-solid .ant-radio-button-wrapper {
    &.ant-radio-button-wrapper-checked {
      background: @blue-blue-700;

      &:hover {
        background: @blue-blue-600;
      }

      &.ant-radio-button-wrapper-disabled {
        background: @gray-gray-100;
      }
    }
  }

  &.ant-radio-group-outline .ant-radio-button-wrapper {
    .block-round(@ib-element-size-72);
    padding: @ib-padding-xxxx-small - @ib-border-thickness-1;

    &.ant-radio-button-wrapper-checked {
      border-width: @ib-border-thickness-2 !important;
      padding: @ib-padding-xxxx-small - @ib-border-thickness-2;
      background: @white-white-50;
      color: @blue-blue-700;

      &:hover {
        color: @blue-blue-600;
      }

      &.ant-radio-button-wrapper-disabled {
        border-color: @gray-gray-200 !important;
        background: @gray-gray-100;
        color: @gray-gray-200;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;