@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-avatar {
  position: relative;
  flex-shrink: 0;

  &__img-container, &__icon-container, &__children-container, &__meta-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: @ib-avatar-bg-color;

    &__img {
      .block-size(100%);
      display: block;
      object-fit: cover;
    }

    .anticon-user {
      color: @ib-white-color;
    }
  }

  &__children-container {
    svg {
      .block-size(100%);
    }
  }

  &__icon-container {
    background: @gray-gray-100;
  }

  &__status-container {
    position: absolute;
    background: @ib-white-color;
    border-radius: 50%;
    padding: 2px;
    right: 0;
    top: 0;
  }

  &__social-container {
    position: absolute;
    background: @ib-white-color;
    border-radius: 50%;

    svg {
      display: block;
    }
  }

  &_x-small {
    .block-size(32px);

    .anticon-user svg {
      .block-size(18px);
    }

    .ib-avatar__social-container {
      right: -2px;
      bottom: -2px;
      padding: 2px;

      svg {
        .block-size(9px);
      }
    }
  }

  &_small {
    .block-size(36px);

    .anticon-user svg {
      .block-size(22px);
    }

    .ib-avatar__social-container {
      right: -3px;
      bottom: -3px;
      padding: 3px;

      svg {
        .block-size(12px);
      }
    }
  }

  &_medium {
    .block-size(48px);

    .anticon-user svg {
      .block-size(28px);
    }

    .ib-avatar__social-container {
      right: -4px;
      bottom: -4px;
      padding: 4px;

      svg {
        .block-size(16px);
      }
    }
  }

  &_large {
    .block-size(64px);

    .anticon-user svg {
      .block-size(36px);
    }

    .ib-avatar__social-container {
      right: -4px;
      bottom: -4px;
      padding: 4px;

      svg {
        .block-size(@ib-element-size-24);
      }
    }
  }

  &_clickable {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.95;
    }
  }

  &_dark &__social-container,
  &_dark &__children-container {
    background: none;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;