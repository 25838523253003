@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-webchat-button {
  &__button {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }

    &_mobile {
      display: none;

      @media (max-width: @ib-breakpoint-small) {
        display: flex;
      }
    }
  }

  &__webchat-container {
    z-index: 2;
    position: absolute;
    right: @ib-padding-medium;
    bottom: @ib-padding-medium;

    @media (max-width: @ib-breakpoint-small) {
      width: 100vw !important;
      height: 100vh !important;
      right: 0 !important;
      bottom: 0 !important;
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;