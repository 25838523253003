@import "src/inbox/styles/variables";

.ib-inbox-chats-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%; // NOTE: возможно, нужно добавлять на все страницы в инбоксе через mixin или в лайоуте
  flex-grow: 1;

  &__content-row {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    min-height: 0;

    &__chat-details {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__chat-list {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;