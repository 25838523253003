@description-bg-color: #FFFBE7;

.wizard-form-container {
  .ant-pro-steps-form-steps-container {
    margin: unset;
    width: 180px;
  }

  .ant-pro-steps-form-container {
    margin: unset;
    width: 100%;
    min-width: 100%;

    .settings-form {
      width: 100%;
      max-width: 400px;
    }
  }
}

.wizard-switch-wrapper {
  .ant-row.ant-form-item {
    display: inline-flex;
    margin-bottom: 12px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;

    .ant-form-item-label {
      padding: 0;
    }

    .ant-form-item-control {
      flex-grow: unset;
    }
  }
}

.wizard-upload-button-wrapper {
  .ant-form-item .ant-upload.ant-upload-drag {
    padding: 12px 24px;
    max-width: 400px;
    background: #fff;

    .ant-upload.ant-upload-btn {
      padding: 0;
    }
  }

  .ant-row.ant-form-item .ant-upload-drag-container {
    display: inline-flex;
    align-items: center;

    p.ant-upload-drag-icon .anticon {
      font-size: 24px;
      color: #C4C4C4;
    }

    p.ant-upload-text {
      margin-left: 24px;
      font-size: 15px;
      text-align: left;
    }

    p {
      margin: 0;
    }
  }

  .ant-upload-list-picture-card-container {
    width: 400px;
    height: 40px;
    max-width: 100%;
    margin-top: 8px;

    .ant-upload-list-item {
      padding: 0;

      .ant-upload-list-item-actions {
        left: 100%;
        transform: translate(calc(-100% - 12px), -50%);
      }
    }

    .ant-upload-span {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;

      .ant-upload-list-item-thumbnail {
        width: unset;
        display: flex;
        align-items: center;
        position: relative;
        margin-left: 16px;

        .anticon {
          font-size: 20px;
        }
      }

      .ant-upload-list-item-name {
        position: relative;
        width: unset;
        bottom: unset;
        margin: 0;
      }
    }
  }

  .description {
    color: @primary-color;
  }
}

.wizard-kb-description-card {
  border-radius: 2px;
  background: @description-bg-color;
  padding: 16px;
  max-width: 822px;
  margin-bottom: 24px;
}

.wizard-tooltip {
  .ant-tooltip-inner {
    box-shadow: none;
    background: @description-bg-color;
    padding: 16px;
  }

  .ant-tooltip-arrow-content {
    background: @description-bg-color;
  }

  &__header {
    color: #000;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__content {
    color: rgba(0, 0, 0, 0.65);
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;