@import "src/simple-bot/styles/variables";
@import "src/simple-bot/styles/mixins";

.input-action-settings-modal {
  .sb-switch__label {
    font-size: @sb-font-size-base;
    line-height: @sb-line-height-base;
  }

  .answers {
    margin: 4px 10px;
  }

  .sb-scroll > *:first-child {
    height: unset !important;
  }

  .ant-modal-body {
    .sb-scroll:first-child {
      margin-top: 2px; // NOTE: фикс скроллбара
    }
  }

  .sb-spin {
    .center-position;
  }

  .ant-divider {
    margin: 12px 0;
  }

  .sb-panel {
    margin-top: 12px;
    padding: 12px;
  }

  strong {
    font-weight: 500;
  }

  .ant-tabs-content-holder {
    height: auto;
  }

  .field {
    margin-bottom: 16px;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;