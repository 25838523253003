@import "src/simple-bot/styles/variables";

.scenario-editor-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  user-select: none;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .react-flow {
    flex-grow: 1;
  }
}

.scenario-editor {
  height: 100%;
  width: 100%;
  outline: none;
  position: relative;

  &__pane {
    position: absolute;
    height: 100%;
    width: 100%;

    &_active {
      z-index: @sb-working-space-z-index + 1;
      cursor: grab;
    }
  }

  &__validation-container {
    position: absolute;
    right: 20px;
    top: 8px;
    z-index: @sb-editor-controls-z-index;
  }

  &__controls-container {
    position: absolute;
    right: 18px;
    bottom: 40px;
    z-index: @sb-editor-controls-z-index;
  }
}

.context-menu-item {
  line-height: 32px !important;
  height: 32px !important;
  margin: 0 !important;
}

.sb-end-scenario-output-open-scenario-button {
  margin: 0 12px;
  height: 22px;
}

.scenario-editor-validation-warning, .scenario-editor-required-field {
  color: @sb-warning-color;
}

.scenario-editor-validation-caution {
  color: @sb-caution-color;
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;