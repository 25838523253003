@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-user-status {
  border-radius: 50%;
  flex-shrink: 0;

  &_online {
    background: @green-green-300;
  }

  &_offline {
    background: @red-red-600;
  }

  &_x-small {
    .block-size(6px);
  }

  &_small {
    .block-size(7px);
  }

  &_medium {
    .block-size(8px);
  }

  &_large {
    .block-size(16px);
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;