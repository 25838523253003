@import "src/inbox/styles/variables";

.ib-chat-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_empty {
    justify-content: center;
    align-items: center;
    color: @gray-gray-300;

    .ib-typography__paragraph {
      max-width: 200px;
      text-align: center;
    }
  }

  &__scroll {
    height: 100%;
    overflow-y: overlay;

    &__canvas {
      position: relative;
      width: 100%;

      &__window {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;

        & > div:not(:first-child) {
          padding: 2px 0 0;
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;