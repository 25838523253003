@import "src/simple-bot/styles/variables";

.else-case-action {
  margin-top: 8px;
  padding: 6px 14px 6px 12px;
  min-height: 48px;
  width: 100%;
  background: @sb-control-color-background;
  border-radius: @sb-border-radius-small;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &__content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  .output-connection {
    margin-left: 8px;
  }

  &:hover, &_selected {
    box-shadow: @sb-button-box-shadow;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;