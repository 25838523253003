@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-operator-edit-modal {
  &_one-column {
    .ant-modal {
      width: 468px !important;
    }
  }

  &_two-column {
    .ant-modal {
      width: 720px !important;
    }
  }

  .ant-modal {
    @media (max-width: @ib-breakpoint-small) {
      width: 100% !important;
    }
  }

  &__form {
    display: flex;
    flex-flow: row;
    gap: @ib-padding-base;

    @media (max-width: @ib-breakpoint-small) {
      flex-flow: column;
    }

    &__block {
      flex: 1;

      &__item {
        &:not(:last-child) {
          margin-bottom: @ib-padding-base;
        }

        .ib-sound,
        .ib-input,
        .ib-select,
        .ib-phone-number-input,
        .ib-operator-groups-widget,
        .ib-typography__paragraph_error {
          margin-top: @ib-padding-xxxx-small;
        }

        &__controls {
          display: flex;
          flex-flow: row;
          gap: @ib-padding-xx-small;
        }

        &__checkboxes {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-top: @ib-padding-xx-small;
          gap: @ib-padding-base;

          @media (max-width: @ib-breakpoint-small) {
            gap: @ib-padding-small;
          }

          &__item {
            display: flex;
            align-items: center;
            gap: @ib-padding-xx-small;

            @media (max-width: @ib-breakpoint-small) {
              gap: @ib-padding-xxx-small;
            }
          }
        }
      }

      &__extra {
        display: flex;
        gap: @ib-padding-base;

        @media (max-width: @ib-breakpoint-small) {
          flex-direction: column;
          gap: @ib-padding-xx-small;
        }
      }
    }
  }

  .ib-button_secondary {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  .ib-button_fill {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
    }
  }

  .ant-modal-body {
    .ib-operator-edit-modal__block-button {
      display: none;

      @media (max-width: @ib-breakpoint-small) {
        display: flex;
        margin-top: @ib-padding-large;
      }
    }
  }

  .ant-modal-footer {
    .ib-operator-edit-modal__block-button {
      margin-left: auto !important;

      @media (max-width: @ib-breakpoint-small) {
        display: none;
      }
    }
  }

  .ib-alert {
    position: absolute;
    top: @ib-padding-base;
    left: 50%;
    transform: translate(-50%, 0);
    width: 68%;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;