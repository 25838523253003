@import "src/simple-bot/styles/variables";

.sb-menu__item-group {
  .ant-menu-item-group-title {
    padding: 6px 18px;
    line-height: @sb-line-height-small;
    color: @sb-font-color-base;
    font-size: @sb-font-size-small;
    font-weight: @sb-font-weight-bold;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;