@import "src/simple-bot/styles/variables";

.sb-password {
  position: relative;

  &__content {
    background: @sb-control-color-background;
    border-style: solid;
    border-color: @sb-input-color-border;
    box-sizing: border-box;
    box-shadow: none;
    padding: 6px 12px;

    &:hover, &:focus-within {
      border-color: @sb-input-color-hover !important;
    }

    .ant-input {
      color: @sb-font-color-base;
      font-size: @sb-font-size-base;
      line-height: @sb-line-height-base;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: @sb-font-color-secondary-2 !important;
      }
    }

    .ant-input-suffix {
      margin: 0 0 0 32px;
    }
  }

  &__clear {
    display: flex;
    z-index: 1;
    position: absolute;
    top: 15px;
    right: 40px;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    color: @sb-icon-color-base !important;

    &:hover {
      color: @sb-icon-color-hover !important;
    }
  }

  &_warning &__content {
    border-color: @sb-editor-elements-warning-color !important;
  }

  &_size-big &__content {
    height: 50px;
    max-height: 50px;
    border-radius: @sb-border-radius-small;
    border-width: 2px;

    &.ant-input-affix-wrapper {
      border-right-width: 2px !important;

      &:not(.ant-input-affix-wrapper-disabled):hover {
        border-right-width: 2px !important;
      }
    }
  }

  &_size-small &__content {
    height: 36px;
    max-height: 36px;
    border-radius: @sb-border-radius-x-small;
    border-width: 1px;
  }

  &_size-small &__clear {
    top: 8px;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;