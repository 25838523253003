@import "src/simple-bot/styles/variables";
@import "src/inbox/styles/variables";

.sb-create-bot-wizard {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    max-width: 696px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    min-height: 153px;

    @media (max-height: 720px) {
      min-height: unset;
      margin-top: 0;
    }

    @media (max-width: @ib-breakpoint-small) {
      flex-shrink: 0;
    }

    h1 {
      font-size: @sb-font-size-title-medium;
      line-height: @sb-line-height-title-medium;
      font-weight: @sb-font-weight-bold;
      margin-bottom: 6px;
      cursor: default;
    }

    p {
      font-size: @sb-font-size-base;
      line-height: @sb-line-height-base;
      white-space: pre-wrap;
      cursor: default;
    }
  }

  &__steps {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }

    .sb-steps__icon {
      height: 40px;
      width: 40px;
      padding-top: 5px;
    }

    .sb-steps__title {
      font-size: @sb-font-size-base;
      line-height: @sb-line-height-base;
    }
  }

  &__content {
    min-height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .sb-scroll {
      // min-height: 0;
      margin-left: -10px;
      margin-right: -10px;
      // NOTE: чтобы помещалась тень карточек при ховере
      padding-top: 4px;
      margin-top: -4px;
    }

    .ps--active-y {
      margin-right: -38px;

      .ps__thumb-y {
        margin-right: 16px;
      }
    }

    .sb-scroll > :first-child {
      height: unset !important;
    }

    &__list {
      .ant-row {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      .ant-list-item {
        margin-bottom: 24px !important;
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 40px;
    right: 40px;
    padding: 12px;
    display: flex;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    border-radius: @sb-border-radius-large;

    & > *:not(:first-child) {
      margin-left: 20px;
    }

    .sb-icon {
      margin-right: 8px;
    }

    .sb-button {
      height: 40px;
      font-size: @sb-font-size-medium;
      line-height: @sb-line-height-medium;
    }
  }

  .ant-list {
    .ant-spin-container::after {
      background: transparent;
    }
  }


}

.creating-scenario {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
  text-align: center;

  &__spin {
    transform: scale(2);
    transform-origin: 12px 0;
    height: 52px;
  }

  h4 {
    color: @sb-font-color-base;
    font-weight: @sb-font-weight-bold;
    font-size: @sb-font-size-xx-large;
    line-height: @sb-line-height-xx-large;
  }

  p {
    color: @sb-font-color-secondary;
    font-size: @sb-font-size-x-large;
    line-height: @sb-line-height-x-large;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;