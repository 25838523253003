@import "src/simple-bot/styles/variables";

td.sb-table__editable-cell {

  &_read-mode {

    & > div {
      display: flex;
      flex-direction: row;

      & > span {
        width: 1px;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:empty::after {
          content: "\00a0";
        }
      }

      & > button {
        margin: -3px -12px -3px 6px;
        height: @sb-line-height-small;
        display: none;
        flex-grow: 0;
      }
    }

    &:hover button {
      display: block;
    }
  }

  &_edit-mode {
    padding: 0 !important;

    & > .ant-form-item {
      margin-top: -1px;
      margin-bottom: -1px;

      .ant-form-item-control {
        margin: 0 !important;
      }

      & input {
        font-family: @sb-font-family;
        font-style: normal;
        font-size: @sb-font-size-small;
        line-height: @sb-line-height-small;
        color: @sb-font-color-base;
        padding: 13px 11px;
        box-shadow: none;
        border-radius: 0;
        border: 1px solid @sb-cell-color-hover;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;