@import "src/simple-bot/styles/variables";

.sb-user-list {
  margin-top: 12px;

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .sb-button {
      font-size: @sb-font-size-medium;
      height: 40px;
      margin: 0 12px 12px 0;
    }

    .sb-input {
      height: 40px;
      box-shadow: none;
      max-width: 400px;
      margin: 0 0 12px auto;
    }

    .ant-page-header {
      margin: 0 12px 12px 0;

      .ant-page-header-heading-title {
        font-size: @sb-font-size-title-medium;
        line-height: @sb-line-height-title-medium;
        color: @sb-font-color-base;
      }
    }
  }

  &__content {
    background: @sb-control-color-background;
    padding: 20px 36px;
    border: @sb-border-default;
    border-radius: @sb-border-radius-large;
    margin-bottom: 12px;

    &__table {
      &__roles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .sb-tag:not(:first-child) {
          margin-top: 8px;
        }
      }

      &__status {
        &_warning {
          color: @sb-warning-color;
        }
      }
    } 
    
  }
}

.sb-invite-user-modal {
  &__content {
    position: relative;
    margin: 0 16px;

    > .ant-row {
      margin: 12px 0;
    }

    .ant-col {
      margin: auto 0;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;