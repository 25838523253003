@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-channel-status {
  display: inline-flex;
  align-items: center;
  max-width: 400px;
  font-weight: normal;
  overflow-x: hidden;

  height: @ib-element-size-28;
  border-radius: @ib-border-radius-x-large;
  padding: @ib-padding-xxxxx-small @ib-padding-small;
  font-size: @ib-font-size-base;
  line-height: @ib-line-height-large;

  &__text {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &_medium {
    height: @ib-element-size-28;
    border-radius: @ib-border-radius-x-large;
    padding: @ib-padding-xxxxx-small @ib-padding-small;
    font-size: @ib-font-size-large;
    line-height: @ib-line-height-x-large;
  }

  &_small {
    height: @ib-element-size-24;
    border-radius: @ib-border-radius-x-small;
    padding: 0 @ib-padding-xx-small;
    font-size: @ib-font-size-base;
    line-height: @ib-line-height-large;
  }

  &_x-small {
    height: @ib-line-height-medium;
    border-radius: @ib-border-radius-x-small;
    padding: 0 @ib-padding-xx-small;
    font-size: @ib-font-size-small;
    line-height: @ib-line-height-medium;

    .ib-channel-status__marker {
      .block-size(@ib-element-size-8) !important;
    }
  }

  &__marker {
    .block-size(@ib-element-size-9);
    border-radius: 50%;
    margin-right: @ib-padding-xxxx-small;
  }

  .ib-icon {
    margin-left: @ib-padding-xxx-small;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: @ib-black-color;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;