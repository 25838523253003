@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-select {
  width: 100%;
  font-size: @ib-font-size-small;
  line-height: @ib-line-height-base;
  color: @gray-gray-900;
  display: flex;
  align-items: center;

  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 40px;
      border: 1px solid @gray-gray-200;
      border-radius: @ib-border-radius-x-small;
      background: @white-white-50;
      padding: 0 calc(20px + @ib-padding-base) 0 @ib-padding-base;
      width: unset;
      flex-grow: 1;
      box-shadow: none !important;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: @ib-line-height-base;
        padding: 0;
        display: flex;
        align-items: center;
      }

      .ant-select-selection-placeholder {
        color: @gray-gray-300;
      }

      .ant-select-selection-search {
        display: flex;
        align-items: center;
        margin: 0 @ib-padding-xxx-small;
      }

      .ant-select-selection-overflow {
        .ant-select-selection-overflow-item {
          &:first-child:not(.ant-select-selection-overflow-item-suffix) {
            margin-left: -@ib-padding-xx-small;
          }

          .ant-select-selection-item {
            border: none;
            border-radius: @ib-border-radius-xxx-large;
            font-size: @ib-font-size-x-small;
            line-height: @ib-line-height-x-small;
            font-weight: @ib-font-weight-normal;
            max-width: 140px;
            margin: @ib-padding-xxxxx-small @ib-padding-xx-small @ib-padding-xxxxx-small 0;
            padding: 0 @ib-padding-xx-small;
            overflow-x: hidden;
            color: @gray-gray-500;
            background: @gray-gray-100;

            .ant-select-selection-item-remove {
              display: flex;
            }
          }

          .ant-select-selection-search {
            margin: 0;
          }
        }

        + .ant-select-selection-placeholder {
          left: @ib-padding-base;
          right: calc(20px + @ib-padding-base);
        }
      }
    }

    .ant-select-arrow,
    .ant-select-clear {
      position: absolute;
      flex-shrink: 0;
      top: unset;
      right: 8px;
      bottom: unset;
      left: unset;
      vertical-align: unset;
      margin: 0;
      width: unset;
      height: unset;
      width: 20px;
      height: 20px;
    }

    &:not(.ant-select-disabled) {
      &.ant-select-focused {
        .ant-select-selector {
          border-color: @blue-blue-700;
        }
      }

      &:hover {
        .ant-select-selector {
          border-color: @blue-blue-700;
        }
      }
    }

    &.ant-select-disabled {
      .ant-select-selector {
        background: @gray-gray-100;
        color: @gray-gray-200;
      }
    }
  }

  &__remove-icon {
    cursor: pointer;
    color: @gray-gray-500 !important;

    &:hover {
      color: @black-black-50 !important;
    }
  }

  &_success {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-color: @green-green-500 !important;
      }
    }
  }

  &_error {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-color: @red-red-600 !important;
      }
    }
  }

  &__dropdown {
    box-shadow: @ib-popover-box-shadow;
    background: @white-white-50;
    border-radius: @ib-border-radius-x-small;
    padding: @ib-padding-base;

    .ant-select-item {
      color: @gray-gray-900;
      font-size: @ib-font-size-small;
      line-height: @ib-line-height-base;
      padding: 0;
      min-height: 20px;
      background-color: transparent !important;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &-option-active {
        color: @blue-blue-700;
      }

      &-empty {
        padding: 0;
        min-height: @ib-line-height-base;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;