@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

@ib-tag-list-item-description-margin-left-mobile: 30px;

.ib-tags-management-modal {
  .ant-modal {
    height: 628px;
  }

  .ant-modal-content {
    height: 100%;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    overflow: hidden !important;

    > .ib-input {
      width: 100%;
      flex-shrink: 0;
    }
  }

  .ib-alert {
    position: absolute;
    top: @ib-padding-base;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &__empty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
      margin-bottom: 6px;
    }

    .ib-typography__paragraph {
      text-align: center;
      margin-bottom: 24px;
    }

    .ib-button {
      display: flex !important;
    }
  }

  &__tag-list {
    overflow-y: auto;
    padding: 0;
    margin: @ib-padding-small 0 0 0;

    @media (max-width: @ib-breakpoint-small) {
      margin: @ib-padding-x-small 0 0 0;
    }

    &__item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: 56px;
      height: auto;
      background: @white-white-50;

      @media (max-width: @ib-breakpoint-small) {
        align-items: center;
        flex-wrap: wrap;
        padding: @ib-padding-small 0;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid @gray-gray-100;
      }

      &_drag-disabled {
        > .ib-icon {
          opacity: 0;
        }
      }

      &_dragging {
        border-bottom: none !important;
        box-shadow: 0px 0px 20px 0px #0000000D;
      }

      > .ib-icon {
        color: @gray-gray-100 !important;
        margin: @ib-padding-base @ib-padding-xxx-small 0 0;

        @media (max-width: @ib-breakpoint-small) {
          margin: 0 @ib-padding-xxx-small 0 0;
        }
      }

      &__tag-container {
        overflow: hidden;
        margin: @ib-padding-base @ib-padding-base 0 0;
        width: 140px;
        flex-shrink: 0;

        @media (max-width: @ib-breakpoint-small) {
          margin: 0 @ib-padding-base 0 0;
        }
      }

      .ib-typography__paragraph {
        .text-ellipsis(2);
        flex-grow: 1;
        margin: @ib-padding-base 0 @ib-padding-base auto;
        align-self: center;

        @media (max-width: @ib-breakpoint-small) {
          .text-ellipsis();
          display: block;
          order: 3;
          margin: @ib-padding-xx-small 0 0 @ib-tag-list-item-description-margin-left-mobile;
        }
      }

      &__actions {
        flex-shrink: 0;
        display: flex;
        margin: @ib-padding-small 0 0 auto;
        
        @media (max-width: @ib-breakpoint-small) {
          gap: @ib-padding-small;
          margin: 0 0 0 auto;
        }
      }
    }
  }

  &__color-picker {
    display: flex;
    gap: @ib-padding-large;
    width: 100%;

    @media (max-width: @ib-breakpoint-small) {
      flex-direction: column;
      gap: @ib-padding-base;
    }

    .ib-typography__paragraph {
      margin-bottom: @ib-padding-xxxx-small;
    }

    &__control {
      display: flex;
      flex-direction: column;
      width: 216px;
      flex-shrink: 0;

      @media (max-width: @ib-breakpoint-small) {
        width: 100%;
      }
    }

    &__preview {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;

      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        border: 1px solid @gray-gray-200;
        border-radius: @ib-border-radius-x-small;
        overflow: hidden;
        padding: @ib-padding-xxxx-small;

        @media (max-width: @ib-breakpoint-small) {
          height: 84px;
        }
      }
    }
  }


  &__form-item {
    &:not(:last-child) {
      margin-bottom: @ib-padding-base;
    }

    .ib-input {
      margin-top: @ib-padding-xxxx-small;
    }
  }

  &__create-button {
    &_footer {
      @media (max-width: @ib-breakpoint-small) {
        display: none;
      }
    }
  }

  &__delete-button {
    display: none;
    
    @media (max-width: @ib-breakpoint-small) {
      display: flex;
      margin-top: @ib-padding-large;
    }

    &_footer {
      margin-left: auto !important;

      @media (max-width: @ib-breakpoint-small) {
        display: none;
      }
    }
  }

  .ib-button_secondary {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }
  }

  .ib-button_fill {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;