@import "src/inbox/styles/variables";

.ib-tariff-limit-modal {
  .ant-modal {
    width: 356px !important;

    @media (max-width: @ib-breakpoint-small) {
      width: 100% !important;
      height: unset !important;
      margin: auto @ib-padding-base @ib-padding-base @ib-padding-base;

      .ant-modal-content {
        border-radius: @ib-border-radius-small;

        .ant-modal-close {
          left: unset !important;
          right: 0 !important;
        }

        .ant-modal-header {
          border-radius: @ib-border-radius-small;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    margin: 0 @ib-padding-large @ib-padding-large @ib-padding-large;
    padding: 0 0 @ib-padding-large 0;

    &__icon {
      color: @red-red-500 !important;
      margin-bottom: @ib-padding-xx-large;
    }

    &__header {
      margin-bottom: @ib-padding-base;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;