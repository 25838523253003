@import "src/inbox/styles/variables";

.ib-attachment-input {
  display: flex;
  width: @ib-element-size-100 + @ib-padding-xxx-small;
  height: @ib-element-size-100 + @ib-padding-xxx-small;
  padding-top: @ib-padding-xxx-small;
  padding-right: @ib-padding-xxx-small;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;

    &_file {
      border: solid @ib-border-thickness-1 @gray-gray-200;
      border-radius: @ib-border-radius-x-small;
      padding: @ib-padding-xxxx-small;
    }

    &_thumbnail {
      padding: @ib-padding-xxxx-small + @ib-border-thickness-1;
    }

    &__thumbnail,
    &__button {
      position: absolute;
    }

    &__thumbnail {
      top: 0;
      left: 0;
    }

    &__body {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: @ib-padding-xxxx-small;
      z-index: 1;
    }

    &__footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: @ib-padding-xxxx-small;

      &__name {
        min-width: 0;
      }

      &__status {
        display: flex;
        padding-left: @ib-padding-xxxx-small;

        &_failure .ib-icon path:first-child {
          fill: @ib-error-color;
          stroke: @ib-error-color;
        }

        .ib-button {
          padding: 0;
          width: @ib-element-size-12;
          height: @ib-element-size-12;
          background: none;
          line-height: @ib-element-size-12;

          .ib-icon {
            width: @ib-element-size-12 !important;
            height: @ib-element-size-12 !important;
          }
        }
      }
    }

    &_file &__button {
      top: -@ib-padding-xxx-small - @ib-border-thickness-1;
      right: -@ib-padding-xxx-small - @ib-border-thickness-1;
    }

    &_thumbnail &__button {
      top: -@ib-padding-xxx-small;
      right: -@ib-padding-xxx-small;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;