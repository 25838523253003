@import "src/inbox/styles/variables";

.ib-menu-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 48px;
  height: 48px;
  background: @ib-white-color;
  color: @ib-icon-button-color;
  border-radius: @ib-border-radius-small;
  font-size: @ib-font-size-x-small;
  line-height: @ib-line-height-x-small;

  &_selected,
  &:active {
    background: @ib-icon-button-bg-color;
    color: @ib-primary-color;

    .ib-icon {
      color: @ib-primary-color !important;
    }
  }

  &:hover {
    background: @ib-icon-button-bg-color;

    @media (max-width: @ib-breakpoint-small) {
      background: unset;
    }
  }

  @media (max-width: @ib-breakpoint-small) {
    background: unset;
    margin-top: 6px;
    gap: 1px;
  }

  &__text {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: unset;
    }
  }

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;