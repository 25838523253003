@import "src/inbox/styles/variables";

.ib-checkbox {
  position: relative;
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid @ib-checkbox-border-color;
  background: @ib-white-color;
  transition: all 0.2s ease-out;
  cursor: pointer;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    opacity: 0;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  svg {
    position: absolute;
    display: none;
    width: 16px;
    height: 16px;
    color: @ib-white-color;
    top: -1px;
    left: -1px;
  }

  &:hover {
    border: 1px solid @ib-primary-color;
  }

  &_checked {
    border: 1px solid @ib-primary-color;
    background: @ib-primary-color;

    svg {
      display: block;
    }
  }

  &_disabled {
    cursor: not-allowed;
    border: 1px solid @ib-checkbox-border-color !important;
    background: @ib-checkbox-disabled-bg-color !important;

    svg {
      color: @ib-checkbox-border-color;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;