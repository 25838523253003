@import "src/simple-bot/styles/variables";
@import "src/simple-bot/styles/mixins";

.sb-channels-block {

  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    h2 {
      margin-right: 16px;
    }

    &__buttons {
      display: flex;
      flex-wrap: wrap;

      .sb-button {
        margin: 0 16px;
        font-size: @sb-font-size-base!important;
        line-height: @sb-line-height-base!important;
      }
    }

    &__sort-panel {
      font-size: @sb-font-size-base;
      line-height: @sb-line-height-base;
      color: @sb-font-color-base;
      margin-left: auto;
      margin-top: 7px;

      &__text {
        margin-right: 9px;
      }

      .ant-radio-button-wrapper {
        background-color: transparent !important;
        border-style: none !important;
        padding: 0;
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
        color: @sb-font-color-base;

        &:hover {
          color: @sb-primary-color;
        }

        &:not(:first-child)::before {
          background-color: transparent !important;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: none !important;
      }

      .ant-radio-button-wrapper-checked {
        color: @sb-primary-color;
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
        text-shadow: 0 0 .5px @sb-primary-color, 0 0 .5px @sb-primary-color;

        &::after {
          position: absolute;
          content: '';
          height: 2px;
          bottom: 8px;
          left: 0;
          width: 100%;
          background: @sb-primary-color;
        }
      }

      .ant-radio-button-wrapper-checked:last-child::after {
        margin-right: 0;
      }

      .ant-divider-vertical {
        color: @sb-divider-color;
        background-color: @sb-divider-color;
        height: 20px;
      }
    }
  }

  &__content {
    &__empty-list-message {
      margin-top: 14px;
      margin-bottom: 12px;
    }

    .sb-add-channel-button {
      margin-bottom: 8px;
    }

    &__list {
      display: table;
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 8px;

      &__channel-container {
        display: table-row;

        &__icon {
          display: table-cell;
          vertical-align: middle;
          padding-right: 16px;
        }

        &__title {
          display: table-cell;
          width: 100%;
          vertical-align: middle;
          font-size: @sb-font-size-x-large;
          line-height: @sb-line-height-x-large;
        }

        &__status {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }

    &__loading-block {
      position: relative;
      height: 60px;

      .sb-spin {
        .center-position;
      }
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;