@import "src/inbox/styles/variables";

.ib-switch {
  min-width: 40px;
  width: 40px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  padding: 4px;
  background-color: @ib-switch-unchecked-bg-color;
  transition: all 0.2s ease-out;
  flex-shrink: 0;
  
  .ant-click-animating-node, .ant-switch-inner {
    display: none;
  }

  &.ant-switch-loading, &.ant-switch-disabled {
    opacity: 1;
  }

  .ant-switch-handle {
    position: absolute;
    right: unset;
    left: 4px;
    top: unset;
    min-width: 16px;
    width: 16px;
    height: 16px;

    &:before {
      display: block;
      position: relative;
      top: unset !important;
      right: unset !important;
      bottom: unset !important;
      left: unset !important;
      box-shadow: none;
      background-color: @ib-white-color;
      width: 100%;
      height: 100%;
    }

    .ant-switch-loading-icon {
      position: absolute;
      top: 2px;
      left: 2px;

      svg {
        color: @ib-primary-color;
        display: block;
        width: 12px;
        height: 12px;
      }
    }
  }

  &.ant-switch-checked {
    background-color: @ib-primary-color;

    .ant-switch-handle {
      left: calc(100% - 20px);
    }
  }

  &.ant-switch-disabled {
    background-color: @ib-switch-disabled-bg-color;

    .ant-switch-handle:before {
      background-color: @ib-switch-handle-disabled-bg-color;
    }
  }

  &.ant-switch-loading {
    &:not(.ant-switch-checked) {
      background-color: @ib-switch-unchecked-bg-color;

      .ant-switch-handle .ant-switch-loading-icon svg {
        color: @ib-switch-unchecked-bg-color;
      }
    }

    &.ant-switch-checked {
      background-color: @ib-primary-color;
    }

    .ant-switch-handle:before {
      background-color: @ib-white-color;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;