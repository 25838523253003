@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";
@import "src/simple-bot/styles/variables";

.ib-card {
  display: flex;
  flex-direction: column;
  padding: @ib-padding-large;
  border: 1px solid @gray-gray-200;
  border-radius: @ib-border-radius-x-large;
  height: 233px;
  transition: box-shadow 0.3s, border-color 0.3s;
  cursor: pointer;

  @media (max-width: @ib-breakpoint-small) {
    height: auto;
  }

  &:hover, &:focus {
    box-shadow: @sb-box-shadow-default;

    .sb-scenario-list-card__top-row > h3 {
      text-decoration: underline;
    }
  }

  &:active {
    border: 2px solid @sb-scenario-list-card-active;
  }

  &__top-row {
    display: flex;
    gap: @ib-padding-xx-small;
    width: 100%;
    min-width: 0;
    align-items: center;

    .ib-typography {
      flex-grow: 1;
      min-width: 0;
      cursor: pointer;

      h4 {
        .text-ellipsis();
      }
    }
  }

  & > .ib-divider {
    margin: @ib-padding-large 0;
  }

  &__content {
    flex-grow: 1;
    min-height: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__footer {
    width: 100%;

    .label {
      text-transform: lowercase;
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;