@import "src/inbox/styles/variables";

.ib-tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  max-width: 140px;
  height: 24px;
  padding: 0 @ib-padding-xx-small;
  font-size: @ib-font-size-x-small;
  line-height: @ib-line-height-x-small;
  font-weight: @ib-font-weight-normal;
  overflow-x: hidden;
  border-radius: @ib-border-radius-xxx-large;

  &_success {
    color: @green-green-500;
    background: @green-green-100;
  }

  &_warning {
    color: @orange-orange-500;
    background: @orange-orange-100;
  }

  &_error {
    color: @red-red-500;
    background: @red-red-100;
  } 

  &_default {
    color: @gray-gray-500;
    background: @gray-gray-100;
  } 

  &_status {
    border-radius: 70px;
    color: @ib-tag-status-color;
    background: transparent;
    border: 1px solid @ib-tag-status-border-color;
  }
  
  &__content {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ib-icon:not(.ib-tag__close-icon) {
    height: 16px !important;
    width: 16px !important;
    color: currentColor !important;
  }

  &__close-icon {
    cursor: pointer;
    color: @gray-gray-500 !important;

    &:hover {
      color: @black-black-50 !important;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;