@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-contact-channel-list {
  display: flex;
  flex-direction: column;
  gap: @ib-padding-xx-small;

  &__channel-table {
    @media (max-width: @ib-breakpoint-small) {
      display: none;
    }

    &__name-cell {
      display: flex;
      align-items: center;
      gap: @ib-padding-xx-small;
    }

    &__actions-cell {
      padding: @ib-padding-base 0 @ib-padding-base @ib-padding-xxx-small !important;

      .ib-button {
        margin-left: auto;
      }
    }

    .ib-table__header {
      height: @ib-padding-xx-small;
    }
  }

  &__channel-list {
    display: none;

    @media (max-width: @ib-breakpoint-small) {
      display: flex;
    }

    &__item {
      display: flex;
      flex-flow: row;
      align-items: start;
      gap: @ib-padding-xx-small;

      &__info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 0;

        &__title {
          height: @ib-element-size-24;
        }
      }
    }

    .ant-spin-nested-loading {
      width: 100%;
    }
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;