@import "src/simple-bot/styles/variables";

.button-settings {
  display: inline-flex;
  padding-bottom: 20px;

  &__main {
    display: flex;
    flex-direction: column;
    min-width: 510px;
    padding-bottom: 60px;

    &__alt-texts-container {
      margin: 12px 0 24px;
      height: 197px;
    }
  }

  &__description {
    margin-left: 48px;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;