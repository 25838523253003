@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-scenario-card {
  &__extra {
    display: flex;
    gap: @ib-padding-base;

    .ib-button {
      .block-size(@ib-element-size-24);
    }
  }

  &__triggers {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
    grid-template-rows: repeat(3, 1fr);
    grid-gap: @ib-padding-base;

    &_four-items {
      grid-template-rows: repeat(2, 1fr);
    }

    &__trigger {
      display: flex;
      flex-wrap: nowrap;
      gap: @ib-padding-xxx-small;
      align-items: center;

      .ib-typography__paragraph {
        .text-ellipsis();
      }

      .ib-badge {
        padding: @ib-padding-xxxxx-small 0;
      }

      .ib-button {
        font-weight: unset;
      }

      .ib-icon {
        color: @ib-font-color-base !important;
      }
    }

    &__show-more {
      cursor: pointer;

      &:hover {
        .ib-badge {
          background-color: @blue-blue-600;
        }
      }
    }
  }

  &__footer {
    display: flex;
    gap: @ib-padding-xxx-small;
    align-items: baseline;
    height: 24px;

    :first-child {
      .text-ellipsis();
    }

    .ib-divider {
      height: 20px;
      transform: translateY(@ib-padding-xxx-small);
    }

    :nth-child(3) {
      flex-grow: 1;
      min-width: 0;
      .text-ellipsis();
    }

    .ib-typography__paragraph {
      span:first-child {
        text-transform: lowercase;
      }
    }
  }

  .ib-card {
    height: 257px;
  }
}
@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;