@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-button {
  font-size: @ib-font-size-small;
  line-height: @ib-line-height-base;
  font-weight: @ib-font-weight-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 @ib-padding-base;
  gap: @ib-padding-xx-small;
  height: @ib-element-size-40;
  border-radius: @ib-border-radius-x-small;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
  overflow: hidden;
  flex-shrink: 0;
  .text-ellipsis();

  .ib-icon {
    width: 20px !important;
    height: 20px !important;
    color: currentColor !important;
  }

  &_icon-only {
    padding: 0 @ib-padding-x-small;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &_with-actions {
    padding-right: 0;

    .ib-button__popover-expander {
      position: relative;
      margin-left: 8px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-out;
      flex-shrink: 0;

      &__divider {
        position: absolute;
        left: 0;
        display: block;
        width: 1px;
        height: 20px;
      }
    }
  }

  &_primary {
    border: none;
    background: @blue-blue-700;
    color: @white-white-50;

    &:hover:not(:has(.ib-button__popover-expander:hover)) {
      background: @blue-blue-600;
    }

    &:active:not(:has(.ib-button__popover-expander:active)) {
      background: @blue-blue-800;
    }

    &:disabled {
      background: @gray-gray-100 !important;
      color: @gray-gray-200 !important;
    }

    &.ib-button_success {
      background: @green-green-500;
      color: @white-white-50;

      &:hover {
        background: @green-green-400 !important;
      }

      &:active {
        background: @green-green-700 !important;
      }
    }

    &.ib-button_error {
      background: @red-red-600;
      color: @white-white-50;

      &:hover {
        background: @red-red-300 !important;
      }

      &:active {
        background: @red-red-800 !important;
      }
    }

    &.ib-button_with-actions {
      .ib-button__popover-expander {
        background-color: @blue-blue-700;

        &:hover {
          background: @blue-blue-600;
        }

        &:active {
          background: @blue-blue-800;
        }

        &__divider {
          background: @blue-blue-200;
        }
      }

      &:disabled {
        .ib-button__popover-expander {
          background: @gray-gray-100 !important;
          color: @gray-gray-200 !important;

          &__divider {
            background: @gray-gray-200 !important;
          }
        }
      }
    }

    .ib-spin {
      color: @white-white-50;
    }
  }

  &_secondary {
    border: 1px solid @blue-blue-700;
    background: @white-white-50;
    color: @blue-blue-700;

    &:hover:not(:has(.ib-button__popover-expander:hover)) {
      background: @blue-blue-50;
    }

    &:active:not(:has(.ib-button__popover-expander:active)) {
      border: 1px solid @blue-blue-800;
      background: @blue-blue-50;
      color: @blue-blue-800;
    }

    &:disabled {
      border: 1px solid @gray-gray-200 !important;
      background: @white-white-50 !important;
      color: @gray-gray-200 !important;
    }

    &.ib-button_success {
      border: 1px solid @green-green-500;
      background: @white-white-50;
      color: @green-green-500;

      &:hover {
        background: @green-green-100 !important;
      }

      &:active {
        border: 1px solid @green-green-700 !important;
        background: @green-green-100 !important;
        color: @green-green-700 !important;
      }
    }

    &.ib-button_error {
      border: 1px solid @red-red-600;
      background: @white-white-50;
      color: @red-red-600;

      &:hover {
        background: @red-red-100 !important;
      }

      &:active {
        border: 1px solid @red-red-800 !important;
        background: @red-red-100 !important;
        color: @red-red-800 !important;
      }
    }

    &.ib-button_with-actions {
      .ib-button__popover-expander {
        background-color: @white-white-50;

        &:hover {
          background: @blue-blue-50;
        }

        &:active {
          color: @blue-blue-800;
        }

        &__divider {
          background: @blue-blue-700;
        }
      }

      &:disabled {
        .ib-button__popover-expander {
          background: @white-white-50 !important;
          color: @gray-gray-200 !important;

          &__divider {
            background: @gray-gray-200 !important;
          }
        }
      }
    }
  }

  &_fill {
    border: none;
    background: @blue-blue-50;
    color: @blue-blue-700;

    &:hover:not(:has(.ib-button__popover-expander:hover)) {
      background: @blue-blue-200;
    }

    &:active:not(:has(.ib-button__popover-expander:active)) {
      background: @blue-blue-200;
      color: @blue-blue-800;
    }

    &:disabled {
      background: @gray-gray-100 !important;
      color: @gray-gray-200 !important;
    }

    &.ib-button_success {
      background: @green-green-100;
      color: @green-green-500;

      &:hover {
        background: @green-green-400 !important;
      }

      &:active {
        background: @green-green-400 !important;
        color: @green-green-700 !important;
      }
    }

    &.ib-button_error {
      background: @red-red-100;
      color: @red-red-600;

      &:hover {
        background: @red-red-300 !important;
      }

      &:active {
        background: @red-red-300 !important;
        color: @red-red-800 !important;
      }
    }

    &.ib-button_with-actions {
      .ib-button__popover-expander {
        background-color: @blue-blue-50;

        &:hover {
          background: @blue-blue-200;
        }

        &:active {
          color: @blue-blue-800;
        }

        &__divider {
          background: @blue-blue-700;
        }
      }

      &:disabled {
        .ib-button__popover-expander {
          background: @gray-gray-100 !important;
          color: @gray-gray-200 !important;

          &__divider {
            background: @gray-gray-200 !important;
          }
        }
      }
    }
  }

  &_link {
    border: none;
    background: transparent;
    color: @blue-blue-700;
    height: @ib-line-height-base;
    padding: 0;
    border-radius: 0;

    &:hover {
      color: @blue-blue-600;
    }

    &:active {
      color: @blue-blue-800;
    }

    &:disabled {
      color: @gray-gray-200 !important;
    }

    &.ib-button_success {
      color: @green-green-500;

      &:hover {
        color: @green-green-400;
      }

      &:active {
        color: @green-green-700;
      }
    }

    &.ib-button_error {
      color: @red-red-600;

      &:hover {
        color: @red-red-300;
      }

      &:active {
        color: @red-red-800;
      }
    }
  }

  &_icon {
    padding: 0;
    width: 32px;
    height: 32px;
    border: none;
    background: transparent;
    color: @gray-gray-300;

    &.ib-button_warning {
      color: @orange-orange-500;
      background-color: @orange-orange-100;
      border-radius: @ib-border-radius-medium;

      &:hover {
        color: @orange-orange-500;
        background-color: @orange-orange-300;
      }

      &:active {
        color: @orange-orange-800;
      }
    }

    &:hover {
      color: @blue-blue-700;
    }

    &:active {
      color: @blue-blue-800;
    }

    &:disabled {
      color: @gray-gray-200 !important;
    }

    .ib-icon {
      @media (max-width: @ib-breakpoint-small) {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  &_icon-bordered,
  &_icon-round {
    padding: 0;
    background: @white-white-50;
    color: @gray-gray-300;

    &:hover {
      border: 1px solid @blue-blue-700;
      color: @blue-blue-700;
    }

    &:active {
      border: 1px solid @blue-blue-800;
      color: @blue-blue-800;
    }

    &:disabled {
      border: 1px solid @gray-gray-200 !important;
      background: @gray-gray-100 !important;
      color: @gray-gray-200 !important;
    }
  }

  &_icon-bordered {
    width: 40px;
    border: 1px solid @gray-gray-200;
  }

  &_icon-round {
    width: 24px;
    height: 24px;
    border-radius: @ib-border-radius-small;
    border: 1px solid @gray-gray-300;
    line-height: @ib-line-height-xx-small;

    .ib-icon {
      width: 14px !important;
      height: 14px !important;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;