@import "src/inbox/styles/variables";

.ib-message-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;

  &__content {
    flex-grow: 1;
    min-height: 0;
    background-color: @ib-message-list-background-color;
    border-top: 1px solid @gray-gray-100;

    &__scroll {
      height: 100%;
      overflow-x: hidden;
      overflow-y: overlay;
      transform: scaleY(-1);

      &__canvas {
        position: relative;
        width: 100%;

        &__window {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;

          & > div {
            transform: scaleY(-1);
          }
        }
      }
    }
  }

  &__footer {
    background: @ib-white-color;
    border-top: 1px solid @gray-gray-100;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;