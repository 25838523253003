@import "src/simple-bot/styles/variables";
@import "src/simple-bot/styles/mixins";

.sb-channels-card {
  font-size: @sb-font-size-base;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: @sb-font-color-base;
  background: @sb-content-page-background-color;

  &__header {
    background: @sb-control-color-background;
    box-shadow: @sb-border-bottom-box-shadow;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 11;

    &__left {
      display: inline-flex;
      align-items: center;
      margin: 8px 0;
      overflow: hidden;

      .sb-button {
        margin-right: 10px;
      }

      &__title {
        display: flex;
        flex-direction: column;
        max-width: 100%;

        h3 {
          font-size: @sb-font-size-x-large;
          line-height: @sb-line-height-x-large;
          margin: 0;
        }

        h4&__subtitle {
          font-size: @sb-font-size-x-small;
          line-height: @sb-line-height-x-small;
          height: @sb-line-height-x-small;
          font-weight: @sb-font-weight-normal;
          color: @sb-font-color-base;
          margin: 0 !important;
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    &__header {
      flex-wrap: nowrap;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: @sb-content-page-background-color;
    flex-grow: 1;
    padding: 20px;
    min-height: 0;

    &__controls {
      margin-top: 24px;
      background: @sb-content-page-background-color;
      display: flex;
      flex-direction: row;

      margin: 0 8px 12px 10px;
      flex-grow: 0;
      flex-shrink: 0;

      h3 {
        font-size: @sb-font-size-medium;
        line-height: @sb-line-height-medium;
        margin-bottom: 0;
        margin-right: 18px;
      }

      .col-1 {
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 5px;
      }

      &__filter {
        display: flex;
        justify-content: flex-end;

        .sb-input {
          border-width: 1px;
          max-width: 534px;
        }
      }

      .sb-add-channel-button .sb-button {
        color: @sb-control-color-background !important;
        background: @sb-primary-color;
        border: none;
        box-shadow: none;
        height: 40px;

        &:hover {
          color: @sb-control-color-background;
          background: @sb-primary-color;
          border: none;
          box-shadow: @sb-button-box-shadow;
        }

        &:active {
          color: @sb-control-color-background;
          background: @sb-button-color-active;
          border: none;
          box-shadow: none;
        }
      }

      &__sort > .sb-button {
        color: @sb-control-color-foreground;
        border-color: @sb-button-bg-color-disabled;
        box-shadow: none;
        width: 40px;
        height: 40px;

        &:hover {
          color: @sb-primary-color;
          background: @sb-control-color-background;
          border: 1px solid @sb-primary-color;
          box-shadow: @sb-button-box-shadow;
        }

        &:active {
          color: @sb-button-color-active;
          background: @sb-control-color-background;
          border: 1px solid @sb-button-color-active;
          box-shadow: none;
        }
      }
    }

    &__empty-results {
      text-align: center;
      margin-top: 120px;

      h4 {
        color: @sb-font-color-base;
        font-weight: @sb-font-weight-bold;
        font-size: @sb-font-size-x-large;
        line-height: @sb-line-height-x-large;
      }

      p {
        color: @sb-font-color-secondary;
        font-size: @sb-font-size-medium;
        line-height: @sb-line-height-medium;
      }

      .sb-button {
        margin: auto;
        height: 40px;
        font-size: @sb-font-size-medium;
        line-height: @sb-line-height-medium;
      }
    }

    &__list-row {
      flex-grow: 1;
      flex-shrink: 1;
      min-height: 0;
      position: relative;

      > .sb-spin {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.5;
        background: @sb-content-page-background-color;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .scrollbar-container {
        padding-right: 8px;
      }

      &__list {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding-bottom: 20px;

        &__pagination {
          margin-top: 20px;
        }
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;