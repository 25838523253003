@import "src/simple-bot/styles/variables";
@import "src/simple-bot/styles/mixins";

.sb-bot-versions-modal {
  &.sb-modal > .ant-modal-content > .ant-modal-body {
    margin-bottom: 0;
  }

  .sb-panel {
    height: 100%;
  }

  .sb-tabs {
    .ant-tabs-tab-btn {
      font-size: @sb-font-size-x-large;
      line-height: @sb-line-height-x-large;
    }

    .ant-tabs-content-holder {
      height: auto;
    }
  }

  &__history {
    &__group {
      * {
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }

      h3 {
        font-weight: @sb-font-weight-bold;
        margin: 0;
        padding: 6px;
      }

      &__item {
        margin-top: 4px;
        padding: 6px;
        display: flex;
        border: 1px solid transparent;
        cursor: pointer;

        &_selected {
          border-color: @sb-editor-elements-selected-color;
        }

        &__description {
          .text-ellipsis;
          max-width: 260px;
          min-width: 260px;
          flex-grow: 1;
          margin-left: 12px;
        }

        &__user {
          .text-ellipsis;
          margin-left: 12px;
          color: @sb-primary-color;
        }
      }
    }
  }

  &__controls {
    display: flex;
    margin-top: 20px;

    .sb-button:not(:first-child) {
      margin-left: 12px;
    }
  }
}


@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;