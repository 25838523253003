@import "src/simple-bot/styles/variables";

@sb-output-connection-error-color: #F5222D;
@sb-output-connection-base-color: #8C8C8C;

.output-connection {
  position: relative;
  min-width: 14px;
  max-width: 14px;
  min-height: 14px;
  background: @sb-control-color-background;
  border-radius: 50%;
  border: 2px solid @sb-output-connection-base-color;
  cursor: default;

  svg {
    display: none;
  }

  &:hover {
    svg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
    }
  }

  &_error {
    border: 2px solid @sb-output-connection-error-color;
  }

  &_connected {
    background: @sb-output-connection-base-color;
  }

  &_active {
    border: 2px solid @sb-editor-elements-selected-color;
    background: @sb-editor-elements-selected-color;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;