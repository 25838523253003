@import "src/simple-bot/styles/variables";

.sb-tags-input {
  border: @sb-border-default;
  border-radius: @sb-border-radius-small;
  border-width: 2px;
  padding: 12px;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;

  &_read-only {
    padding: 0;
    border: none;
  }

  .sb-tag {
    margin: 0 8px 4px 0;
    height: 28px;
    font-size: @sb-font-size-base;
    line-height: @sb-line-height-base;
    color: @sb-font-color-base;
  }

  .ant-input {
    font-size: @sb-font-size-base;
    line-height: @sb-line-height-base;
    color: @sb-font-color-base;
    border: none;
    outline: 0;
    box-shadow: none;
    padding: 2px 12px;
    margin: 0 8px 4px 0;
    width: 200px;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;