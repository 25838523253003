@import "src/simple-bot/styles/variables";

.sb-context-menu {
  &__icon {
    background: transparent;
    color: @sb-font-color-secondary-2;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: @sb-context-menu-icon-hover-bg-color;
    }

    &:active {
      background: @sb-context-menu-icon-active-bg-color;
    }

    .sb-icon {
      display: block;
    }
  }

  .ant-dropdown-menu {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .ant-dropdown-menu-item {
    color: @sb-context-menu-item-color;
    font-size: @sb-font-size-x-large;
    line-height: @sb-line-height-x-large;
    padding-left: 24px;
    padding-right: 24px;
    height: 44px;
  }

  .ant-dropdown-menu-item .ant-upload {
    color: @sb-context-menu-item-color;
    font-size: @sb-font-size-x-large;
    line-height: @sb-line-height-x-large;
  }

  .ant-dropdown-menu-item-disabled {
    color: @sb-button-color-disabled;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;