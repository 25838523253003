@import "src/simple-bot/styles/variables";

.elma365-form-properties-table() {
  tr {
    cursor: default;

    &:hover > td {
      color: unset;
    }

    th, td {
      width: 166px;
    }

    td {
      line-height: @sb-line-height-base;

      .sb-tag {
        max-width: 100%;
      }

      &:nth-child(3) {
        color: @sb-font-color-secondary-2;
      }
    }
  }
}

.text-ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.center-position() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
