@import "src/simple-bot/styles/variables";

.sb-typography {

  & > * {
    font-family: @sb-font-family;
    font-style: normal;

    &:first-child {
      margin: 0;
    }

    & + * {
      margin: 8px 0 0 0;
    }
  }

  h2, h3, h4 {
    color: @sb-control-color-foreground;
    font-weight: @sb-font-weight-bold;
  }

  h2 {
    font-size: @sb-font-size-xx-large;
    line-height: @sb-line-height-xx-large;
  }

  h3 {
    font-size: @sb-font-size-base;
    line-height: @sb-line-height-base;
  }

  h4 {
    font-size: @sb-font-size-small;
    line-height: @sb-line-height-small;
  }

  p, ul, ol {
    color: @sb-font-color-secondary;
    font-weight: normal;
    font-size: @sb-font-size-small;
    line-height: @sb-line-height-small;
  }

  ul, ol {
    padding: 0 0 0 20px;
  }

  small {
    display: inline-block;
    color: @sb-font-color-secondary-2;
    font-weight: normal;
    font-size: @sb-font-size-x-small;
    line-height: @sb-line-height-x-small;
    margin-top: 3px;
  }

  p&__paragraph {

    &_lead {
      color: @sb-control-color-foreground;
      font-size: @sb-font-size-base;
      line-height: @sb-line-height-base;
    }
  }

  p&__paragraph {

    &_lead {
      color: @sb-control-color-foreground;
      font-size: @sb-font-size-base;
      line-height: @sb-line-height-base;
    }
  }

  button:not(.sb-switch *) {
    border: none;
    color: @sb-primary-color;
    cursor: pointer;
    background: none;
    padding: 0;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;