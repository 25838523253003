@import "src/simple-bot/styles/variables";

.create-elma365-app-element-action {
  &__content, &__failure-connection {
    color: @sb-font-color-secondary-2;
    font-size: @sb-font-size-small;
    line-height: @sb-line-height-small;
  }

  &__content {
    &__card {
      padding: @sb-padding-small;
      background: @sb-group-element-card-bg-color;
      border: 1px solid @sb-group-element-border-color;
      border-radius: @sb-border-radius-small;

      &_warning {
        border-color: @sb-warning-color;
      }

      &__title, &__prop, .sb-tag {
        color: @sb-font-color-base;
        max-width: 100%;
      }

      &__title {
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
        margin-bottom: 12px;
      }

      &__prop {
        margin-top: 10px;
      }

      &__divider {
        height: 1px;
        background: @sb-group-element-border-color;
        margin: 12px -12px 0;
      }

      &__result {
        margin-top: 12px;
      }
    }

    .dashed-button {
      margin-top: 16px;
    }
  }

  &__failure-connection {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;