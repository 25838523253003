@import "src/simple-bot/styles/variables";

@sb-actions-divider-color: #D9D9D9;

.sb-scenario-card {
  font-size: @sb-font-size-base;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: @sb-font-color-base;

  &__header {
    background: @sb-control-color-background;
    box-shadow: @sb-border-bottom-box-shadow;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 11;

    &__left {
      display: inline-flex;
      align-items: center;
      margin: 8px 0;
      overflow: hidden;

      &__title {
        display: flex;
        flex-direction: column;
        max-width: 100%;

        &__pencil {
          display: none;
          padding-left: 6px;
          cursor: pointer;
          color: @sb-font-color-secondary-2;
        }

        h3 {
          font-size: @sb-font-size-x-large;
          line-height: @sb-line-height-x-large;
          margin: 0;
          cursor: pointer;

          &:hover {
            .sb-scenario-card__header__left__title__pencil {
              display: inline;
            }
          }
        }

        input {
          font-size: @sb-font-size-x-large;
          line-height: @sb-line-height-x-large;
          font-weight: @sb-font-weight-bold;
          padding: 0;
          border: none;
          background-color: transparent;
          box-shadow: none;
          border-radius: 0;
          caret-color: @sb-control-color-foreground;
        }

        h4 {
          font-size: @sb-font-size-x-small;
          line-height: @sb-line-height-x-small;
          height: @sb-line-height-x-small;
          font-weight: normal;
          color: @sb-font-color-secondary-2;
          margin: 0 !important;
          display: flex;
          align-items: center;

          .sb-button {
            font-size: @sb-font-size-x-small;
            line-height: @sb-line-height-x-small;
          }

          .sb-icon {
            margin: 0 0 0 6px;
          }
        }

        h4&__subtitle_origin {
          color: @sb-font-color-base;

          .sb-button {
            margin-left: 6px;
          }

          .sb-icon {
            color: @sb-error-color;
            margin: 0 6px 0 0;
          }
        }
      }
    }

    &__right {
      display: inline-flex;
      flex-wrap: nowrap;
      align-items: center;
      margin: 8px 0;

      .sb-button {
        height: 40px;
        font-size: @sb-font-size-medium;

        &.ant-btn-icon-only{
          width: 40px;
        }
      }

      &__actions-divider {
        height: 32px;
        margin: 0 20px;
        background: @sb-actions-divider-color;
      }

      &__test-button-wrapper {
        margin-right: 12px;
      }
    }
  }

  @media screen and (min-width: 769px) {
    &__header {
      flex-wrap: nowrap;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    background: @sb-content-page-background-color;
    flex-grow: 1;
  }

  &__webchat-container {
    position: absolute;
    right: 100px;
    bottom: 20px;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;