@import "src/simple-bot/styles/variables";
@import "src/inbox/styles/variables";
@import "src/inbox/styles/mixins";

.ib-knowledge-base-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__content {
    display: flex;
    flex-flow: row wrap;
    gap: @ib-padding-xxx-small;
    padding-bottom: @ib-padding-base;

    &__entry {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: @ib-padding-xx-small;

      .ib-tag {
        color: @black-black-50;
        background-color: @ib-kb-entry-background-color;
        font-size: @ib-font-size-base;
        line-height: @ib-line-height-base;
      }
    }

    &__show-more {
      cursor: pointer;

      .ib-button {
        display: block;
        flex-shrink: 1;
        min-width: 0;
        font-weight: @ib-font-weight-normal;
        text-transform: lowercase;
      }
    }

    &__empty {
      display: flex;
      flex-direction: column;
      gap: @ib-padding-base;
      align-items: flex-start;
    }
  }

  &__extra {
    display: flex;
    gap: @ib-padding-base;
    align-items: center;
  }

  &__footer {
    display: flex;
    gap: @ib-padding-xxx-small;
    align-items: baseline;
    height: 24px;

    :first-child {
      .text-ellipsis();
    }

    .ib-divider {
      height: 20px;
      transform: translateY(@ib-padding-xxx-small);
    }

    :nth-child(3) {
      flex-grow: 1;
      min-width: 0;
      .text-ellipsis();
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;