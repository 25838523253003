@import "src/simple-bot/styles/variables";

.sb-menu {
  color: @sb-font-color-base;

  &.ant-menu-vertical {
    border-right: none;
  }

  li.ant-menu-item {
    margin: 0 !important;
    padding: 8px 24px;
    font-size: @sb-font-size-small;
    line-height: @sb-line-height-small;
    height: 38px;
    display: flex;
    align-items: center;

    .ant-menu-title-content {
      margin-left: 0;

      & > .sb-icon {
        display: block;
      }
    }

    .sb-icon {
      margin-right: 12px;
      flex-shrink: 0;
    }

    &:hover {
      background: @sb-dropdown-menu-list-item-bg-hover-color;
      color: inherit;
    }

    &:active {
      background: @sb-dropdown-menu-list-item-bg-active-color;
      color: inherit;
    }

    &-disabled {
      opacity: 0.4;
      color: inherit !important;
    }

    &-selected {
      color: @sb-primary-color;
      background: none;
    }
  }

  .ant-menu-item-divider {
    margin: 4px 0;
    background: @sb-dropdown-menu-items-divider-color;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;